import CabecalhoDeslogado from "../../components/cabecalho/cabecalhoDeslogado"
import '../../assets/css/telasInfo.css'
import Rodape from "../../components/rodape/rodape"

export default function ComoUsar() {
    return (
        <div>
            <CabecalhoDeslogado />
            <main>
                <div className="container textoComoUsar">
                    <div>
                        <h1 className="h1_telasInfo">Como Usar</h1>
                        <p>
                            Você pode usar o seu Euquerovantagens de 4( quatro) maneiras diferentes:
                        </p>
                        <ol className="listaComoUsar">
                            <li>Para Ter Renda Extra;</li>
                            <li>Para Ter Descontos Grátis(Free);</li>
                            <li>Para Ter Descontos em Dobro(Vip);</li>
                            <li>Para Anunciar Sua Empresa, Produtos e
                                Serviços(Master);</li>
                        </ol>
                    </div>
                    <div>
                        <h2>
                            1 - Para Ter Renda Extra Vitalícia, Transferível
                            aos Seus Herdeiros.
                        </h2>
                        <p>
                            O Portal EuQueroVantagens é a maior revolução do Marketing Digital de todos os tempos, assim como foi o Bitcon, só que melhor, porque aqui você não precisar investir dinheiro.
                        </p>
                        <p>
                            Aqui Basta fazer um dos cadastros abaixo, inclusive o Free(grátis), e você já tem descontos, e recebe seu Link cadastrador e QR Code,  sem nenhum custo, e começar a indicar amigos.  Pessoas física para terem descontos ou empresas ou autônomos para  anunciarem sem pagamento de comissões sobre venda, e todos também podem fazer renda extra só indicando.
                        </p>
                        <p>
                            - Inacreditável?
                        </p>
                        <p>
                            - Você não acreditou e perdeu a change do Bitcon no seu lançamento, por favor, não perca esta, porque aqui você não arrisca nada. (Maiores informações clicar nos ícones DNA e depois em RENDA  OU RENDIMENTOS.).
                        </p>
                    </div>
                    <div>
                        <h2>2 - Para Ter Descontos Simples(Free)</h2>
                        <p>Basta preencher o cadastro de Free e já ter descontos grátis por tempo indeterminado,  anunciados em nosso Site e APP, sem precisar ter ou deixar números de cartões, sem obrigações financeiras, de consumos ou quaisquer outras e fará renda extra só indicando amigos. </p>
                    </div>
                    <div>
                        <h2>3 - Para Ter Descontos em Dobro(Vip)</h2>
                        <p>Basta preencher o cadastro Vip, adquirir o pagote Vip(inclusive por boleto, sem precisar ter cartões), e você  terá descontos em dobro em relação aos cadastrados Free, anunciados em nosso Site e APP, e fará r renda extra só indicando amigos.</p>
                    </div>
                    <div>
                        <h2>4 - Para Anunciar Sua Empresa(Master)</h2>
                        <p>Basta preencher o cadastro Master como
                            Estabelecimento Empresa ou Autônomo, sem pagamento de percentuais sobre suas venda ou quaisquer outros, é só  adquirir o pacode Master, inclusive por boleto e anunciar e fazer renda extra só indicando amigos.</p>
                    </div>
                </div>
            </main>
            <Rodape />
        </div>
    )
}