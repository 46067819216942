import {useNavigate } from "react-router-dom"
import "../assets/css/notFound.css"
import Logo from "../assets/img/LogoEuQueroVantagens.png"
import Header from "../components/cabecalho/header"

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <div className="container__notFound">
            <Header />
            <section className="banner__notFound">
                <h1>Ops! Página não encontrada</h1>
                <span>Volte para a página inicial e continue sua busca por descontos e promoções!</span>
                <button className="c-banner__button c-banner__button--voltar" type="submit" onClick={() => navigate("/")}>Voltar para a página inicial</button>
            </section>
        </div>
    )
}