// import "https://code.iconify.design/2/2.1.2/iconify.min.js"
// import "https://fonts.googleapis.com/icon?family=Material+Icons";
import Header from "../../components/cabecalho/header"
import Footer from "../../components/rodape/rodape"
import "../../assets/css/meusAnuncios.css"
import { Modal, Input, Row, Checkbox, Button, Text } from "@nextui-org/react";
import "../../assets/css/global.css"
import { QrReader } from "react-qr-reader";
import { useState, useEffect, useRef } from "react"
import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import api from "../../services/api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { parseJwt } from "../../services/auth";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Icon } from "@mui/material";
import adapter from 'webrtc-adapter';


//QR code
import Webcam from "react-webcam";
// const WebcamComponent = () => <Webcam />;



export default function MeusAnuncios() {
    const [listaMeusAnuncios, setListaMeusAnuncios] = useState([]);
    const [categoria, setCategoria] = useState("");
    const [anuncioDelete, setAnuncioDelete] = useState(0)
    const [pesquisa, setPesquisa] = useState("");
    const navigate = useNavigate();
    const [cupomEncontrado, setCupomEncotrado] = useState(null)
    const [modal2, setModal2] = useState(false)
    const [botao, setBotao] = useState(false)

    const [modalDelete, setModalDelete] = useState(false);
    const [modalScan, setModalScan] = useState(false);

    const handler = (id) => {
        setModalDelete(true);
        setAnuncioDelete(id)
    }

    const closeHandler = () => {
        setModalDelete(false);
        setAnuncioDelete(0);
        console.log("closed");
    };

    const closeModalScan = () => {
        setModalScan(false)
        setCupomEncotrado(null)
        setModal2(false)
        setBotao(false)
    }

    //Qr
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    //
    document.addEventListener('DOMContentLoaded', function () {
        let video = document.querySelector("#webcam");

        // alguns celulares necessitam destas configurações
        // video.setAttribute('autoplay', '');
        // video.setAttribute('muted', '');
        // video.setAttribute('playsinline', '');

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'user' } })
                .then(function (stream) {
                    video.srcObject = stream;
                })
                .catch(function (error) {
                    alert("Não foi possível iniciar a webcam.");
                });
        }
    });


    function foto() {
        let video = document.querySelector("#webcam");

        let canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        let ctx = canvas.getContext('2d');

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        let dataURI = canvas.toDataURL('image/jpeg');
        document.querySelector("#foto").src = dataURI;
        document.querySelector("#base64").value = dataURI;
    }




    var myHeader = { 'Content-Type': 'application/json' }

    function BuscarMeusAnuncios() {
        api.get('/Anuncios/meusanuncios/' + parseJwt().jti, {
            headers: myHeader
        }).then((resposta) => {
            if (resposta.status === 200) {
                setListaMeusAnuncios(resposta.data)
            }
            console.log(resposta)
        }).catch((erro) => console.log(erro));
    }

    useEffect(BuscarMeusAnuncios, []);


    function DeletarAnuncio(id) {
        closeHandler()

        api.delete('/anuncios/' + id, {
            headers: myHeader
        }).then(((resposta) => {
            BuscarMeusAnuncios();
        }))

        setAnuncioDelete(0)
    }

    const filtroPesquisa = pesquisa.length > 0 ?
        listaMeusAnuncios.filter(anuncio => anuncio.nomeProduto.toLowerCase().includes(pesquisa))
        :
        []

    function BuscarAnuncioPorNome(nome) {
        console.log(nome)

        if (nome === "" || nome === " " || nome === null || nome === undefined || nome.lenght === 0) {

            BuscarMeusAnuncios();
        } else {
            api.get('/anuncios/nome' + nome, {
                headers: myHeader
            }).then((resposta) => {
                console.log(resposta)
                return resposta.json()
            }).then((data) => {
                console.log(data);
                setListaMeusAnuncios(data);
            }).catch((erro) => console.log(erro));

        }
    }

    function LerQrCode(id) {
        api.get("/cupons/" + id)
            .then(response => {
                if (response.status === 200) {
                    setCupomEncotrado(response.data)
                    console.log(response.data)

                    var dataFim = (response.data.idAnuncioNavigation.dataFim).toString();
                    var hoje = (new Date().toISOString())

                    // console.log(dataFim)
                    // console.log(hoje)

                    if (dataFim < hoje) {
                        console.log('Este cupom está vencido!')
                        closeModalScan()
                        var input = document.getElementById("validar");
                        input.classList.add('c-input--invalid');
                        toast.error("Este cupom está vencido :/");

                        //atualizar situacao do cupom para 3
                        api.put('/cupons', {
                            "idCupom": id,
                            "situacao": "3"
                        }).then(response => {
                            if (response.status === 200) {
                                // console.log('foi aqui moço')
                            }
                        }).catch(error => {
                            console.log('deu ruim aqui bixo')
                            console.log(error)
                        })
                    }

                    else {
                        console.log('cupom válido.')
                        setModal2(true)
                        //validar se a situacao do cupom ja é 2                                                             
                        var situacao = (response.data.situacao);

                        if (situacao === "2") {
                            // console.log('Este cupom já está validado!')
                            closeModalScan()
                            var input = document.getElementById("validar");
                            input.classList.add('c-input--invalid');
                            toast.warning("Este cupom já foi validado!");
                        }

                        //atualizar cupom para 2
                        else if (situacao === "1") {
                            setBotao(true)
                        }

                    }
                }
            }).catch(error => {
                console.log('deu ruim aqui bixo')
                console.log(error)
            })
    }

    function validar(idCupom) {
        api.put('/cupons', {
            "idCupom": idCupom,
            "situacao": "2"
        }).then(response => {
            if (response.status === 200) {
                // console.log('foi aqui agora!')
                closeModalScan()
                var input = document.getElementById("validar");
                input.classList.add('c-input--invalid');
                toast.success("Cupom validado com sucesso :D");
            }
        }).catch(error => {
            console.log('deu ruim aqui bixo')
            console.log(error)
        })
    }

    // useEffect(() => {
    //     if (data.length === 36) {
    //         axios.get("https://62d9e41e9eedb6996363b49f.mockapi.io/anuncio/1/cupom/" + 2)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     console.log('foi')
    //                     setCupomEncotrado(response.data)
    //                 }
    //             }).catch(error => {
    //                 console.log('deu ruim aqui bixo')
    //                 console.log(error)
    //             })
    //     }
    // }, [data])

    return (
        <div>
            <Header></Header>
            <div className="c-body">
                <Modal
                    closeButton
                    blur
                    aria-labelledby="modal-title"
                    open={modalDelete}
                    onClose={closeHandler}
                >
                    <Modal.Header>
                        <Text id="modal-title" size={18}>
                            <Text b size={18}>
                                Tem certeza de que deseja deletar esse anúncio?
                            </Text>
                        </Text>
                    </Modal.Header>
                    <Modal.Body>
                        <Text size={18}>
                            Está ação será permanente e não será possível recuperar o saldo anterior
                        </Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto onClick={closeHandler}>
                            Cancelar
                        </Button>
                        <Button auto color="error" onClick={() => DeletarAnuncio(anuncioDelete)}>
                            Deletar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    id="validar"
                    closeButton
                    blur
                    aria-labelledby="modal-title"
                    open={modalScan}
                    onClose={() => closeModalScan()}
                >
                    <Modal.Header>
                        <Text id="modal-title" size={18}>
                            <Text b size={18}>
                                Aponte sua câmera para o QR Code do cupom
                            </Text>
                        </Text>
                    </Modal.Header>
                    <Modal.Body>
                        <QrReader
                            onResult={(result, error) => {
                                if (!!result) {
                                    LerQrCode(result?.text)
                                    }
                                    
                                    if (!!error) {
                                        // console.info(error);
                                        }
                                        }}
                                        style={{ width: '100%', height: '600px' }}
                                        />

                        {/* ------ */}

                        {/* <div className="qr-reader"> */}
                            {/* QR */}

                            {/* <div className="content">
                                <video autoPlay id="webcam"></video>

                                <button type="button" onclick="foto()" class="btn">Tirar foto </button>

                                <div class="image">
                                    <img src="person.svg" id="foto" />
                                </div>
                                <form method="POST" action="imagem.php">
                                    <textarea name="image_base64" id="base64"></textarea>

                                    <button type="submit" class="btn">Enviar Imagem</button>
                                 </form>
                            </div> */}

                            {/* <Webcam
                                style={{ width: "100%" }}
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                            /> */}


                        {/* </div> */}

                        <p style={{ textAlign: 'center' }}>{cupomEncontrado !== null ? cupomEncontrado.idAnuncioNavigation.nomeProduto : 'Sem resultado :('}</p>

                        {modal2 ?
                            <Modal.Body className="modal-validacao">
                                <p style={{ textAlign: 'center', color: 'white', fontWeight: 'bolder' }}>Confirme as informações antes de validar:</p>
                                <div className="div-info" >
                                    <p className="info">Produto: </p>
                                    <p className="info-response">{cupomEncontrado.idAnuncioNavigation.nomeProduto}</p>
                                </div>
                                <div className="div-info" >
                                    <p className="info">Empresa: </p>
                                    <p className="info-response">{cupomEncontrado.idAnuncioNavigation.idUsuarioNavigation.nomeComercial}</p>
                                </div>
                                <div className="div-info" >
                                    <p className="info">Cliente: </p>
                                    <p className="info-response">{cupomEncontrado.idClienteNavigation.nome}</p>
                                </div>
                                <div className="div-info" >
                                    <p className="info">CPF: </p>
                                    <p className="info-response">{cupomEncontrado.idClienteNavigation.cpf}</p>
                                </div>
                            </Modal.Body>
                            : <></>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button auto color="error" onClick={() => closeModalScan()}>
                            Cancelar
                        </Button>
                        {botao ?
                            <Button auto style={{ cursor: 'pointer' }} onClick={() => validar(cupomEncontrado.idCupom)}>
                                Validar
                            </Button> :
                            <Button auto style={{ cursor: 'no-drop' }}>
                                Validar
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>

                <main className="c-mainA">
                    <section className="c-gerenciaAnuncios container">
                        <div>
                            <h1 className="c-gerenciaAnuncios__titulo--big">Anúncios publicados</h1>
                        </div>
                        <div className="c-acoes">
                            <div className="c-pesquisa">
                                <input className="c-pesquisa__input" type="search" placeholder="Procure seus anuncios" value={pesquisa} onChange={(campo) => setPesquisa(campo.target.value)} onBlur={(campo) => BuscarAnuncioPorNome(campo.target.value)} />
                            </div>
                            {/* <div className="c-filtro">
                                <span className="iconify material-icons" data-icon="bi:filter"></span>
                                <p className="c-filtro__text">Filtrar e ordenar</p>
                            </div> */}
                            <button className="c-cadastroA" onClick={() => {
                                Swal.fire({
                                    title: '<span style="font-size: 20px;">Quero Vantagens deseja usar a câmera</span>',
                                    width: '55%',
                                    height: '15%',
                                    fontSize: 'small',
                                    type: 'warning',
                                    icon: 'question',
                                    position: 'center',
                                    position: 'top',
                                    showCancelButton: true,
                                    confirmButtonColor: '#0070f3',
                                    cancelButtonColor: '#f21361',
                                    confirmButtonText: 'Permitir',
                                    cancelButtonText: 'Não Permitir'
                                }).then((result) => {
                                    if (result.value) {
                                        setModalScan(true)
                                    }
                                })
                            }}>
                                <p className="c-cadastro__text">Validar cupom</p>
                            </button>
                            <button className="c-cadastroA" onClick={() => navigate("/CadastroAnuncio")}>
                                <p className="c-cadastro__text">Novo anúncio</p>
                                <span className="iconify material-icons" data-icon="fluent:add-circle-32-filled">add_circle</span>
                            </button>

                        </div>
                        <ToastContainer />
                    </section>

                    <section className="c-listagem container">
                        {
                            pesquisa.length <= 0 ? (listaMeusAnuncios.map((anuncio) => {
                                // console.log(anuncio.imgPrincipal)
                                return (
                                    <div key={anuncio.idAnuncio} className="c-linha">
                                        <div className="c-anuncio">
                                            <div className="c-anuncio__imagem" style={{ backgroundImage: `url(${"http://195.35.42.38:5000/img/" + anuncio.imgPrincipal})` }}>
                                                {/* <p className="c-anuncio__categoria" onLoad={BuscarCategoriaAnuncio(anuncio.categoriaAnuncios[0].idCategoria)}>{categoria}</p> */}
                                            </div>

                                            <div className="c-infoAnuncio">
                                                <h1 className="c-infoAnuncio__titulo">{anuncio.nomeProduto}</h1>
                                                <p className="c-infoAnuncio__descricao">{anuncio.descricao}</p>
                                            </div>

                                            <div className="c-valores">
                                                <h2 className="c-valores__text">valores</h2>
                                                <div className="c-planos">
                                                    <p className="c-planos__text">{`R$ ${(anuncio.valor - (anuncio.valor * anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                    <p className="c-planos__plano c-planos__plano--vip">vip 20%</p>
                                                </div>
                                                <div className="c-planos">
                                                    <p className="c-planos__text">{`R$ ${(anuncio.valor - (anuncio.valor * (anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                    <p className="c-planos__plano c-planos__plano--free">free 10%</p>
                                                </div>
                                                <div className="c-planos">
                                                    <p className="c-planos__text">R${anuncio.valor}</p>
                                                    <p className="c-planos__plano">visitante</p>
                                                </div>

                                                <div className="c-planos c-planos-prazo">
                                                    <p className="c-planos-prazo__text">prazo</p>
                                                    <p className="c-planos-prazo__data">{Intl.DateTimeFormat("pt-BR", {
                                                        month: "2-digit", day: "2-digit"
                                                    }).format(new Date(anuncio.dataFim))}</p>
                                                </div>
                                            </div>

                                            <div className="c-prazo">
                                                <p className="c-prazo__text">prazo</p>
                                                <p className="c-prazo__data">{Intl.DateTimeFormat("pt-BR", {
                                                    month: "2-digit", day: "2-digit"
                                                }).format(new Date(anuncio.dataFim))}</p>
                                            </div>

                                        </div>

                                        <div className="c-valoresResponsivo">
                                            <div className="c-linhaTitulo">
                                                <h2 className="c-valores__text">valores</h2>
                                            </div>
                                            <div className="c-linhaPlanos">
                                                <div className="c-planosR">
                                                    <p className="c-planos__text">R$80,00</p>
                                                    <p className="c-planos__plano c-planos__plano--vip">vip 20%</p>
                                                </div>
                                                <div className="c-planosR">
                                                    <p className="c-planos__text">R$90,00</p>
                                                    <p className="c-planos__plano c-planos__plano--free">free 10%</p>
                                                </div>
                                                <div className="c-planosR">
                                                    <p className="c-planos__text">R$100,00</p>
                                                    <p className="c-planos__plano">visitante</p>
                                                </div>

                                                <div className="c-planosR c-planos-prazo">
                                                    <p className="c-planos-prazo__text">prazo</p>
                                                    <p className="c-planos-prazo__data">{Intl.DateTimeFormat("pt-BR", {
                                                        month: "2-digit", day: "2-digit"
                                                    }).format(new Date(anuncio.dataFim))}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="c-opcoesAnuncio">
                                            <div onClick={() => handler(anuncio.idAnuncio)} className="c-opcao">
                                                <button className="c-opcao__btn">
                                                    <span className="iconify c-opcao__btn--delete material-icons" data-icon="ic:outline-delete">delete_outline</span>
                                                </button>
                                                <p className="c-opcao__text" >Deletar anúncio</p>
                                            </div>
                                            <div onClick={() => navigate('/cadastroanuncio', { state: { idAnuncio: anuncio.idAnuncio } })} className="c-opcao">
                                                <button className="c-opcao__btn">
                                                    <span className="iconify c-opcao__btn--update material-icons" data-icon="ic:outline-update">update</span>
                                                </button>
                                                <p className="c-opcao__text">Atualizar anúncio</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))

                                :

                                (filtroPesquisa.map((anuncio) => {
                                    // console.log(anuncio.imgPrincipal)
                                    return (
                                        <div key={anuncio.idAnuncio} className="c-linha">
                                            <div className="c-anuncio">
                                                <div className="c-anuncio__imagem" style={{ backgroundImage: `url(${"http://195.35.42.38:5000/img/" + anuncio.imgPrincipal})` }}>
                                                    {/* <p className="c-anuncio__categoria" onLoad={BuscarCategoriaAnuncio(anuncio.categoriaAnuncios[0].idCategoria)}>{categoria}</p> */}
                                                </div>

                                                <div className="c-infoAnuncio">
                                                    <h1 className="c-infoAnuncio__titulo">{anuncio.nomeProduto}</h1>
                                                    <p className="c-infoAnuncio__descricao">{anuncio.descricao}</p>
                                                </div>

                                                <div className="c-valores">
                                                    <h2 className="c-valores__text">valores</h2>
                                                    <div className="c-planos">
                                                        <p className="c-planos__text">{`R$ ${(anuncio.valor - (anuncio.valor * anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                        <p className="c-planos__plano c-planos__plano--vip">vip 20%</p>
                                                    </div>
                                                    <div className="c-planos">
                                                        <p className="c-planos__text">{`R$ ${(anuncio.valor - (anuncio.valor * (anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                        <p className="c-planos__plano c-planos__plano--free">free 10%</p>
                                                    </div>
                                                    <div className="c-planos">
                                                        <p className="c-planos__text">R${anuncio.valor}</p>
                                                        <p className="c-planos__plano">visitante</p>
                                                    </div>

                                                    <div className="c-planos c-planos-prazo">
                                                        <p className="c-planos-prazo__text">prazo</p>
                                                        <p className="c-planos-prazo__data">{Intl.DateTimeFormat("pt-BR", {
                                                            month: "2-digit", day: "2-digit"
                                                        }).format(new Date(anuncio.dataFim))}</p>
                                                    </div>
                                                </div>

                                                <div className="c-prazo">
                                                    <p className="c-prazo__text">prazo</p>
                                                    <p className="c-prazo__data">{Intl.DateTimeFormat("pt-BR", {
                                                        month: "2-digit", day: "2-digit"
                                                    }).format(new Date(anuncio.dataFim))}</p>
                                                </div>

                                            </div>

                                            <div className="c-valoresResponsivo">
                                                <div className="c-linhaTitulo">
                                                    <h2 className="c-valores__text">valores</h2>
                                                </div>
                                                <div className="c-linhaPlanos">
                                                    <div className="c-planosR">
                                                        <p className="c-planos__text">R$80,00</p>
                                                        <p className="c-planos__plano c-planos__plano--vip">vip 20%</p>
                                                    </div>
                                                    <div className="c-planosR">
                                                        <p className="c-planos__text">R$90,00</p>
                                                        <p className="c-planos__plano c-planos__plano--free">free 10%</p>
                                                    </div>
                                                    <div className="c-planosR">
                                                        <p className="c-planos__text">R$100,00</p>
                                                        <p className="c-planos__plano">visitante</p>
                                                    </div>

                                                    <div className="c-planosR c-planos-prazo">
                                                        <p className="c-planos-prazo__text">prazo</p>
                                                        <p className="c-planos-prazo__data">{Intl.DateTimeFormat("pt-BR", {
                                                            month: "2-digit", day: "2-digit"
                                                        }).format(new Date(anuncio.dataFim))}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="c-opcoesAnuncio">
                                                <div onClick={() => handler(anuncio.idAnuncio)} className="c-opcao">
                                                    <button className="c-opcao__btn">
                                                        <span className="iconify c-opcao__btn--delete material-icons" data-icon="ic:outline-delete">delete_outline</span>
                                                    </button>
                                                    <p className="c-opcao__text" >Deletar anúncio</p>
                                                </div>
                                                <div onClick={() => navigate('/cadastroanuncio', { state: { idAnuncio: anuncio.idAnuncio } })} className="c-opcao">
                                                    <button className="c-opcao__btn">
                                                        <span className="iconify c-opcao__btn--update material-icons" data-icon="ic:outline-update">update</span>
                                                    </button>
                                                    <p className="c-opcao__text">Atualizar anúncio</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))
                        }

                    </section>

                </main>
                <Footer />
            </div>
        </div >
    )
}