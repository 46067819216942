import '../assets/css/global.css'
import '../assets/css/home.css'
import Header from '../components/cabecalho/header'
import imgBanner from '../assets/img/positive-black-adult-man.png'
import imgAnuncio from '../assets/img/imagem-anuncio.jpg'
import imgDna from '../assets/img/Saly-31.svg'
import imgApp from '../assets/img/app-celular.png'
import imgGoogle from '../assets/img/googlePlay.png'
import imgApple from '../assets/img/appleStore.png'
import Rodape from '../components/rodape/rodape'
import { useState } from 'react'
import api, { url } from '../services/api'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { parseJwt } from '../services/auth'
import { Modal, Text } from '@nextui-org/react'
import { QRCodeSVG } from 'qrcode.react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Iconify from '@iconify/iconify';
import { FormatAlignCenter, Style } from '@mui/icons-material'
<script src="https://code.iconify.design/3/3.1.0/iconify.min.js"></script>



export default function Home() {
  const navigate = useNavigate()
  const [listaAnuncios, setListaAnuncios] = useState([])
  const [usuario, setUsuario] = useState(false)
  const [empresaLogada, setEmpresaLogada] = useState(true)
  const [nomeProduto, setNomeProduto] = useState('')
  const [idAnuncio, setIdAnuncio] = useState('')
  const [nomeComercial, setNomeComercial] = useState('')
  const [cupom, setCupom] = useState('')
  const [qrCodeAnuncio, setQrCodeAnuncio] = useState({})
  const [listaAnunciosResgatados, setListaAnunciosResgatados] = useState([])
  const [idsAnunciosResgatados, setIdsAnunciosResgatados] = useState([])

  const [visible, setVisible] = useState(false)
  const handler = () => setVisible(true)
  const closeHandler = () => {
    setVisible(false)
    console.log('closed')
    window.location.reload()
  }
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function ListarAnuncios() {
    api
      .get('/anuncios')
      .then(response => {
        if (response.status === 200) {
          setListaAnuncios(response.data)
          //console.log(listaAnuncios)
        }
      })
      .catch(erro => {
        console.log(erro)
      })
  }

  function pegarAnunciosResgatados() {

    var cliente = parseJwt(localStorage.getItem('usuario-login')).jti

    // Pegar Ids dos Anuncios Resgatados
    api.get("/Cupons/Meus/" + cliente)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setListaAnunciosResgatados(response.data)
        }
      })
      .catch(erro => {
        console.log(erro)
      })

  }

  function verificarLogin() {
    var id = parseJwt(localStorage.getItem('usuario-login')).jti
    //console.log(id)

    if (id != null) {
      setUsuario(true)
      pegarAnunciosResgatados();
    }
  }

  function Handler(idAnuncio, anuncio) {

    var cliente = parseJwt(localStorage.getItem('usuario-login')).jti

    api
      .get('clientes/buscar/' + cliente)
      .then(response => {
        if (response.status === 200) {
          //console.log(response.data.idCliente)
          setEmpresaLogada(false)
          setQrCodeAnuncio(anuncio)
          setVisible(true)

          const novoCupom = {
            idAnuncio: idAnuncio,
            idCliente: response.data.idCliente,
            dataResgate: new Date().toJSON(),
            codigo: '123',
            situacao: '1'
          }

          api.post('/cupons', novoCupom).then(response => {
            if (response.status === 201) {
              console.log('foi aqui moço')
              console.log(response.data.idCupom)
              setCupom(response.data.idCupom)
              toast.success("Cupom resgatado ;D")
              //setTimeout(()=>{window.location.reload()}, 2000)
            }
          })

        }
      })
      .catch(erro => {
        console.log(erro)
      })

    api
      .get('empresas/buscar/' + cliente)
      .then(response => {
        if (response.status === 200) {
          //setEmpresaLogada(true);
          var input = document.getElementById('resgatar')
          input.classList.add('c-input--invalid')
          toast.error(
            'Você está cadastrado(a) como uma empresa, portanto não pode resgatar cupons  :('
          )
          //alert(`Você está cadastrado(a) como uma empresa, portanto não pode resgatar cupons :(`);
        }
      })
      .catch(erro => {
        console.log(erro)
      })

  }

  // function Handler(idAnuncio, anuncio) {

  //   var cliente = parseJwt(localStorage.getItem('usuario-login')).jti

  //   //verifica se já foi resgatado

  //   api.get("/Cupons/Meus/" + cliente)
  //     .then(response => {
  //       if (response.status === 200) {
  //         //console.log(response.data.filter(cupom => cupom.idAnuncio === idAnuncio));

  //         let resposta = response.data.filter(cupom => cupom.idAnuncio === idAnuncio);

  //         if (resposta != 0) {
  //           //console.log("resgatou!");

  //           toast.warning("Você já resgatou esse cupom!")
  //           //document.getElementById("resgatar").style.backgroundColor = "#5b5b5b";

  //         } else {
  //           //console.log("não foi resgatado!");
  //           api
  //             .get('clientes/buscar/' + cliente)
  //             .then(response => {
  //               if (response.status === 200) {
  //                 //console.log(response.data.idCliente)
  //                 setEmpresaLogada(false)
  //                 setQrCodeAnuncio(anuncio)
  //                 setVisible(true)

  //                 const novoCupom = {
  //                   idAnuncio: idAnuncio,
  //                   idCliente: response.data.idCliente,
  //                   dataResgate: new Date().toJSON(),
  //                   codigo: '123',
  //                   situacao: '1'
  //                 }

  //                 api.post('/cupons', novoCupom).then(response => {
  //                   if (response.status === 201) {
  //                     console.log('foi aqui moço')
  //                     console.log(response.data.idCupom)
  //                     setCupom(response.data.idCupom)
  //                   }
  //                 })

  //               }
  //             })
  //             .catch(erro => {
  //               console.log(erro)
  //             })

  //           api
  //             .get('empresas/buscar/' + cliente)
  //             .then(response => {
  //               if (response.status === 200) {
  //                 //setEmpresaLogada(true);
  //                 var input = document.getElementById('resgatar')
  //                 input.classList.add('c-input--invalid')
  //                 toast.error(
  //                   'Você está cadastrado(a) como uma empresa, portanto não pode resgatar cupons  :('
  //                 )
  //                 //alert(`Você está cadastrado(a) como uma empresa, portanto não pode resgatar cupons :(`);
  //               }
  //             })
  //             .catch(erro => {
  //               console.log(erro)
  //             })
  //         }
  //       }
  //     }).catch(error => {
  //       console.log(error)
  //     })

  //   //
  // }

  useEffect(() => {
    ListarAnuncios()
    console.log(idsAnunciosResgatados);
  }, [idsAnunciosResgatados])
  useEffect(() => {
    console.log(listaAnunciosResgatados);
    listaAnunciosResgatados.forEach(anuncioResgatado => {
      setIdsAnunciosResgatados(idsAnunciosResgatados=> [...idsAnunciosResgatados, anuncioResgatado.idAnuncio])
    })
  }, [listaAnunciosResgatados])
  useEffect(async () => {
    await verificarLogin()
  }, [])

  return (
    <div>
      <Header></Header>
      <main>
        {usuario ? (
          <p></p>
        ) : (
          <section className='banner'>
            <div className='banner__box'>
              <img
                src={imgBanner}
                alt='Pessoas foto criado por wayhomestudio - br.freepik.com'
              />
              <div className='banner__txtConteudo'>
                <h1>
                  Encontre as melhores promoções, anuncie seus produtos e faça
                  renda extra!
                </h1>
                <p>
                  Não são apenas compras, é a sua oportunidade de crescimento
                  financeiro e de fazer melhor para todos!
                </p>
                <div className='banner__buttons'>
                  <button onClick={() => navigate("/cadastro")}>torne-se um cliente</button>
                  <button onClick={() => navigate("/cadastro")}>torne-se um anunciante</button>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className='secaoCategorias'>
          <div className='container'>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='map:beauty-salon'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='carbon:theater'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='akar-icons:laptop-device'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='fluent:sport-soccer-20-regular'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='fluent:food-16-filled'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='ps:cart-supermarket'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span className='iconify-inline' data-icon='bx:bx-health'></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='ic:round-home-repair-service'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='ic:round-travel-explore'
              ></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span className='iconify-inline' data-icon='bx:bx-car'></span>
            </div>
            <div className='secaoCategorias__categoria'>
              <span
                className='iconify-inline'
                data-icon='dashicons:pets'
              ></span>
            </div>
          </div>
        </section>
        <section className='secaoAnuncios container'>
          <div className='secaoAnuncios__box'>
            <div className='secaoAnuncios__verTodos'>
              <h2>Anúncios</h2>
              <div>
                <a href='/TodosAnuncios'>Ver todos</a>
                <span
                  className='iconify-inline'
                  data-icon='akar-icons:chevron-right'
                ></span>
              </div>
            </div>
            <div className='secaoAnuncios__listaAnuncios'>
              <Modal
                className='modalQrcode'
                noPadding
                open={visible}
                onClose={closeHandler}
              >
                <Modal.Header className='headerQrcode'>
                  <Text className='nomeProdutoQrcode'>
                    {qrCodeAnuncio.nomeProduto}
                  </Text>
                </Modal.Header>
                <Modal.Body  >
                  <QRCodeSVG className='qr-code' value={cupom} />
                </Modal.Body>
              </Modal>
              {listaAnuncios
                .reverse()
                .slice(0, 6)
                .map(anuncio => {
                  if (idsAnunciosResgatados.includes(anuncio.idAnuncio)) {
                    //botão de resgatar desabilitado
                    return (
                      <div className='secaoAnuncios__anuncio' key={anuncio.idAnuncio} >
                        {/* <div>
                          {empresaLogada ? (
                            <p></p>
                          ) : (
                            <Modal
                              className='modalQrcode'
                              noPadding
                              open={visible}
                              onClose={closeHandler}
                            >
                              <Modal.Header className='headerQrcode'>
                                <Text className='nomeProdutoQrcode'>
                                  {anuncio.nomeProduto}
                                </Text>
                              </Modal.Header>
                              <Modal.Body>
                                <QRCodeSVG className='qr-code' value={cupom} />
                              </Modal.Body>
                            </Modal>
                          )}
                        </div> */}
                        <div className='secaoAnuncios__titulo'>
                          <img
                            src={`${url}/${anuncio.imgPrincipal}`}
                            alt='Photo by David B Townsend on Unsplash'
                          />
                          <div>
                            <p>{anuncio.nomeProduto}</p>
                            <p>{anuncio.idUsuarioNavigation.nomeComercial}</p>
                          </div>
                        </div>
                        <div className='secaoAnuncios__diasPromocao'>
                          <p className='secaoAnuncios__txtCampo'>
                            Dias da promoção:
                          </p>
                          <div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.segunda === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              s
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.terca === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              t
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.quarta === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              q
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.quinta === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              q
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.sexta === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              s
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.sabado === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              s
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.domingo === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              d
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.feriados === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              f
                            </div>
                          </div>
                        </div>
                        <div className='secaoAnuncios__valor'>
                          <p className='secaoAnuncios__txtCampo'>Valor:</p>
                          <div className='secaoAnuncios__descontos'>
                            <div className='secaoAnuncios__tag'>
                              <p>{`R$ ${anuncio.valor}`}</p>
                              <div>Original</div>
                            </div>
                            <div className='separator'></div>
                            <div className='secaoAnuncios__tag'>
                              {/* <p>{`R$ ${(
                                  anuncio.valor -
                                  anuncio.valor * (anuncio.descontoTotal / 2)
                                ).toFixed(2)}`}</p> */}
                              <p>{`R$ ${(
                                anuncio.valor -
                                anuncio.valor * 0.1
                              ).toFixed(2)}`}</p>
                              <div>free 10%</div>
                            </div>
                            <div className='separator'></div>
                            <div className='secaoAnuncios__tag'>
                              {/* <p>{`R$ ${(
                                  anuncio.valor -
                                  anuncio.valor * anuncio.descontoTotal
                                ).toFixed(2)}`}</p> */}
                              <p>{`R$ ${(
                                anuncio.valor -
                                anuncio.valor * 0.2
                              ).toFixed(2)}`}</p>
                              <div>vip 20%</div>
                            </div>
                          </div>
                        </div>
                        <button
                          style={{ backgroundColor: "#5b5b5b", cursor: "no-drop" }}
                          disabled
                          id='resgatar'
                          onClick={() =>
                            Handler(
                              anuncio.idAnuncio,
                              anuncio
                            )
                          }
                          className='secaoAnuncios__btnResgatar'
                        >resgatado
                        </button>
                      </div>
                    )
                  } else {
                    //botão de resgatar normal
                    return (
                      <div className='secaoAnuncios__anuncio' key={anuncio.idAnuncio} >
                        {/* <div>
                          {empresaLogada ? (
                            <p></p>
                          ) : (
                            <Modal
                              className='modalQrcode'
                              noPadding
                              open={visible}
                              onClose={closeHandler}
                            >
                              <Modal.Header className='headerQrcode'>
                                <Text className='nomeProdutoQrcode'>
                                  {anuncio.nomeProduto}
                                </Text>
                              </Modal.Header>
                              <Modal.Body>
                                <QRCodeSVG className='qr-code' value={cupom} />
                              </Modal.Body>
                            </Modal>
                          )}
                        </div> */}
                        <div className='secaoAnuncios__titulo'>
                          <img
                            src={`${url}/${anuncio.imgPrincipal}`}
                            alt='Photo by David B Townsend on Unsplash'
                          />
                          <div>
                            <p>{anuncio.nomeProduto}</p>
                            <p>{anuncio.idUsuarioNavigation.nomeComercial}</p>
                          </div>
                        </div>
                        <div className='secaoAnuncios__diasPromocao'>
                          <p className='secaoAnuncios__txtCampo'>
                            Dias da promoção:
                          </p>
                          <div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.segunda === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              s
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.terca === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              t
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.quarta === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              q
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.quinta === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              q
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.sexta === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              s
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.sabado === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              s
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.domingo === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              d
                            </div>
                            <div
                              className={
                                anuncio.idDiasPromoNavigation.feriados === true
                                  ? 'diasPromo'
                                  : 'diasSemPromo'
                              }
                            >
                              f
                            </div>
                          </div>
                        </div>
                        <div className='secaoAnuncios__valor'>
                          <p className='secaoAnuncios__txtCampo'>Valor:</p>
                          <div className='secaoAnuncios__descontos'>
                            <div className='secaoAnuncios__tag'>
                              <p>{`R$ ${anuncio.valor}`}</p>
                              <div>Original</div>
                            </div>
                            <div className='separator'></div>
                            <div className='secaoAnuncios__tag'>
                              {/* <p>{`R$ ${(
                                  anuncio.valor -
                                  anuncio.valor * (anuncio.descontoTotal / 2)
                                ).toFixed(2)}`}</p> */}
                              <p>{`R$ ${(
                                anuncio.valor -
                                anuncio.valor * 0.1
                              ).toFixed(2)}`}</p>
                              <div>free 10%</div>
                            </div>
                            <div className='separator'></div>
                            <div className='secaoAnuncios__tag'>
                              {/* <p>{`R$ ${(
                                  anuncio.valor -
                                  anuncio.valor * anuncio.descontoTotal
                                ).toFixed(2)}`}</p> */}
                              <p>{`R$ ${(
                                anuncio.valor -
                                anuncio.valor * 0.2
                              ).toFixed(2)}`}</p>
                              <div>vip 20%</div>
                            </div>
                          </div>
                        </div>
                        <button
                          id='resgatar'
                          onClick={() =>
                            Handler(
                              anuncio.idAnuncio,
                              anuncio
                            )
                          }
                          className='secaoAnuncios__btnResgatar'
                        >resgatar
                        </button>
                      </div>
                    )
                  }

                  // return (
                  //   <div className='secaoAnuncios__anuncio' key={anuncio.idAnuncio} >
                  //     {/* <div>
                  //       {empresaLogada ? (
                  //         <p></p>
                  //       ) : (
                  //         <Modal
                  //           className='modalQrcode'
                  //           noPadding
                  //           open={visible}
                  //           onClose={closeHandler}
                  //         >
                  //           <Modal.Header className='headerQrcode'>
                  //             <Text className='nomeProdutoQrcode'>
                  //               {anuncio.nomeProduto}
                  //             </Text>
                  //           </Modal.Header>
                  //           <Modal.Body>
                  //             <QRCodeSVG className='qr-code' value={cupom} />
                  //           </Modal.Body>
                  //         </Modal>
                  //       )}
                  //     </div> */}
                  //     <div className='secaoAnuncios__titulo'>
                  //       <img
                  //         src={`${url}/${anuncio.imgPrincipal}`}
                  //         alt='Photo by David B Townsend on Unsplash'
                  //       />
                  //       <div>
                  //         <p>{anuncio.nomeProduto}</p>
                  //         <p>{anuncio.idUsuarioNavigation.nomeComercial}</p>
                  //       </div>
                  //     </div>
                  //     <div className='secaoAnuncios__diasPromocao'>
                  //       <p className='secaoAnuncios__txtCampo'>
                  //         Dias da promoção:
                  //       </p>
                  //       <div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.segunda === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           s
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.terca === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           t
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.quarta === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           q
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.quinta === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           q
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.sexta === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           s
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.sabado === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           s
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.domingo === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           d
                  //         </div>
                  //         <div
                  //           className={
                  //             anuncio.idDiasPromoNavigation.feriados === true
                  //               ? 'diasPromo'
                  //               : 'diasSemPromo'
                  //           }
                  //         >
                  //           f
                  //         </div>
                  //       </div>
                  //     </div>
                  //     <div className='secaoAnuncios__valor'>
                  //       <p className='secaoAnuncios__txtCampo'>Valor:</p>
                  //       <div className='secaoAnuncios__descontos'>
                  //         <div className='secaoAnuncios__tag'>
                  //           <p>{`R$ ${anuncio.valor}`}</p>
                  //           <div>Original</div>
                  //         </div>
                  //         <div className='separator'></div>
                  //         <div className='secaoAnuncios__tag'>
                  //           {/* <p>{`R$ ${(
                  //               anuncio.valor -
                  //               anuncio.valor * (anuncio.descontoTotal / 2)
                  //             ).toFixed(2)}`}</p> */}
                  //           <p>{`R$ ${(
                  //             anuncio.valor -
                  //             anuncio.valor * 0.1
                  //           ).toFixed(2)}`}</p>
                  //           <div>free 10%</div>
                  //         </div>
                  //         <div className='separator'></div>
                  //         <div className='secaoAnuncios__tag'>
                  //           {/* <p>{`R$ ${(
                  //               anuncio.valor -
                  //               anuncio.valor * anuncio.descontoTotal
                  //             ).toFixed(2)}`}</p> */}
                  //           <p>{`R$ ${(
                  //             anuncio.valor -
                  //             anuncio.valor * 0.2
                  //           ).toFixed(2)}`}</p>
                  //           <div>vip 20%</div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //     {
                  //       usuario ?
                  //         <button
                  //           id='resgatar'
                  //           onClick={() =>
                  //             Handler(
                  //               anuncio.idAnuncio,
                  //               anuncio
                  //             )
                  //           }
                  //           className='secaoAnuncios__btnResgatar'
                  //         >resgatar
                  //         </button>
                  //         :
                  //         <button
                  //           id='resgatar'
                  //           onClick={() =>
                  //             Handler(
                  //               anuncio.idAnuncio,
                  //               anuncio
                  //             )
                  //           }
                  //           className='secaoAnuncios__btnResgatar_deslogado'
                  //         >resgatar
                  //         </button>
                  //     }
                  //   </div>
                  // )
                })}
            </div>
            <ToastContainer />
          </div>
        </section>
        <section className='secaoDna'>
          <div className='secaoDna__container'>
            <img src={imgDna} alt='' />
            <div className='secaoDna__txtConteudo'>
              <h2>Vantagens que estão no DNA!</h2>
              <p>
                Aqui você tem descontos e faz sua própria renda vitalícia,
                além de poder ajudar seus amigos e herdeiros do seu DNA. Melhor impossível!
                {' '}
              </p>
              <div>
                <button style={{ cursor: "pointer" }} onClick={() => navigate('/dnaDescritivo')}>Descobrir</button>
              </div>
            </div>
          </div>
        </section>
        <section className='secaoPlanos' id='secaoPlanos'>
          <div className='container'>
            <h2>Planos</h2>
            <div className='secaoPlanos__opcoesPlanos'>
              {/* Visitante */}
              {/* <div className='secaoPlanos__plano'>
                <div>
                  <span
                    className='iconify-inline'
                    data-icon='healthicons:travel-alt'
                  ></span>
                  <h3>visitante</h3>
                </div>
                <ul>
                  <li>
                    Navegar como visistante e mergulhar em tantas ofertas nunca foi tão simples!
                    Sem precisar de cadastro, você já poderá acessar todos os nossos anúncios
                     e ver o que mais combina com você!
                    Não perca tempo, aproveite essa experiência e adquira
                    um dos nossos planos agora mesmo!
                  </li>
                </ul>
                <button>Ver mais</button>
              </div> */}
              {/* Free */}
              <div className='secaoPlanos__plano'>
                <div>
                  <span
                    className='iconify-inline'
                    data-icon='icons8:unlock-2'
                  ></span>
                  <h3>free</h3>
                </div>
                <ul>
                  <li>
                    Se cadastrando como Free, você já terá descontos disponíveis por
                    tempo indeterminado na sua conta, é isso mesmo!
                    Acha que não pode melhorar? Aqui você já pode começar a fazer
                    sua renda extra apenas indicando seus amigos a entrarem nessa onda!
                  </li>
                </ul>
                {/* <button>Ver mais</button> */}
              </div>
              {/* Vip */}
              <div className='secaoPlanos__plano'>
                <div>
                  <span
                    className='iconify-inline'
                    data-icon='bx:bxs-crown'
                  ></span>
                  <h3>vip</h3>
                </div>
                <ul>
                  <li>
                    Se tornando VIP, você automaticamente terá descontos em dobro!
                    É muita vantagem para um lugar só, né? Além de gastar menos, você ainda
                    pode ganhar mais!
                    Faça um upgrade no seu plano e na sua renda e indique
                    quem você conhece para fazer parte dessa experiência com você!
                  </li>
                </ul>
                {/* <button>Ver mais</button> */}
              </div>
              {/* Master */}
              <div className='secaoPlanos__plano'>
                <div>
                  <span
                    className='iconify-inline'
                    data-icon='mdi:diamond'
                  ></span>
                  <h3>master</h3>
                </div>
                <ul>
                  <li>
                    Quer divulgar sua marca e fazer sua empresa crescer ainda mais?
                    Adquirindo o plano Master, você anuncia o que tem de melhor sem qualquer
                    cobrança sobre suas vendas. Ah, você ainda tem desconto em todo o
                    nosso site, hein! Fala sério, vai ficar de fora dessa?
                  </li>
                </ul>
                {/* <button>Ver mais</button> */}
              </div>
            </div>
          </div>
        </section>
        {/* <section className='secaoApp'>
          <div className='secaoApp__imgCelular'>
            <img src={imgApp} alt='' />
          </div>
          <div className='secaoApp__box container'>
            <div className='secaoApp__conteudo'>
              <h2>Instale nosso aplicativo!</h2>
              <p>Tenha todas as promoções na palma da sua mão!</p>
              <div>
                <a href=''>
                  <img src={imgGoogle} alt='' />
                </a>
                <a href=''>
                  <img src={imgApple} alt='' />
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <Rodape />
    </div>
  )
}
