import React, { useState, Component } from 'react';
import CadastroEndereco from './cadastroEndereco';
import CadastroContato from './cadastroContato';
import CadastroEmpresa from './cadastroEmpresa';
import Confirm from './Confirmar';

export default function Cadastro() {

    // Outra forma de salvar os inputs era criar essas functions de steps
    // e chamar elas nas páginas, mas provavel que não funcione porque precisa
    // transformar essa parte de state de classe pra useState 

    const [userInfo, setUserInfo] = useState([{step:"1", cep:"", endereco:"", complemento:"", numero:"", bairro:"", cidade:"", UF:"", site:"", ecommerce:"", facebook:"", youtube:"", instagram:"", whatsapp:"", telegram:"", telefone:"", celular:"", email:"", razaosocial:"", nomefantasia:"", cnpj:"", senha:""}]);
  
    //proceed to next step
    function nextStep() {
        userInfo.map(item => {
            setUserInfo({
                step: item.step + 1
            });
        })
    }
    //go back to prev step
    function prevStep() {
        userInfo.map(item => {
            setUserInfo({
                step: item.step - 1
            });
        })
    }
    
    //handle fields change
    function handleChange(input, e) {
        setUserInfo({ [input]: e.target.value });
    }

    function handlePage(item) {
    
        switch (item.step) {
                case 1:
                    return <CadastroEndereco nextStep={nextStep()} handleChange={handleChange()} values={item}/>
                    
                case 2:
                    return <CadastroContato
                            nextStep={nextStep()}
                            prevStep={prevStep()}
                            handleChange={handleChange()}
                            values={item}
                        />
                        
                case 3:
                    return <CadastroEmpresa
                            nextStep={nextStep()}
                            prevStep={prevStep()}
                            handleChange={handleChange()}
                            values={item}
                        />
                    
                case 4:
                    return <Confirm
                            nextStep={nextStep()}
                            prevStep={prevStep()}
                            values={item}
                        />
                
                default : 
                return undefined
        }
    }

    return (
          <div>
            {userInfo && userInfo.map((item) => <div key={item.step}>{handlePage(item)}</div>)}
          </div>
    );
}


