import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import "../../../assets/css/cadastro.css"
import logo from "../../../assets/img/LogoCinza-Vetor.svg"
import comemoracao from "../../../assets/img/undraw_happy_announcement_re_tsm0.svg"
import api from '../../../services/api'

export default function CadastroUsuario() {
    const navigate = useNavigate();
    const [cpf, setCpf] = useState("");
    const [dataNascimento, setDataNascimento] = useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [senhaConfirma, setSenhaConfirma] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [senhaIgual, setSenhaIgual] = useState(false);
    const [emailValido, setEmailValido] = useState(false);

    var feedback = document.getElementById("msgFeedback");
    var feedbackEmail = document.getElementById("msgFeedbackEmail");

    //
    //validação CPF
    const [status, setStatus] = useState(null);
    // const [federalTaxNumber, setFederalTaxNumber] = useState("");
    // const [birthDate, setBirthDate] = useState("");

    function fetchStatus(cpfTratado, dataTratada) {

        const response = fetch(`https://naturalperson.api.nfe.io/v1/naturalperson/status/${cpfTratado}/${dataTratada}`);
        if (!response.ok) {
            console.log("ERROU!");
            throw new Error('Failed to fetch data');
        }
        const data = response.json();
        setStatus(data);
        console.log(data);
        console.log(status);
    };


    function Cadastrar(e) {

        e.preventDefault();


        //VALIDAÇÃO CPF
        //limpando máscara
        // let cpfTratado = cpf.replace(".", "").replace(".", "").replace("-", "");
        // console.log(cpfTratado);

        // let dataTratada = dataNascimento.replace("/", "").replace("/", "").replace("/", "");
        // console.log(dataTratada);


        // fetchStatus(cpfTratado, dataTratada);
        // console.log(status);
        //


        var dadosCadastro = ({
            novoCliente: {
                dataNascimento: dataNascimento,
                nome: nome,
                cpf: cpf,
                // nomeComercial: null,
                orgaoClasse: null,
                numeroOrgaoClasse: null
            },
            novoUsuario: {
                email: email,
                senha: senha,
                nomeComercial: nome,
                idTipoUsuario: null,
                vip: false,
                master: false
            }
        })

        navigate('/CadastroEndereco', { state: { cadastro: dadosCadastro } })

    }

    function lerCampos() {
        var senha = document.getElementById("password").value
        var senhaConfirmacao = document.getElementById("passwordConfirmation").value

        if (senha !== senhaConfirmacao) {
            feedback.innerHTML = 'As senhas não coincidem!'
            setSenhaIgual(false)
        } else {
            setSenhaIgual(true)
            feedback.innerHTML = ''
        }

    }


    function verificarEmail() {
        var email = document.getElementById("email").value

        api.get('Usuarios/BuscarEmail?email=' + email)
            .then(response => {
                if (response.status === 200) {
                    //console.log(response)
                    feedbackEmail.innerHTML = "Ops, esse email já em uso!"
                    setEmailValido(false)

                } else {
                    setEmailValido(true)
                    feedbackEmail.innerHTML = ""
                }
            })
            .catch(erro => {
                setEmailValido(true)
                feedbackEmail.innerHTML = ""
                console.log(erro)
            })

    }


    function validacaoEmail() {

        const usuario = email.split('@')[0];
        const dominio = email.split('@')[1];

        if ((usuario.length >= 1) &&
            (dominio.length >= 3) &&
            (usuario.search("@") == -1) &&
            (dominio.search("@") == -1) &&
            (usuario.search(" ") == -1) &&
            (dominio.search(" ") == -1) &&
            (dominio.search(".") != -1) &&
            (dominio.indexOf(".") >= 1) &&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            
        } else {
            toast.error("Por favor, insira um email válido!")
        }
    }



    return (
        <div className="c-corpo">
            <header className="c-header container">
                <img src={logo} alt="logo do euquerovantagens" />
            </header>

            <main className="c-main container">
                <section className="c-cadastro">
                    <h1 className="c-cadastro__titulo">Crie sua conta de cliente</h1>

                    <form name="form" className="c-inputs c-inputs--center" onSubmit={Cadastrar}>
                        {/* <form className="c-inputs c-inputs--center" > */}
                        <label className="new_label">CPF</label>
                        <InputMask mask="999.999.999-99" required className="c-inputs__text" type="text" placeholder="000.000.000-00" value={cpf} onChange={(campo) => { setCpf(campo.target.value) }} />
                        {/* <InputMask mask="99/99/9999" required className="c-inputs__text" type="text" placeholder="Data de Nascimento" value={dataNascimento} onChange={(campo) => setDataNascimento(campo.target.value)} /> */}
                        <label className="new_label">Data de Nascimento</label>
                        <input required className="c-inputs__text" style={{ padding: '0px 8px 0px 6px' }} type="date" placeholder="Data de nascimento" value={dataNascimento} onChange={(campo) => setDataNascimento(campo.target.value)} />
                        <label className="new_label">Nome</label>
                        <input required className="c-inputs__text" type="text" placeholder="Nos informe seu nome" value={nome} onChange={(campo) => setNome(campo.target.value)} />
                        <label className="new_label">E-mail</label>
                        <input required className="c-inputs__text" type="text" placeholder="Informe o email de contato"
                            value={email}
                            name="email"
                            onBlur={(e) => validacaoEmail(e.target.value)}
                            id="email"
                            onChange={(campo) => {
                                verificarEmail()
                                setEmail(campo.target.value)
                            }} />
                        <div id="feedbackEmail"><p id="msgFeedbackEmail"></p></div>

                        <label className="new_label">Senha</label>
                        <input required
                            className="c-inputs__text"
                            type="password"
                            id="password"
                            placeholder="Insira sua senha"
                            value={senha}
                            onChange={(campo) => {
                                lerCampos()
                                setSenha(campo.target.value)
                            }
                            }
                            onClick={() => toast.warning("Sua senha deve ter de 4 a 16 caracteres!")}
                        />


                        <label className="new_label">Confirmar senha</label>
                        <input required
                            className="c-inputs__text"
                            type="password"
                            id="passwordConfirmation"
                            placeholder="Confirme a senha"
                            value={senhaConfirma}
                            onChange={(campo) => {
                                lerCampos()
                                setSenhaConfirma(campo.target.value)
                            }}
                        />


                        <div id="feedback"><p id="msgFeedback"></p></div>

                        <div className="c-buttons">
                            <button className="c-buttons__button c-buttons__button--voltar" type="button" onClick={() => navigate(-1)}>retornar</button>
                            {
                                isLoading === false ? (
                                    senhaIgual === false ?
                                        <button className="c-buttons__button c-buttons__button--proximo" disabled type="submit">proximo</button>
                                        : (emailValido === false ? <button className="c-buttons__button c-buttons__button--proximo" disabled type="submit">proximo</button>
                                            : <button className="c-buttons__button c-buttons__button--proximo" type="submit" >proximo</button>)
                                ) : (
                                    <button className="c-buttons__button c-buttons__button--proximo" disabled type="submit">carregando..</button>
                                )
                            }
                        </div>
                    </form>
                    <ToastContainer />
                </section>

                <section className="c-imagem">
                    <img src={comemoracao} alt="pessoas felizes comemorando" />
                </section>
            </main>

            <footer className="c-footerC container">
                <p className="c-footer__texto">@2022 EuQueroVantagens</p>
                <a className="c-footer__texto" href="#">Politica de privacidade</a>
            </footer>
        </div>
    )
}
