// import 'https://code.iconify.design/2/2.1.2/iconify.min.js'
import '../../assets/css/cadastroAnuncio.css'
import '../../assets/css/global.css'
import Header from '../../components/cabecalho/cabecalhoLogado.jsx'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'
import axios from 'axios'
import { Modal, Button, Text, Input, Row, Checkbox } from '@nextui-org/react'
import Lottie from 'react-lottie'
import animationData from '../../assets/lotties/lf30_editor_4nbbnhu0.json'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Rodape from '../../components/rodape/rodape.jsx'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api, { url } from '../../services/api'
import { type } from '@testing-library/user-event/dist/type'
import { parseJwt } from '../../services/auth'
import Iconify from '@iconify/iconify'

export default function CadastroAnuncios() {
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [tituloAnuncio, setTituloAnuncio] = useState('')
  const [categoriasCadastradas, setCategoriasCadastradas] = useState([])
  const [categorias, setCategorias] = useState([])
  const [quantidade, setQuantidade] = useState(1)
  const [descricao, setDescricao] = useState('')
  const [regulamento, setRegulamento] = useState('')
  const [imagens, setImagens] = useState([])
  const [imagemPrincipal, setImagemPrincipal] = useState({})
  const [idDiasPromo, setIdDiasPromo] = useState()
  const [diasPromo, setDiasPromo] = useState({
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
    feriados: false
  })

  const [valor, setValor] = useState(1)
  const [desconto, setDesconto] = useState(1)
  const [dataInicio, setDataInicio] = useState(new Date())
  const [dataFim, setDataFim] = useState(new Date())
  const [status, setStatus] = useState({
    tipo: '',
    mensagem: ''
  })
  const navigate = useNavigate()

  const validacaoSchema = yup.object().shape({
    titulo: yup
      .string()
      .default(tituloAnuncio)
      .required('O título é um campo obrigatório'),
    quantidade: yup
      .number()
      .typeError(
        'É necessário informar a quantidade do produto ou serviço ofertado'
      )
      .moreThan(1, 'É necessário adicionar uma quantidade maior que zero')
      .required(),
    descricaoAnuncio: yup
      .string()
      .required('É necessário adicionar uma descrição'),
    regulamentoAnuncio: yup
      .string()
      .required('É necessário adicionar um regulamento'),
    valorAnuncio: yup
      .number()
      .typeError('É necessário informar um valor para o seu produto ou serviço')
      .moreThan(1, 'É necessário adicionar um valor maior que zero')
      .required(),
    desconto: yup
      .number()
      .typeError('É necessário informar um desconto para o seu anúncio')
      .moreThan(0, 'É necessário adicionar um desconto maior que zero')
      .required(),
    dataInicio: yup
      .date()
      .min(new Date(), 'A data de início deve ser posterior a data atual')
      .typeError('É necessário informar uma data de início')
      .required(),
    dataFim: yup
      .date()
      .typeError('É necessário informar uma data de término para a promoção')
      .min(
        yup.ref('dataInicio'),
        'A data de término deve ser posterior a data de início'
      )
      .required(),
    // categorias: yup.string().required("É necessário adicionar iswrbgliawerbgiebrgi"),
    imagem: yup.mixed().required('Adicione uma imagem')
  })
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validacaoSchema)
  })

  const closeHandler = () => {
    setVisible(false)
    console.log('closed')
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  function Cadastrar(data, event) {
    event.preventDefault()

    if (imagens.length === 0) {
      toast.error('Adicione alguma imagem para o seu anúncio')
    }
    if (imagemPrincipal === 0) {
      toast.error('Escolha alguma imagem principal')
    }
    if (categorias.length === 0) {
      toast.error('Escolha alguma(s) categoria(s) para o seu anúncio')
    }

    if (
      imagens.length !== 0 &&
      imagemPrincipal !== null &&
      categorias.length !== 0
    ) {
      console.log(desconto / 100)
      console.log(diasPromo)
      console.log(categorias)

      let valorDesconto = desconto / 100

      var formData = new FormData()

      formData.append(
        'NovoAnuncio.IdUsuario',
        parseJwt(localStorage.getItem('usuario-login')).jti
      )
      formData.append('NovoAnuncio.NomeProduto', tituloAnuncio)
      formData.append('NovoAnuncio.Valor', valor)
      // formData.append('novoAnuncio.DescontoTotal', '0,6')
      formData.append(
        'novoAnuncio.DescontoTotal',
        valorDesconto.toString().replace('.', ',')
      )
      formData.append('NovoAnuncio.RegulamentoPromo', regulamento)
      formData.append('NovoAnuncio.Descricao', descricao)
      formData.append('NovoAnuncio.DataInicio', dataInicio)
      formData.append('NovoAnuncio.DataFim', dataFim)
      formData.append('NovoAnuncio.Quantidade', quantidade)
      formData.append('NovoDiasPromo.Terca', diasPromo.terca)
      formData.append('NovoDiasPromo.Segunda', diasPromo.segunda)
      formData.append('NovoDiasPromo.Quarta', diasPromo.quarta)
      formData.append('NovoDiasPromo.Quinta', diasPromo.quinta)
      formData.append('NovoDiasPromo.Sexta', diasPromo.sexta)
      formData.append('NovoDiasPromo.Sabado', diasPromo.sabado)
      formData.append('NovoDiasPromo.Domingo', diasPromo.domingo)
      formData.append('NovoDiasPromo.Feriados', diasPromo.feriados)

      const imagensRequisicao = imagens.filter(
        imagem => imagem !== imagemPrincipal
      )

      imagensRequisicao.forEach(imagem => {
        formData.append('imagens', imagem.file)
      })

      formData.append('ImagemPrincipal', imagemPrincipal.file)

      for (let index = 0; index < categorias.length; index++) {
        formData.append(`IdCategorias[${index}].idCategoria`, categorias[index])
        console.log(typeof categorias[index])
      }

      for (var value of formData.entries()) {
        console.log(value)
      }

      if (location.state?.idAnuncio !== undefined) {
        formData.append('NovoAnuncio.IdAnuncio', location.state.idAnuncio)
        formData.append('NovoDiasPromo.IdDiasPromo', idDiasPromo)

        api.put('/anuncios', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (response) {
          if (response.status === 201) {
            setVisible(true)
          }
        }).catch(function (response) {
          console.log(response)
        })

      } else {
        // axios({
        //   method: 'post',
        //   url: 'http://191.101.234.36:5000/api/diaspromo',
        //   data: formData,
        //   headers: { 'Content-Type': 'multipart/form-data' }
        // })
        api.post('/diaspromo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (response) {
          if (response.status === 201) {
            setVisible(true)
          }
        }).catch(function (response) {
          console.log(response)
        })
        
      }
    }
  }

  function MouseOver(id) {
    const img = document.getElementById(id)
    img.style.setProperty('display', 'flex')
  }

  function MouseOut(id) {
    const img = document.getElementById(id)
    img.style.setProperty('display', 'none')
  }

  function DeletarImagem(imagem) {
    const listaImagens = imagens.filter(
      imagemEscolhida => imagemEscolhida.url !== imagem
    )
    setImagens(listaImagens)
  }

  function PreviewImage(e) {
    if (e.target.files) {
      const arquivosArray = Array.from(e.target.files).map(arquivo =>
        URL.createObjectURL(arquivo)
      )

      const imagem = {
        url: arquivosArray[0],
        file: e.target.files[0]
      }

      setImagens(prevImagens => prevImagens.concat(imagem))

      console.log(imagens)

      Array.from(e.target.files).map(arquivo => URL.revokeObjectURL(arquivo))
    }
  }

  function ManipularCategorias(id) {
    const categoriaEncontrada = categorias.filter(categoria => categoria === id)

    if (categoriaEncontrada.length <= 0) {
      const novaCategoria = categorias.concat(id)
      setCategorias(novaCategoria)
    } else {
      const listaCategorias = categorias.filter(categoria => categoria !== id)
      setCategorias(listaCategorias)
    }

    console.log(categorias)
  }

  async function AnuncioAtual() {
    if (location.state?.idAnuncio !== undefined) {
      console.log(location.state?.idAnuncio)
      // ${location.state?.idAnuncio}
      api.get(`/anuncios/${location.state?.idAnuncio}`).then(async response => {
        if (response.status === 200) {
          await UrlParaArquivo(response.data.imgPrincipal).then(img => {
            console.log('idfbibfsifssfib')
            setImagens(prevArray => [...prevArray, img])
            setImagemPrincipal(img)
          })

          setValue('titulo', response.data.nomeProduto)
          setTituloAnuncio(await response.data.nomeProduto)

          setValue('valorAnuncio', response.data.valor)
          setValor(await response.data.valor)

          setValue('dataFim', response.data.dataFim)
          setDataFim(await response.data.dataFim)

          setValue('dataInicio', response.data.dataInicio)
          setDataInicio(await response.data.dataInicio)

          setValue('desconto', response.data.descontoTotal * 100)
          setDesconto((await response.data.descontoTotal) * 100)

          setValue('descricaoAnuncio', response.data.descricao)
          setDescricao(await response.data.descricao)

          setDiasPromo(await response.data.idDiasPromoNavigation)

          setValue('quantidade', response.data.quantidade)
          setQuantidade(await response.data.quantidade)

          setValue('regulamentoAnuncio', response.data.regulamentoPromo)
          setRegulamento(await response.data.regulamentoPromo)

          setIdDiasPromo(await response.data.idDiasPromoNavigation.idDiasPromo)
          console.log(await response.data.idDiasPromoNavigation.idDiasPromo)

          response.data.categoriaAnuncios.map(categoria => {
            setCategorias(prevArray => [...prevArray, categoria.idCategoria])
          })

          response.data.imagems.map(async imagem => {
            let img = await UrlParaArquivo(imagem.caminhoImagem)
            setImagens(prevArray => [...prevArray, img])
          })
        }
      })
    } else {
      console.log('nenhum id foi passado')
    }
  }

  async function UrlParaArquivo(caminhoImagem) {
    let resposta = await fetch(`${url}${caminhoImagem}`)
    let data = await resposta.blob()
    let arquivo = new File([data], caminhoImagem, {
      type: `image/${caminhoImagem.split('.')[1]}`
    })

    const image = {
      url: `${url}${caminhoImagem}`,
      file: arquivo
    }

    return image
  }

  useEffect(async () => {
    await AnuncioAtual()
  }, [])

  useEffect(() => {
    api.get('/categorias').then(response => {
      setCategoriasCadastradas(response.data)
    })
  }, [])

  return (
    <div>
      <ToastContainer />
      <Header></Header>
      <main>
        <div className='modalCadAnuncio'>
          <Modal
            closeButton
            preventClose
            aria-labelledby='modal-title'
            open={visible}
            onClose={closeHandler}
          >
            <Modal.Body>
              <Lottie options={defaultOptions} height='90%' width='90%' />
              <Text size={25}>Anúncio cadastrado com sucesso</Text>

              <Button
                style={{
                  marginTop: '40px',
                  borderRadius: '3px',
                  backgroundColor: '#370564',
                  marginBottom: '30px',
                  width: '100%'
                }}
                onClick={() => navigate('/')}
              >
                Voltar para o início
              </Button>
            </Modal.Body>
          </Modal>
        </div>
        <div className='cadastroAnuncio container'>
          <form
            className='form-cadastroAnuncio'
            onSubmit={handleSubmit(Cadastrar)}
          >
            <div className='cadastroAnuncio__checkbox'>
              <div>
                <h2>
                  Marque aqui caso você seja um profissional ou empresa impedida
                  de dar desconto
                </h2>
                <input type='checkbox' />
                <p>
                  Seu anúncio será postado com a seguinte mensagem: "Esse
                  profissional ou empresa por norma do seu Conselho de Ética
                  está impedido de oferecer descontos promocionais, mas está a
                  sua disposição como divulgado para melhor atende-lo”.
                </p>
              </div>
              
            </div>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>1</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Dê um belo título ao seu anúncio!
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <input
                  type='text'
                  name='titulo'
                  {...register('titulo', { value: tituloAnuncio })}
                  value={tituloAnuncio}
                  onChange={t => setTituloAnuncio(t.target.value)}
                  className='cadastroAnuncio__inputTexto'
                  id='titulo'
                  placeholder='Escreva aqui :-)'
                />
                <p className='avisoErro'>{errors.titulo?.message}</p>
              </div>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>2</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Selecione a(s) categoria(s) do seu anúncio
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div className='opcoesCategoria'>
                  {categoriasCadastradas.map(categoriaCadastrada => {
                    let icone

                    if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '1F683646-6592-41D7-98F3-60618A83440F'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='map:beauty-salon'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '22D633F8-B554-481E-9717-2C3AA727C7AF'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='carbon:theater'
                        ></span>
                      )
                    } 
                    else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '6EBCED78-7E1C-4F8D-8A87-A61BC77EAAB0'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='akar-icons:laptop-device'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      'ADCB64D0-F5A3-4C25-BFE6-7EF4B3F95D2B'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='fluent:sport-soccer-20-regular'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '3E3A3E2C-8257-4069-AC83-8F98006E40A7'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='fluent:food-16-filled'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '566AC880-EF92-47B6-98C8-D33A3327EF27'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='ps:cart-supermarket'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      'B93F026F-6492-48B6-B5DB-468B5CC63AFD'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='dashicons:pets'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      'A5D7ADB4-1D10-4A2E-BFA7-A410EE9DDCF4'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='bx:bx-health'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '9ACE4598-F0AC-4A09-B905-D21A4B565047'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='ic:round-home-repair-service'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      'EBD4646E-55CF-4ADF-B6A3-B76E9B3FC596'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='ic:round-travel-explore'
                        ></span>
                      )
                    } else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '55E7A418-F248-4F9A-B6A8-9202B4697E31'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='bx:bx-car'
                        ></span>
                      )
                    }else if (
                      categoriaCadastrada.idCategoria.toUpperCase() ===
                      '20FC5CA4-D90B-4CAA-8EFC-9B44E71973AB'
                    ) {
                      icone = (
                        <span
                          className='iconify-inline'
                          data-icon='charm:search'
                        ></span>
                      )
                    }


                    return (
                      <div
                        type='button'
                        onClick={() =>
                          ManipularCategorias(categoriaCadastrada.idCategoria)
                        }
                        className={`opcoesCategoria__categoria ${categorias.find(
                          categoria =>
                            categoria === categoriaCadastrada.idCategoria
                        ) !== undefined
                          ? 'opcoesCategoria__categoria--color'
                          : 'opcoesCategoria__categoria--svg'
                          }`}
                      >
                        <div>{icone}</div>
                        <p>{categoriaCadastrada.tituloCategoria}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
              <input
                type='number'
                id='categorias'
                style={{ display: 'none' }}
                value={categorias[0]}
                name='categorias'
                {...register('categorias')}
              />
              {/* <p className="avisoErro">{errors.categorias?.message}</p> */}
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>3</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Escreva uma descrição
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <textarea
                  {...register('descricaoAnuncio')}
                  onChange={t => setDescricao(t.target.value)}
                  style={{ resize: 'none' }}
                  value={descricao}
                  className='cadastroAnuncio__inputTexto'
                  type='text'
                  name='descricaoAnuncio'
                  id='descricaoAnuncio'
                  placeholder='Escreva aqui :-)'
                />
              </div>
              <p className='avisoErro'>{errors.descricaoAnuncio?.message}</p>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>4</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Escreva um regulamento para essa promoção
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <textarea
                  {...register('regulamentoAnuncio')}
                  value={regulamento}
                  onChange={t => setRegulamento(t.target.value)}
                  style={{ resize: 'none' }}
                  className='cadastroAnuncio__inputTexto'
                  type='text'
                  name='regulamentoAnuncio'
                  id=''
                  placeholder='Escreva aqui :-)'
                ></textarea>
              </div>
              <p className='avisoErro'>{errors.regulamentoAnuncio?.message}</p>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>5</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Em quais dias da semana irá funcionar?
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div className='opcoesDias'>
                  <div
                    onClick={() =>
                      setDiasPromo({
                        ...diasPromo,
                        segunda: !diasPromo.segunda
                      })
                    }
                    className={
                      diasPromo.segunda === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>S</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({ ...diasPromo, terca: !diasPromo.terca })
                    }
                    className={
                      diasPromo.terca === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>T</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({ ...diasPromo, quarta: !diasPromo.quarta })
                    }
                    className={
                      diasPromo.quarta === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>Q</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({ ...diasPromo, quinta: !diasPromo.quinta })
                    }
                    className={
                      diasPromo.quinta === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>Q</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({ ...diasPromo, sexta: !diasPromo.sexta })
                    }
                    className={
                      diasPromo.sexta === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>S</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({ ...diasPromo, sabado: !diasPromo.sabado })
                    }
                    className={
                      diasPromo.sabado === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>S</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({
                        ...diasPromo,
                        domingo: !diasPromo.domingo
                      })
                    }
                    className={
                      diasPromo.domingo === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>D</p>
                  </div>
                  <div
                    onClick={() =>
                      setDiasPromo({
                        ...diasPromo,
                        feriados: !diasPromo.feriados
                      })
                    }
                    className={
                      diasPromo.feriados === true
                        ? 'opcoesDias__dia'
                        : 'opcoesDias__dia--color'
                    }
                  >
                    <p>F</p>
                  </div>
                </div>
              </div>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>6</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Qual será o valor do produto ou serviço?
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div className='cadastroAnuncio__inputPreco'>
                  <label for=''>Valor:</label>
                  <div>
                    <p>R$</p>
                    <input
                      {...register('valorAnuncio')}
                      onChange={t => setValor(t.target.value)}
                      value={valor}
                      name='valorAnuncio'
                      type='number'
                      min='0.00'
                      step='0.01'
                      placeholder='0,00'
                    />
                  </div>
                </div>
              </div>
              <p className='avisoErro'>{errors.valorAnuncio?.message}</p>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>7</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Qual será a quantidade?
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div className='cadastroAnuncio__inputPreco'>
                  <label for=''>Quantidade:</label>
                  <div>
                    <input
                      {...register('quantidade')}
                      value={quantidade}
                      onChange={t => setQuantidade(t.target.value)}
                      name='quantidade'
                      type='number'
                      min='0'
                      step='1'
                      placeholder='0'
                    />
                  </div>
                </div>
              </div>
              <p className='avisoErro'>{errors.quantidade?.message}</p>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>8</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Selecione o desconto ofertado:
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div className='cadastroAnuncio__inputPreco'>
                  <label for=''>Desconto:</label>
                  <div>
                    <input
                      {...register('desconto')}
                      value={desconto}
                      onChange={t => setDesconto(t.target.value)}
                      name='desconto'
                      type='number'
                      min='0'
                      maxLength={3}
                      step='0.01'
                      max='100'
                      placeholder='0'
                    />
                    <p>%</p>
                  </div>
                </div>
              </div>
              <p className='avisoErro'>{errors.desconto?.message}</p>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>9</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Determine a duração deste anúncio:
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div className='cadastroAnuncio__inputData'>
                  <div>
                    <label for=''>Data de início:</label>
                    <input
                      {...register('dataInicio')}
                      value={dataInicio}
                      onChange={t => setDataInicio(t.target.value)}
                      name='dataInicio'
                      type='datetime-local'
                    />
                  </div>
                  <p className='avisoErro'>{errors.dataInicio?.message}</p>
                  <div>
                    <label for=''>Data do término:</label>
                    <input
                      {...register('dataFim')}
                      value={dataFim}
                      onChange={t => setDataFim(t.target.value)}
                      name='dataFim'
                      type='datetime-local'
                    />
                  </div>
                  <p className='avisoErro'>{errors.dataFim?.message}</p>
                </div>
              </div>
            </section>
            <section className='cadastroAnuncio__etapa'>
              <div className='cadastroAnuncio__indice'>
                <div>
                  <p>10</p>
                </div>
                <label className='cadastroAnuncio__titulo'>
                  Adicione algumas imagens:
                </label>
              </div>
              <div className='cadastroAnuncio__conteudoPrincipal'>
                <div>
                  <label onClick={ () => toast.warning("Insira pelo menos 2 imagens e destaque uma ;D")} for='arquivo'>Adicionar imagem</label>
                  <input
                    {...register('imagem')}
                    style={{ display: 'none' }}
                    multiple
                    accept='image/*'
                    onChange={i => PreviewImage(i)}
                    type='file'
                    name='imagem'
                    id='arquivo'
                  />
                </div>
                <div className='cadastroAnuncio__listaImagens'>
                  {imagens.map(imagem => {
                    return (
                      <div
                        id={imagem.url}
                        onMouseOver={() => MouseOver('btn' + imagem.url)}
                        onMouseOut={() => MouseOut('btn' + imagem.url)}
                        className={
                          imagemPrincipal === imagem
                            ? 'teste teste--destacado'
                            : 'teste teste--transparente'
                        }
                      >
                        <img src={imagem.url} alt='' />
                        <div id={'btn' + imagem.url} className='hoverImg'>
                          <button
                            onClick={() => DeletarImagem(imagem.url)}
                            type='button'
                          >
                            <span
                              className='iconify-inline'
                              data-icon='ant-design:delete-filled'
                            ></span>
                            <p>Deletar</p>
                          </button>
                          <button
                            onClick={() => setImagemPrincipal(imagem)}
                            type='button'
                          >
                            <span
                              className='iconify-inline'
                              data-icon='ant-design:star-filled'
                            ></span>
                            <p>Destacar</p>
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <p className='avisoErro'>{errors.imagem?.message}</p>
            </section>
            <button className='btnCadastrar' type='submit'>
              Cadastrar
            </button>
          </form>
          {status.tipo === 'success' ? (
            <p style={{ color: 'green' }}>{status.mensagem}</p>
          ) : (
            ''
          )}
          {status.tipo === 'error' ? (
            <p style={{ color: 'red' }}>{status.mensagem}</p>
          ) : (
            ''
          )}
        </div>
      </main>
      <Rodape />
    </div>
  )
}
