import "../../assets/css/global.css"
import "../../assets/css/header.css"
import "../../assets/css/modal.css"
import "../../assets/css/checkbox.css"
import "../../assets/css/switch.css"
import "../../assets/css/checkboxRound.css"


import Logo from "../../assets/img/LogoEuQueroVantagens.png"
import LoginIcon from '@mui/icons-material/Login';
import { useEffect, useState } from "react"
import { useNavigate, Navigate, Link, Modal } from "react-router-dom";
import InputMask from 'react-input-mask';
import api from "../../services/api"

import Parse from 'parse/dist/parse.min.js';
// import { PersonComponent } from './PersonComponent'

export default function CabecalhoDeslogado() {

    const navigate = useNavigate();

    //Configurando Parse
    const PARSE_APPLICATION_ID = 'Ras1fgOJoTquJRPTS4zzhz7gniwCYsZS5nkfR1jO';
    const PARSE_HOST_URL = 'https://parseapi.back4app.com/';
    const PARSE_JAVASCRIPT_KEY = 'WSD4z8tmxOQCN4fusj7yF1CDbddGVsKuTC2Rtm8t';
    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = PARSE_HOST_URL;


    const [documento, setDocumento] = useState("");
    const [senha, setSenha] = useState("");
    const [email, setEmail] = useState("");

    const [opcaoTipo, setOpcaoTipo] = useState(false);
    const [erroMensagem, setErroMensagem] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    function MostrarMenu() {
        var menu = document.getElementById("menu")

        if (window.innerWidth <= 910) {
            if (menu.style.display === "flex") {
                console.log("aí")
                menu.style.display = "none"

            } else {
                menu.style.display = "flex"
            }
        }
    }

    function mudarInput() {
        var option = document.getElementById("switchOpt");
        // var input = document.getElementById("inputOPT");

        if (option.checked == true) {
            // input.placeholder = "CNPJ";
            setOpcaoTipo(true);
            document.getElementById("emp").style.borderBottom = "1px solid #f57200"
            document.getElementById("cli").style.borderBottom = "1px solid #542494"
        } else {
            // input.placeholder = "CPF";
            setOpcaoTipo(false);
            document.getElementById("cli").style.borderBottom = "1px solid #f57200"
            document.getElementById("emp").style.borderBottom = "1px solid #542494"
        }
    }

    function abrirModal() {
        var modal = document.getElementById("myModal");

        modal.style.display = "block";

        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }
    }


    function abrirModalSenha() {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";


        var modalSenha = document.getElementById("modalSenha");
        modalSenha.style.display = "block";

        window.onclick = function (event) {
            if (event.target == modalSenha) {
                modalSenha.style.display = "none";
            }
        }

    }

    function efetuaLogin(evento) {
        evento.preventDefault();

        if (opcaoTipo === false) {

            var myBody = JSON.stringify({
                "cpf": documento,
                "senha": senha
            });

            api.post("/login/cliente", myBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    localStorage.setItem("usuario-login", response.data.token);
                    setIsLoading(false);
                    //window.location.reload(true);
                    navigate("/edicaoPerfil");
                } else {
                    setIsLoading(false);
                }
            }).catch(erro => {
                setIsLoading(false);
                setErroMensagem("Ops! Email e/ou senha inválidos.");
                console.log(erro)
            })

        } else {

            var myBody = JSON.stringify({
                "cnpjMeiCei": documento,
                "senha": senha
            });

            api.post("/login", myBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    localStorage.setItem("usuario-login", response.data.token);
                    setIsLoading(false);
                    //window.location.reload(true);
                    navigate("/meusAnuncios");
                } else {
                    setIsLoading(false);
                }
            }).catch(erro => {
                setIsLoading(false);
                setErroMensagem("Ops! Email e/ou senha inválidos.");
                console.log(erro)
            })
        }

       
    }

    async function addPerson(emailRecebido) {
        
            //Adicionando email a tabela User no Parse
            // create a new Parse Object instance
            const User = new Parse.Object('User');
            // define the attributes you want for your Object
            User.set('email', emailRecebido);
            // save it on Back4App Data Store
            await User.save();
            alert('Email salvo!');
    }

  
    const recuperarSenha = async function () {
        // Note that this value come from state variables linked to your text input
        const emailValue = email;
        console.log('entrou')
        console.log(emailValue)

        
        try {
            addPerson(email)
        
            await Parse.User.requestPasswordReset(emailValue);
            console.log('funcionou!')
            alert(`Sucesso! Por favor cheque seu email ${email} para prosseguir com a redefinição de senha.`);
            return true;
        } catch (error) {
            // Error can be caused by lack of Internet connection
            alert(`Erro! ${error}`);
            return false;
        }
    };

    useEffect(() => {
        function handleResize() {
            var menu = document.getElementById("menu")
            menu.removeAttribute("style")
        }
        window.addEventListener('resize', handleResize)
    })


    return (
        <div>
            <header>
                <div className="navegacaoPrincipal">
                    <div id="menu" className="navegacaoPrincipal__menu">
                        <div className="container">
                            <div className="navegacaoPrincipal__barraPesquisa">
                                <input type="text" className="navegacaoPrincipal__inputPesquisa"
                                    placeholder="Encontre a promoção certa para você" />
                                <button className="navegacaoPrincipal__btnPesquisa">
                                    <span className="iconify-inline" data-icon="fa-solid:search"></span>
                                </button>
                            </div>
                            <a className="navegacaoPrincipal__links">
                                <span className="iconify-inline" data-icon="whh:coupon"></span>
                                <p>meus cupons</p>
                            </a>
                            <a style={{width:'79px'}} className="navegacaoPrincipal__links navegacaoPrincipal__links--width">
                                <span className="iconify-inline" data-icon="la:dna"></span>
                                <button style={{ cursor: 'pointer' , color: 'white', textTransform:'uppercase', backgroundColor:'transparent'}} onClick={() =>  abrirModal()}>dna</button>
                            </a>
                        </div>
                    </div>
                    <div className="container">
                        <button onClick={() => MostrarMenu()} id="btnMenu" className="navegacaoPrincipal__btnMenu">
                            <span className="iconify-inline" data-icon="ant-design:menu-outlined"></span>
                        </button>
                        <Link to='/'><img src={Logo} alt="Logo do EuQueroVantagens" /></Link>
                        <div className="navegacaoPrincipal__barraPesquisa">
                            <input type="text" className="navegacaoPrincipal__inputPesquisa"
                                placeholder="Encontre a promoção certa para você" />
                            <button className="navegacaoPrincipal__btnPesquisa">
                                <span className="iconify-inline" data-icon="fa-solid:search"></span>
                            </button>
                        </div>
                        <div className="navegacaoPrincipal__btnLoginCadastro">
                            <div>
                                <button onClick={() => abrirModal()}>entrar</button>
                                <button onClick={() => navigate("/cadastro")}>cadastre-se</button>
                            </div>
                        </div>
                        <button onClick={() => abrirModal()} className="navegacaoPrincipal__btnUser">
                            <span className="iconify-inline" data-icon="carbon:user-avatar-filled"></span>
                        </button>
                    </div>
                </div>
                <div className="navegacaoSecundaria">
                    <nav className="container">
                        <a href="" className="navegacaoSecundaria__links">regulamento</a>
                        <Link to={"/SobreNos"} className="navegacaoSecundaria__links">sobre nós</Link>
                        <a href="" className="navegacaoSecundaria__links">fale conosco</a>
                        <Link to={'/comousar'} className="navegacaoSecundaria__links">como usar</Link>
                        <a href="" className="navegacaoSecundaria__links">política de privacidade</a>
                        <a href="/#secaoPlanos" className="navegacaoSecundaria__links navegacaoSecundaria__links--weight">descubra nossos planos
                            de vantagens
                            {/* <span className="iconify-inline" data-icon="akar-icons:circle-chevron-right-fill"></span> */}
                        </a>
                    </nav>
                </div>
            </header>

            <div id="myModal" className="modal">

                <div className="modal-content">
                    <Link className="logo" to='/'><img src={Logo} alt="Logo do EuQueroVantagens" /></Link>

                    <p className="modal-content__text modal-content__text--big">Bem vindo(a) de volta!</p>
                    <p className="modal-content__text">Entre na sua conta</p>

                    <div className="c-switch">
                        <p  id="cli" className="c-switch__label">cliente</p>
                        <label className="switch">
                            <input id="switchOpt" type="checkbox" title="cliente ou empresa?" onChange={() => mudarInput()} />
                            <span className="slider round"></span>
                        </label>
                        <p id="emp" className="c-switch__label">empresa</p>
                    </div>

                    <form action="" className="c-form" id="c-form" onSubmit={efetuaLogin}>
                        {
                            opcaoTipo === false ? (
                                <InputMask mask="999.999.999-99" required id="inputOPT" className="modal-content__input" type="text" placeholder="CPF" title="CPF" value={documento} onChange={(campo) => setDocumento(campo.target.value)} />
                            ) : (
                                <InputMask mask="99.999.999/9999-99" required id="inputOPT" className="modal-content__input" type="text" placeholder="CNPJ" title="CNPJ" value={documento} onChange={(campo) => setDocumento(campo.target.value)} />

                            )
                        }
                        <input className="modal-content__input" type="password" placeholder="Senha" title="senha" value={senha} onChange={(campo) => setSenha(campo.target.value)} />

                        <div className="c-loginOptions">
                            <div className="c-lembrar">
                                <label htmlFor="lembrar" className="c-lembrar__check modal-content__text modal-content__text--small">
                                    <input type="checkbox" id="lembrar" title="lembrar de mim?" />
                                    <span className="checkmarkR"></span>
                                    Lembre de mim
                                </label>
                            </div>

                            <button onClick={() => abrirModalSenha()} className="modal-content__text modal-content__link" href="#">Esqueceu sua senha?</button>
                        </div>

                        <p className="modal-content__text modal-content__link" style={{ color: '#FFF800', fontSize: '15px', textAlign: 'center', marginTop:'3%'}}>{erroMensagem}</p>
                        {
                            isLoading === false ? (
                                <button className="modal-content__button" type="submit">
                                    Login
                                    <span style={{ visibility: "hidden" }}>..</span>
                                    <LoginIcon sx={{ fontSize: 35 }} />
                                </button>
                            ) : (

                                <button className="modal-content__button" type="submit" disabled>
                                    Carregando...
                                </button>)
                        }


                    </form>

                    <div className="c-cadastroM">
                        <p className="modal-content__text modal-content__text--small">Não tem conta?</p>
                        <button className="modal-content__text modal-content__link" onClick={() => navigate("/cadastro")}> Cadastre-se</button>
                    </div>

                </div>

            </div>

            <div id="modalSenha" className="modal">

                <div className="modal-content" style={{ marginTop: "8%" }}>
                    <Link className="logo" to='/'><img src={Logo} alt="Logo do EuQueroVantagens" /></Link>

                    <p className="modal-content__text modal-content__text--big">Problemas para entrar?</p>
                    <p className="modal-content__text">Informe seu email :)</p>


                    <input className="modal-content__input" type="text" placeholder="Email" title="email" value={email} onChange={(campo) => setEmail(campo.target.value)} />

                    <p className="modal-content__text modal-content__link">{erroMensagem}</p>
                    {
                        isLoading === false ? (
                            <button className="modal-content__button" type="primary" onClick={() => recuperarSenha()}>
                                Recuperar Senha
                            </button >
                        ) : (

                            <button className="modal-content__button" type="submit" disabled>
                                Carregando...
                            </button>)
                    }

                </div>

            </div>
        </div>
    )
}