import { logDOM } from '@testing-library/react';
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../assets/css/compraEfetuada.css'
import imagem from '../../assets/img/compraRealizada.png'
import visa from '../../assets/img/visa.png'
import api from '../../services/api';

export default function CompraEfetuada() {
    const location = useLocation();
    const [pacoteEscolhido, setPacoteEscolhido] = useState({});
    const [boleto, setBoleto] = useState({});
    const [linhaBoleto, setLinhaBoleto] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        setPacoteEscolhido(location.state?.pacoteEscolhido)
        console.log(location.state?.pacoteEscolhido)
        console.log(location.state?.cadastro)
    }, [])

    useEffect(() => {
        setBoleto(location.state?.linkBoleto)
        setLinhaBoleto(location.state?.linhaBoleto)
        console.log(location.state?.linkBoleto)
        console.log(location.state?.linhaBoleto)
    }, [])

    return (
        // PÁGINA QUE RETORNA ASSIM QUE A COMPRA É EFETUADA COM SUCESSO

        <section className='purchaseSection'>
            <container className='purchaseContainer'>

                <div className='purchaseSucess'>
                    <img src={imagem} alt="" />
                    <div className='groupText'>
                        <h2>Compra realizada com sucesso!</h2>
                        <p>Seja bem vindo(a) a um mundo de vantagens</p>
                    </div>
                </div>

                <div className='sectionPurchase'>

                    <div className='purchaseDetails'>
                        <span id="detailsSan">Detalhes da compra</span>
                        <table className='detailsTable'>

                            <tr>
                                <td id='detailsIdFather'>Plano adquirido</td>
                                {
                                    pacoteEscolhido === 'master' ? <td id='detailsIdSon'>Master</td> : <td id='detailsIdSon'>Vip</td> 
                                }
                            </tr>

                            <tr>
                                <td id='detailsIdFather'>Linha digitável</td>
                                <td id='detailsIdSon'>{linhaBoleto}</td>
                            </tr>

                            <tr>
                                <td id='detailsIdFather'>Duração do plano</td>
                                <td id='detailsIdSon'>1 mês</td>
                            </tr>

                            <tr>
                                <td id='detailsIdFather'>Preço</td>
                                {pacoteEscolhido === 'master' ? <td id='detailsIdSon'>R$ 59,99</td> : <td id='detailsIdSon'>R$ 29,99</td>}
                            </tr>

                            <tr className='detailsClass'>
                                <td id='detailsIdFather'>Forma de pagamento</td>
                                <div className='detailsGroup'>
                                    <td id='detailsIdSon'>Boleto</td>
                                </div>
                            </tr>
                        </table>
                    </div>

                    <button id="printButton"><a href={boleto} target="_blank" rel='external' download={boleto} type='application/pdf'>Imprimir boleto</a></button>
                    <button id="detailsButton" onClick={() => navigate('/codigodna')}>Concluir</button>
                </div>
            </container>
        </section>
    );
}


