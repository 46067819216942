import React, { useEffect } from 'react'
import Header from '../../components/cabecalho/cabecalhoDeslogado.jsx'
import '../../assets/css/dna.css'
import Footer from '../../components/rodape/rodape.jsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

moment.locale('pt-br')


export default function DnaDescritivo() {
  const navigate = useNavigate()

  return (
    <div>
      <Header/>
      <main>
        <section className='bannerDna'>
          <div className='container'>
            <h1>Conheça o DNA!</h1>
            <p>
              Convide amigos e comerciantes, receba bônus até sua quinta geração
              e construa sua renda extra aqui mesmo!
            </p>
            <button>Ver regras</button>
          </div>
        </section>
        <section className='infoDna container'>
          <div className='boxInfoDna'>
            <div className='boxResumoBonus'>
              <div>
                <h2>Como ganhar seu bônus?</h2>
                <p>
                  Aqui você indica amigos e já começa a ganhar bônus!
                  Assim que alguém se cadastra no Quero Vantagens pelo
                  seu link ou QR Code, sendo Vip ou Master, já começa a fazer
                  parte do seu DNA e você recebe por isso!
                </p>
              </div>
              <div className='codigoUsuario'>
                <h2>Compartilhe seu código de convite</h2>
                <div>
                  <p>sEu fUtuR0 c0D1g0</p>
                </div>
                <div>
                  <button onClick={() => navigate('/Cadastro')}>Junte-se já!</button>
                </div>
              </div>
            </div>
            
            <div className='boxWinMoney'>
              <div>
                <h2>Entre para uma família e aproveite as vantagens!</h2>
                <p>
                  Veja os bônus que você recebeu até o momento e o que falta
                  para alcançar todas as vantagens oferecidas pelo sistema DNA!{' '}
                </p>

                {/* <button onClick={() => navigate('/codigodna')} className='button-icon-dna'>
                <span>Saiba mais</span>
                <span
                  className='iconify-inline'
                  data-icon='akar-icons:chevron-right'
                />
              </button> */}
              </div>

              </div>
          </div>

        </section>
        <section className='tabelaFilhos container'>
    
        </section>
      </main>
      <Footer />
    </div>
  )
}
