import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import "../../../assets/css/cadastro.css"
import "../../../assets/css/checkbox.css"
import "../../../assets/css/switch.css"
import logo from "../../../assets/img/LogoCinza-Vetor.svg"
import comemoracao from "../../../assets/img/undraw_happy_announcement_re_tsm0.svg"
import api from "../../../services/api";


export default function CadastroEmpresa() {

    const navigate = useNavigate();

    const location = useLocation();

    const [email, setEmail] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [nomeFantasia, setNomeFantasia] = useState("");
    const [orgaoClasse, setOrgaoClasse] = useState("")
    const [numOrgaoClasse, setNumOrgaoClasse] = useState("")
    const [nomeCompleto, setNomeCompleto] = useState("")
    // const [numAlvara, setNumAlvara] = useState("");
    const [dadosCadastro, setDadosCadastro] = useState({});
    const [Cnpj, setCnpj] = useState("");
    const [senha, setSenha] = useState("");
    const [senhaConfirma, setSenhaConfirma] = useState("");
    const [empresa, setEmpresa] = useState("");

    const [isCnpjValid, setIsCnpjValid] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const [senhaIgual, setSenhaIgual] = useState(false);
    const [emailValido, setEmailValido] = useState(false);
    var feedback = document.getElementById("msgFeedback");
    var feedbackEmail = document.getElementById("msgFeedbackEmail");

    var usuario = "";

    function validarCNPJ(cnpj) {
        var input = document.getElementById("iCNPJ")

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '' ||
            cnpj.length != 14 ||
            cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999") {

            // console.log("ERrADAo");
            input.classList.add('c-input--invalid');
            toast.warning("CNPJ inválido");

        } else {

            var tamanho = cnpj.length - 2
            var numeros = cnpj.substring(0, tamanho);
            var digitos = cnpj.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }

            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) {
                input.classList.add('c-input--invalid');
            } else {
                tamanho = tamanho + 1;
                numeros = cnpj.substring(0, tamanho);
                soma = 0;
                pos = tamanho - 7;

                for (i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2)
                        pos = 9;
                }

                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(1)) {
                    input.classList.add('c-input--invalid');
                    toast.warning("CNPJ inválido");
                } else {
                    setIsCnpjValid(true);
                    input.classList.remove('c-input--invalid');
                }
            }
        }
    }

    function efetuaLogin() {
        var myBody = JSON.stringify({
            "cnpjMeiCei": Cnpj,
            "senha": senha
        });

        api.post('/login', myBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resposta) => {
            console.log("antes do if");
            if (resposta.status === 200) {
                console.log("depois do if");
                return resposta.json()
                    .then((data) => {
                        console.log(data.token);
                        localStorage.setItem("usuario-login", data.token);
                    })
            }
        }).catch((erro) => { console.log(erro) });


    }

    function cadastrarUsuario() {
        var splited = location.state.value.split(";");
        // console.log(splited)
        var endereco = splited[0];
        // console.log(endereco);
        var contato = splited[1];

        var myBody = JSON.stringify({
            "idTipoUsuario": "D5926714-C22A-48FF-AD5C-F7BC2D5EB54F",
            "idEndereco": endereco,
            "idContato": contato,
            "email": email,
            "senha": senha,
            "codigo": "",
            "caminhoImagem": "",
            "token": ""
        });

        api.post('/usuarios', myBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resposta) => {
            // console.log("chegou antes do if");
            if (resposta.status === 201) {
                // console.log(resposta.json());
                // console.log("chegou dps");

                return resposta.json()
                    .then((data) => {
                        // console.log(data.idUsuario);
                        usuario = data.idUsuario;
                    })
                    .catch(console.error());
            }
        })

    }

    //
    // validação CNPJ
    const [basicInfo, setBasicInfo] = useState(null);

    function fetchInfo(cnpj) {

        const response = fetch(`https://legalentity.api.nfe.io/v1/legalentities/basicInfo/${cnpj}`);
        if (!response.ok) {
            console.log("ERROU!");
            throw new Error('Failed to fetch data');
        }
        const data = response.json();
        setBasicInfo(data);
        console.log(data);
    };


    // funcao que completa o cadastro e irá logar com o usuário cadastrado
    function cadastrarEmpresa(evento) {
        var input = document.getElementById("iCNPJ")

        //VALIDAÇÃO CNPJ
        //limpando máscara
        // let cnpjTratado = Cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "");
        // console.log(cnpjTratado);

        // evento.preventDefault();
        // var input = document.getElementById("iCNPJ")

        // fetchInfo(cnpjTratado);
        //

        if (dadosCadastro.novoCliente === undefined) {
            if (isCnpjValid == true && senha == senhaConfirma) {

                setIsLoading(true);

                var usuario = ({
                    novoUsuario: {
                        email: email,
                        senha: senha,
                        nomeComercial: nomeFantasia,
                        master: true
                    }
                })

                var empresa = ({
                    novaEmpresa: {
                        cnpjMeiCei: Cnpj,
                        razaoSocial: razaoSocial
                    }
                });

                dadosCadastro["novoUsuario"] = usuario.novoUsuario
                dadosCadastro["novaEmpresa"] = empresa.novaEmpresa

                setDadosCadastro({ ...dadosCadastro })

                console.log(dadosCadastro)

                // api.post('/enderecos', dadosCadastro, {
                //     headers: {
                //         'Content-Type': 'application/json'
                //     }
                // }).then(response => {
                //     if (response.status === 201) {
                //         console.log("Cadastro realizado")
                //         console.log(dadosCadastro)
                //         var myBody = JSON.stringify({
                //             cnpjmeicei: dadosCadastro.novaEmpresa.cnpjMeiCei,
                //             senha: dadosCadastro.novoUsuario.senha
                //         });

                //         api.post('/login', myBody, {
                //             headers: {
                //                 'Content-Type': 'application/json'
                //             }
                //         })
                //             .then(response => {
                //                 if (response.status === 200) {
                //                     localStorage.setItem('usuario-login', response.data.token)
                //                 }
                //             })
                //     }
                // }).catch(erro => {
                //         console.log(erro)
                //     })

                navigate('/CadastroEndereco', { state: { cadastro: dadosCadastro, nomeCompleto: nomeCompleto } })

                console.log(nomeCompleto)


            } else if (isCnpjValid == false) {
                input.classList.add('c-input--invalid');
                toast.error("CNPJ inválido");
            } else {
                toast.error("As senhas não iguais");
            }
        } else {
            dadosCadastro.novoCliente["orgaoClasse"] = orgaoClasse
            dadosCadastro.novoCliente["numeroOrgaoClasse"] = numOrgaoClasse
            dadosCadastro.novoUsuario["nomeComercial"] = nomeFantasia

            setDadosCadastro({ ...dadosCadastro })

            navigate('/planoMaster', { state: { cadastro: dadosCadastro } })


        }
    }

    function lerCampos() {
        var senha = document.getElementById("password").value
        var senhaConfirmacao = document.getElementById("passwordConfirmation").value

        if (senha !== senhaConfirmacao) {
            feedback.innerHTML = 'As senhas não coincidem!'
            setSenhaIgual(false)
        } else {
            setSenhaIgual(true)
            feedback.innerHTML = ''
        }

    }

    function verificarEmail() {
        var email = document.getElementById("email").value

        api.get('Usuarios/BuscarEmail?email=' + email)
            .then(response => {
                if (response.status === 200) {
                    //console.log(response)
                    feedbackEmail.innerHTML = "Ops, esse email já em uso!"
                    setEmailValido(false)

                } else {
                    setEmailValido(true)
                    feedbackEmail.innerHTML = ""
                }
            })
            .catch(erro => {
                console.log(erro)
                setEmailValido(true)
                feedbackEmail.innerHTML = ''
            })

    }

    function validacaoEmail() {

        const usuario = email.split('@')[0];
        const dominio = email.split('@')[1];

        if ((usuario.length >= 1) &&
            (dominio.length >= 3) &&
            (usuario.search("@") == -1) &&
            (dominio.search("@") == -1) &&
            (usuario.search(" ") == -1) &&
            (dominio.search(" ") == -1) &&
            (dominio.search(".") != -1) &&
            (dominio.indexOf(".") >= 1) &&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            
        } else {
            toast.error("Por favor, insira um email válido!")
        }
    }

    // useEffect(() => {
    //     if (location.state.cadastro !== undefined) {
    //         setDadosCadastro(location.state.cadastro)
    //     }
    // }, [])

    return (
        <div className="c-corpo">
            <header className="c-header container">
                <img src={logo} alt="" />
            </header>

            <main className="c-main container">
                <section className="c-cadastro">
                    <h1 className="c-cadastro__titulo">Crie sua conta de empresa</h1>

                    <div className="c-progress">
                        <div className="c-progress__bar--completed c-progress__bar"></div>
                        <div className="c-progress__bar--completed c-progress__bar"></div>
                        <div className="c-progress__bar--completed c-progress__bar"></div>
                    </div>

                    <h2 className="c-cadastro__subtitulo">Empresa</h2>

                    <form name="form" className="c-inputs--lessmargin c-inputs--center" action="" onSubmit={cadastrarEmpresa}>

                        <label className="new_label">Proprietário Responsável</label>
                        <input required={dadosCadastro?.novoCliente !== undefined ? false : true} className="c-inputs__text" type="text" placeholder="Nos informe o nome" value={nomeCompleto} onChange={(campo) => setNomeCompleto(campo.target.value)} />
                        <label className="new_label">Email</label>
                        <input required={dadosCadastro?.novoCliente !== undefined ? false : true} className="c-inputs__text" type="email" placeholder="Informe o email de contato "
                            name="email"
                            onBlur={(e) => validacaoEmail(e.target.value)}
                            value={email}
                            id="email"
                            onChange={(campo) => {
                                verificarEmail()
                                setEmail(campo.target.value)
                            }
                            } />
                        <div id="feedbackEmail"><p id="msgFeedbackEmail"></p></div>
                        <label className="new_label">Razão Social</label>
                        <input required={dadosCadastro?.novoCliente !== undefined ? true : false} className="c-inputs__text" type="text" placeholder="Informe o órgão de classe" value={orgaoClasse} onChange={(campo) => setOrgaoClasse(campo.target.value)} />
                        <input required={dadosCadastro?.novoCliente !== undefined ? true : false} className="c-inputs__text" type="text" placeholder="Informe o número de órgão de classe" value={numOrgaoClasse} onChange={(campo) => setNumOrgaoClasse(campo.target.value)} />
                        <input required className="c-inputs__text" type="text" placeholder="Informe a razão social" value={razaoSocial} onChange={(campo) => setRazaoSocial(campo.target.value)} />
                        <label className="new_label">Nome Fantasia</label>
                        <input required className="c-inputs__text" type="text" placeholder="Insira o nome fantasia" value={nomeFantasia} onChange={(campo) => setNomeFantasia(campo.target.value)} />
                        {/* <div className="c-inputs--divided"> */}
                        {/* <input required className="c-inputs__text c-inputs__text--half" type="text" placeholder="Número do alvará" value={numAlvara} onChange={(campo) => setNumAlvara(campo.target.value)} /> */}
                        {/* <input required className="c-inputs__text" type="text" placeholder="CNPJ,MEI ou CEI" value={Cnpj} onChange={(campo) => setCnpj(campo.target.value)} /> */}
                        <label className="new_label">CNPJ,MEI ou CEI</label>
                        <InputMask style={dadosCadastro?.novoCliente !== undefined ? { display: 'none' } : { display: 'block' }} id="iCNPJ" mask="99.999.999/9999-99" required={dadosCadastro?.novoCliente !== undefined ? false : true} className="c-inputs__text" type="text" placeholder="00.000.000/0000-00" onBlur={(campo) => validarCNPJ(campo.target.value)} value={Cnpj} onChange={(campo) => setCnpj(campo.target.value)} />
                        {/* </div> */}
                        {/* <div style={dadosCadastro?.novoCliente !== undefined ? { display: 'none' } : { display: 'flex' }} className="c-inputs--divided1 c-inputs--divided"> */}
                        <label className="new_label">Senha</label>
                        <div className="c-inputs--divided1 c-inputs--divided">
                            <input required={dadosCadastro?.novoCliente !== undefined ? false : true} className="senha c-inputs__text c-inputs__text--half" type="password" placeholder="Insira sua senha"
                                value={senha}
                                id="password"
                                onChange={(campo) => {
                                    lerCampos()
                                    setSenha(campo.target.value)
                                }}
                                onClick={() => toast.warning("Sua senha deve ter de 4 a 16 caracteres!")} />

                            <input required={dadosCadastro?.novoCliente !== undefined ? false : true} className="senha c-inputs__text c-inputs__text--half" type="password" placeholder="Confirme a senha"
                                value={senhaConfirma}
                                id="passwordConfirmation"
                                onChange={(campo) => {
                                    lerCampos()
                                    setSenhaConfirma(campo.target.value)
                                }} />

                        </div>
                        <div id="feedback"><p id="msgFeedback"></p></div>



                        <div className="c-buttons">
                            <button className="c-buttons__button c-buttons__button--voltar" type="button" onClick={() => navigate(-1)}>retornar</button>
                            {
                                isLoading === false ? (
                                    senhaIgual === false ?
                                        <button className="c-buttons__button c-buttons__button--concluir" disabled type="submit">concluir</button>
                                        : (emailValido === false ? <button className="c-buttons__button c-buttons__button--concluir" disabled type="submit">concluir</button>
                                            : <button className="c-buttons__button c-buttons__button--concluir" type="submit">concluir</button>)
                                ) :
                                    <button className="c-buttons__button c-buttons__button--proximo" disabled type="submit">carregando..</button>

                            }
                        </div>
                    </form>

                </section>

                <section className="c-imagem">
                    <img src={comemoracao} alt="pessoas felizes comemorando" />
                    <ToastContainer />
                </section>
            </main>

            <footer className="c-footerC container">
                <p className="c-footer__texto">@2022 EuQueroVantagens</p>
                <a className="c-footer__texto" href="/">Politica de privacidade</a>
            </footer>
        </div>
    )
}