import { usuarioAutenticado, parseJwt } from "../../services/auth"
import CabecalhoLogado from "./cabecalhoLogado"
import CabecalhoDeslogado from "./cabecalhoDeslogado"

export default function AnaliseHeader(){
    var tipo = "";
    if(usuarioAutenticado() === true){
        // console.log(parseJWT());
        switch (parseJwt().role) {
            case "d0593a14-a9be-42b3-aaf8-3223a137dbe7":
                tipo = "Empresa";
                break;
            case "b8d18b05-f89a-4503-9eee-8db9d4ecaca4":
                tipo = "Cliente Free";
                break;
            case "57fd82ca-1327-4dc7-b66a-f4aacd1fff66":
                tipo = "Cliente VIP";
                break;
            default:
                tipo = "Usuario";
                break;
        }
        return(<CabecalhoLogado id={parseJwt().jti} name={parseJwt().name} tipo={tipo}/>)
    } else{
        return(<CabecalhoDeslogado/>)
    }
}
