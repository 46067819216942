import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputMask from 'react-input-mask';

import "../../../assets/css/cadastro.css"
import "../../../assets/css/checkbox.css"
import "../../../assets/css/switch.css"

import logo from "../../../assets/img/LogoCinza-Vetor.svg"
import comemoracao from "../../../assets/img/undraw_happy_announcement_re_tsm0.svg"

export default function CadastroEndereco() {

    const navigate = useNavigate();
    const location = useLocation()
    const [dadosCadastro, setDadosCadastro] = useState({})
    const [cep, setCep] = useState("");
    const [endereco, setEndereco] = useState("");
    const [complemento, setComplemento] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidade, setCidade] = useState("");
    const [UF, setUF] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    function limpa_formulário_cep() {
        //Limpa valores do formulário de cep.
        setEndereco("");
        setBairro("");
        setCidade("");
        setUF("");
        // document.getElementById('rua').value=("");
        // document.getElementById('bairro').value=("");
        // document.getElementById('cidade').value=("");
        // document.getElementById('uf').value=("");
    }

    function preencherPosition() {
        var myAPIKey = "vWyk3dDn92M41JJTqI4kjfHuhvuw1XtnUVSgST65aJc";

        var splited = endereco.split(" ");
        // console.log(splited);

        var endFormat = "";

        for (let index = 0; index < splited.length; index++) {
            endFormat = endFormat + "+" + splited[index];
            // console.log(splited[index]);
        }
        // console.log(endFormat);

        var formated = numero + endFormat + "%2C+Brazil";
        // console.log(formated)

        var myUrl = "https://geocode.search.hereapi.com/v1/geocode?q= " + formated + "&apiKey=vWyk3dDn92M41JJTqI4kjfHuhvuw1XtnUVSgST65aJc";

        fetch(myUrl)
            .then((resposta) => resposta.json())
            .then((data) => {
                // console.log(data.items[0].position)
                var position = data.items[0].position;
                // console.log(position.lng);
                setLat(position.lat);
                setLng(position.lng);
            });
    }

    function preencherCallback(conteudo) {
        if (conteudo != null) {
            //Atualiza os campos com os valores.
            setEndereco(conteudo.logradouro);
            setBairro(conteudo.bairro);
            setCidade(conteudo.localidade);
            setUF(conteudo.uf);

            preencherPosition();

        } //end if.
        else {
            //CEP não Encontrado.
            limpa_formulário_cep();
            alert("CEP não encontrado.");
        }
    }

    function pesquisacep(valor) {
        //Nova variável "cep" somente com dígitos.
        var cep = valor.replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep)) {

                var myURL = 'https://viacep.com.br/ws/' + cep + '/json';

                fetch(myURL)
                    .then(respota => respota.json())
                    .then(data => preencherCallback(data));

            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();
                alert("Formato de CEP inválido.");
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    };

    function cadastrarEndereco(evento) {

        evento.preventDefault();

        setIsLoading(true);

        preencherPosition();

        var enderecoUsuario = ({
            novoEndereco: {
                cep: cep,
                endereco1: endereco,
                numEndereco: numero,
                complemento: complemento,
                cidade: cidade,
                bairro: bairro,
                estado: UF,
                latitude: lat,
                longitude: lng
            }
        });

        dadosCadastro["novoEndereco"] = enderecoUsuario.novoEndereco

        // dadosCadastro = {
        //     ["novoEndereco"]: enderecoUsuario.novoEndereco
        // }

        setDadosCadastro({ ...dadosCadastro })

        console.log(dadosCadastro.novoEndereco?.cep)

        setIsLoading(false);

        navigate('/cadastroContato', { state: { cadastro: dadosCadastro } })

    }

    useEffect(() => {
        if (location.state?.cadastro !== undefined) {
            setDadosCadastro(location.state?.cadastro)
            console.log(location.state?.cadastro)
        }
    }, [])

    return (
        <div className="c-corpo">
            <header className="c-header container">
                <img src={logo} alt="logo do euquerovantagens" />
            </header>

            <main className="c-main container">
                <section className="c-cadastro">
                    <h1 className="c-cadastro__titulo">Cadastre-se</h1>

                    <div className="c-progress">
                        <div className="c-progress__bar--completed c-progress__bar"></div>
                        <div className="c-progress__bar"></div>
                        <div className="c-progress__bar"></div>
                    </div>

                    <h2 className="c-cadastro__subtitulo">Endereço</h2>

                    <form className="c-inputs c-inputs--center" action="" onSubmit={cadastrarEndereco}>

                        {/* <input required className="c-inputs__text" type="text" placeholder="CEP" value={cep} onChange={(campo) => setCep(campo.target.value)} onBlur={(campo) => pesquisacep(campo.target.value)} /> */}
                        <label className="new_label">CEP</label>
                        <InputMask mask="99999-999" required className="c-inputs__text " type="text" placeholder="00000-000" value={cep} onChange={(campo) => setCep(campo.target.value)} onBlur={(campo) => pesquisacep(campo.target.value)} />
                        <label className="new_label">Endereço</label>
                        <input id="rua" required className="c-inputs__text " type="text" placeholder="Rua Fictícia" value={endereco} onChange={(campo) => setEndereco(campo.target.value)} />
                        <label className="new_label">Número do endereço</label>
                        <input required className="c-inputs__text" type="text" placeholder="002" value={numero} onChange={(campo) => setNumero(campo.target.value)} />
                        <label className="new_label">Complemento (Opcional) </label>

                        <input className="c-inputs__text" type="text" placeholder="Casa 2 " value={complemento} onChange={(campo) => setComplemento(campo.target.value)} />
                        <label className="new_label">Bairro</label>
                        <input id="bairro" required className="c-inputs__text" type="text" placeholder="Vila das rosas" value={bairro} onChange={(campo) => setBairro(campo.target.value)} />

                        <label className="new_label">Cidade</label>
                        <input id="cidade" required className="c-inputs__text " type="text" placeholder="São Paulo" value={cidade} onChange={(campo) => setCidade(campo.target.value)} />
                        <label className="new_label">UF</label>
                        
                        <input id="uf" required className="c-inputs__text" type="text" placeholder="SP" value={UF} onChange={(campo) => setUF(campo.target.value)} />


                        <div className="c-buttons">
                            <button className="c-buttons__button c-buttons__button--voltar" type="button" onClick={() => navigate(-1)}>retornar</button>
                            {
                                isLoading === false ? (
                                    <button className="c-buttons__button c-buttons__button--proximo" type="submit">proximo</button>
                                ) : (
                                    <button className="c-buttons__button c-buttons__button--proximo" disabled type="submit">carregando..</button>
                                )
                            }

                        </div>

                    </form>

                </section>

                <section className="c-imagem">
                    <img src={comemoracao} alt="pessoas felizes comemorando" />
                </section>

            </main>

            <footer className="c-footer container">
                <p className="c-footer__texto">@2022 EuQueroVantagens</p>
                <a className="c-footer__texto" href="#">Politica de privacidade</a>
            </footer>
        </div>
    )
}