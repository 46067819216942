import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Route, BrowserRouter as Router, Routes, Outlet, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

// importacoes paginas
import CadastroAnuncios from "../src/pages/anuncios/cadastroAnuncios"
import MeusAnuncios from "../src/pages/anuncios/meusAnuncios"
import CadastroInicio from "../src/pages/cadastro/cadastroInicio"
import NotFound from './pages/notFound';

import CadastroEndereco from "../src/pages/cadastro/cadastroEmpresa/cadastroEndereco"
import CadastroEmpresa from "../src/pages/cadastro/cadastroEmpresa/cadastroEmpresa"
import CadastroContato from "../src/pages/cadastro/cadastroEmpresa/cadastroContato"

import CadastroUsuario from "../src/pages/cadastro/cadastroComum/cadastroUsuario"
// import CadastroContatoComum from "../src/pages/cadastro/cadastroComum/cadastroContComum"

import Login from "../src/pages/login/login"
// em duvida se login seria page ou component pq é modal ....

import EdicaoPerfil from "../src/pages/perfil/edicaoPerfil"
import PaginaAnuncio from "../src/pages/anuncios/paginaAnuncio"
import PlanoUsuario from "../src/pages/planos/planoComum/planoUsuario"
import PlanoEmpresa from "../src/pages/planos/planoEmpresa"
import PlanoMaster from "../src/pages/planos/planoMaster"
import PlanoVip from "../src/pages/planos/planoVip"
import CompraEfetuada from "../src/pages/planos/compraEfetuada"
import Home from "../src/pages/home"
import VerTodosAnuncios from './pages/anuncios/verTodosAnuncios';
import ComoUsar from './pages/telasInfo/comoUsar';
import SobreNos from './pages/telasInfo/sobrenos';
import CodigoDna from './pages/cadastro/cadastroEmpresa/codigoDna';
import ListaCupons from './pages/cupons/listaCupons';
import Atividades from './pages/cupons/atividade';

import Cadastro from "../src/pages/cadastro/cadastroEmpresa/formuFunction"
import Endereco from "../src/pages/cadastro/cadastroEmpresa/cadastroEndereco"
import Dna from './pages/dna/dna';

import { usuarioAutenticado } from './services/auth'
import DnaDescritivo from './pages/dna/dnaDeslogado';

const UsuarioLogado = () => {
  return (
    usuarioAutenticado() ?
      <Outlet /> : <Navigate to="/" />
  )
}

const routing =
  (
    <Router>
      <div>
        <Routes>
          <Route path='/*' element={<NotFound />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/Cadastro" element={<CadastroInicio />} />

          <Route path="/CadastroEmpresa" element={<CadastroEmpresa />} />
          <Route path="/CadastroContato" element={<CadastroContato />} />
          <Route path="/CadastroEndereco" element={<CadastroEndereco />} />
          <Route path="/TodosAnuncios" element={<VerTodosAnuncios />} />

          <Route path="/MeusAnuncios" element={<MeusAnuncios />} />
          <Route element={<UsuarioLogado />}>
            <Route path="/CadastroAnuncio" element={<CadastroAnuncios />} />
            <Route path="/EdicaoPerfil" element={<EdicaoPerfil />} />
            <Route path="/PaginaAnuncio/:idAnuncio" element={<PaginaAnuncio />} />
          </Route>

          <Route path="/ComoUsar" element={<ComoUsar />} />
          <Route path='/SobreNos' element={<SobreNos />}/>
          <Route path='/codigodna' element={<CodigoDna />} />
          <Route path='/meuscupons' element={<ListaCupons />} />
          <Route path='/dna' element={<Dna />} />
          <Route path='/dnaDescritivo' element={<DnaDescritivo />} />
          <Route path='/atividades' element={<Atividades />} />

          <Route path="/PaginaAnuncio" element={<PaginaAnuncio />} />
          <Route path="/PlanoEmpresa" element={<PlanoEmpresa />} />
          <Route path="/PlanoUsuario" element={<PlanoUsuario />} />
          <Route path="/PlanoMaster" element={<PlanoMaster />} />
          <Route path="/PlanoVip" element={<PlanoVip />} />
          <Route path="/CompraEfetuada" element={<CompraEfetuada />} />

          <Route path="/CadastroUsuario" element={<CadastroUsuario />} />
          {/* <Route path="/CadastroContatoComum" element={<CadastroContatoComum />} /> */}
          <Route path="/PaginaAnuncio" element={<PaginaAnuncio />} />
          <Route path="/Form" element={<Cadastro />} />
          <Route path="/Endereco" element={<Endereco />} />

        </Routes>
      </div>
    </Router>
  )

ReactDOM.render(
  routing,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
