import React, { useEffect } from 'react'
import Header from '../../components/cabecalho/cabecalhoLogado.jsx'
import '../../assets/css/dna.css'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Footer from '../../components/rodape/rodape.jsx'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { Modal, Button, Image, Text, Link } from '@nextui-org/react'
import axios from 'axios'
import api from '../../services/api.js'
import moment from 'moment'
import 'moment/locale/pt-br'
import { parseJwt } from '../../services/auth'
import { useNavigate } from 'react-router-dom'
import { transparent } from 'material-ui/styles/colors'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

moment.locale('pt-br')

const columns = [
  { id: 'nomeComercial', label: 'Nome', minWidth: 170 },
  { id: 'dataAdesao', label: 'Data de adesão', minWidth: 100 },
  { id: 'email', label: 'E-mail', minWidth: 170 },
  { id: 'categoria', label: 'Categoria', minWidth: 170 }
]

function createData(idUsuario, nomeComercial, dataAdesao, email, vip, master) {
  //categoria = 0 -> usuário free
  //categoria = 1 -> usuário vip
  //categoria = 2 -> usuário master
  //categoria = 3 -> usuário vip e master

  dataAdesao = moment(dataAdesao).format('LL')

  let categoria
  if (vip === false) {
    if (master === false) {
      // categoria = 0
      categoria = 'Free'
    } else {
      // categoria = 2
      categoria = 'Master'
    }
  } else {
    if (master === true) {
      // categoria = 3
      categoria = 'Vip e Master'
    } else {
      // categoria = 1
      categoria = 'Vip'
    }
  }

  return { idUsuario, nomeComercial, dataAdesao, email, categoria }
}

export default function Dna() {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const [usuarios, setUsuarios] = useState([])
  const [usuarioLogado, setUsuarioLogado] = useState('')
  const [nomeComercial, setNomeComercial] = useState('')

  const closeHandler = () => {
    setVisible(false)
    console.log('closed')
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function listarUsuario() {
    setUsuarioLogado(parseJwt(localStorage.getItem('usuario-login')).jti)

    api.get('/usuarios/' + parseJwt(localStorage.getItem('usuario-login')).jti)
      .then(response => {
        if (response.status === 200) {
          // console.log(response.data.nomeComercial)
          setNomeComercial(response.data.nomeComercial)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  function ListarUsuariosDna() {
    api.get('/DnaUsuario/filhos/' + parseJwt().jti)
      .then(response => {
        if (response.status === 200) {
          //FALTA MOSTRAR NOME COMERCIAL

          console.log(response.data)
          console.log(response.data.length)
          response.data.map(item => {
            setUsuarios(prevArray => [
              ...prevArray,
              createData(
                item.id,
                item.nomeComercial,
                item.dataAdesao,
                item.email,
                item.vip,
                item.master
              )
            ])
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(ListarUsuariosDna, [])
  useEffect(listarUsuario, [])

  return (
    <div>
      <Header/>
      <main>
        <ToastContainer />
        <Modal
          className='modalQrcode'
          noPadding
          open={visible}
          onClose={closeHandler}
        >
          <Modal.Header className='headerQrcode'>
            <Text className='nomeProdutoQrcode'>{nomeComercial}</Text>
          </Modal.Header>
          <Modal.Body>
            <QRCodeSVG className='qr-code' value={usuarioLogado} />
          </Modal.Body>
        </Modal>

        <section className='bannerDna'>
          <div className='container'>
            <h1>Conheça o DNA!</h1>
            <p>
              Convide amigos e comerciantes, receba bônus até sua quinta geração
              e construa sua renda extra aqui mesmo!
            </p>
            <button>Ver regras</button>
          </div>
        </section>
        <section className='infoDna container'>
          <div className='boxInfoDna'>
            <div className='boxResumoBonus'>
              <div>
                <h2>Como ganhar seu bônus?</h2>
                <p>
                  Aqui você indica amigos e já começa a ganhar bônus!
                  Assim que alguém se cadastra no Quero Vantagens pelo
                  seu link ou QR Code, sendo Vip ou Master, já começa a fazer
                  parte do seu DNA e você recebe por isso!
                </p>
              </div>
              <div className='codigoUsuario'>
                <h2>Seu código de convite</h2>
                <div>
                  <p>{usuarioLogado}</p>
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(usuarioLogado);
                      toast.success('Copiado com sucesso!')
                    }}
                  >
                    Copiar
                  </button>
                  <button onClick={() => setVisible(true)}>QR Code</button>
                </div>
              </div>
            </div>
            <div className='boxWinMoney'>
              <div>
                <h2>Entre para uma família e aproveite as vantagens!</h2>
                <p>
                  Veja os bônus que você recebeu até o momento e o que falta
                  para alcançar todas as vantagens oferecidas pelo sistema DNA!{' '}
                </p>

                <button onClick={() => navigate('/codigodna')} className='button-icon-dna'>
                <span>Saiba mais</span>
                <span
                  className='iconify-inline'
                  data-icon='akar-icons:chevron-right'
                />
              </button>
              </div>

              </div>
          </div>
        </section>
        <section className='tabelaFilhos container'>
          <h2>Relação nominal de filhos</h2>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usuarios
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(usuario => {
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={usuario.idUsuario}
                        >
                          {columns.map(column => {
                            const value = usuario[column.id]
                            return (
                              <TableCell align={column.align}>
                                {value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={usuarios.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={<span>Linhas por página:</span>}
              labelDisplayedRows={({ page }) => {
                return `Página: ${page}`;
              }}
            />
          </Paper>
        </section>
      </main>
      <Footer />
    </div>
  )
}
