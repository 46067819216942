// imports aqui
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InputMask from 'react-input-mask';
import "../../../assets/css/cadastro.css"
import "../../../assets/css/checkbox.css"
import "../../../assets/css/switch.css"

import logo from "../../../assets/img/LogoCinza-Vetor.svg"
import comemoracao from "../../../assets/img/undraw_happy_announcement_re_tsm0.svg"
import api from "../../../services/api";

export default function CadastroContato() {

    const navigate = useNavigate();
    const location = useLocation();
    const [dadosCadastro, setDadosCadastro] = useState({});
    const [site, setSite] = useState("");
    const [eCommerce, setECommerce] = useState("");
    const [facebook, setFacebook] = useState("");
    const [youtube, setYoutube] = useState("");
    const [instagram, setInstagram] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [telegram, setTelegram] = useState("");
    const [telefone, setTelefone] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [opcaoTipo, setOpcaoTipo] = useState(false);


    // Uma das tentativas de salvar os dados no input caso queiram usar
    // Recomendado não usar essa branch de cadastro caso não queiram mudar como
    // estava antes, por que tem muitos testes que ainda n funcionam

    function saveStateToLocalStorage() {
        localStorage.setItem("userSite", site)
        localStorage.setItem("userECommerce", eCommerce)
        localStorage.setItem("userFacebook", facebook)
        localStorage.setItem("userYoutube", youtube)
        localStorage.setItem("userInstagram", instagram)
        localStorage.setItem("userWhatsapp", whatsapp)
        localStorage.setItem("userTelegram", telegram)
        localStorage.setItem("userTelefone", telefone)
    }

    function getStateFromLocalStorage() {
        let site = localStorage.getItem('userSite');
        if (site !== undefined) {
            setSite(site);
        }

        let eCommerce = localStorage.getItem('userECommerce');
        if (eCommerce !== undefined) {
            setECommerce(eCommerce);
        }

        let facebook = localStorage.getItem('userFacebook');
        if (facebook !== undefined) {
            setFacebook(facebook);
        }

        let youtube = localStorage.getItem('userYoutube');
        if (youtube !== undefined) {
            setYoutube(youtube);
        }

        let instagram = localStorage.getItem('userInstagram');
        if (instagram !== undefined) {
            setInstagram(instagram);
        }

        let whatsapp = localStorage.getItem('userWhatsapp');
        if (whatsapp !== undefined) {
            setWhatsapp(whatsapp);
        }

        let telegram = localStorage.getItem('userTelegram');
        if (telegram !== undefined) {
            setTelegram(telegram);
        }

        let telefone = localStorage.getItem('userTelefone');
        if (telefone !== undefined) {
            setTelefone(telefone);
        }
    }

    useEffect(() => {
        getStateFromLocalStorage();
    }, []);


    function CadastarContato(evento) {

        evento.preventDefault()

        var contato = ({
            novoContato: {
                facebook: facebook,
                telefone: telefone,
                ecommerce: eCommerce,
                website: site,
                whatsapp: whatsapp,
                telegram: telegram,
                youtube: youtube,
                instagram: instagram
            }
        })

        // var dadosCadastro = location.state.cadastro


        dadosCadastro["novoContato"] = contato.novoContato
        setDadosCadastro({ ...dadosCadastro })

        console.log(typeof (dadosCadastro.novoUsuario.idTipoUsuario))

        console.log(dadosCadastro.novoUsuario.master)

        if (dadosCadastro.novoUsuario.master == true) {

            // navigate('/cadastroEmpresa', { state: { cadastro: dadosCadastro } })

            api.post('/enderecos', dadosCadastro, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

                .then(response => {
                    console.log("OEE resposta");

                    if (response.status === 201) {
                        var myBody = JSON.stringify({
                            cnpjMeiCei: dadosCadastro.novaEmpresa.cnpjMeiCei,
                            senha: dadosCadastro.novoUsuario.senha
                        });

                        setTimeout(() => {
                            console.log("Mandou o post login");
                            api.post('/login', myBody, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(response => {
                                    if (response.status === 200) {
                                        localStorage.setItem('usuario-login', response.data.token)
                                        navigate('/planoMaster', { state: { cadastro: dadosCadastro } })
                                    }
                                })
                        }, 1000)
                    }

                })

            // .then(response => {
            //     if (response.status === 201) {
            //         var myBody = JSON.stringify({
            //             cnpjMeiCei: dadosCadastro.novaEmpresa.cnpjMeiCei,
            //             senha: dadosCadastro.novoUsuario.senha
            //         });



            //         api.post('/login', myBody, {
            //             headers: {
            //                 'Content-Type': 'application/json'
            //             }
            //         })
            //             .then(response => {
            //                 if (response.status === 200) {
            //                     localStorage.setItem('usuario-login', response.data.token)
            //                     navigate('/planoMaster', { state: { cadastro: dadosCadastro } })
            //                 }
            //             })

            //     }
            // })
            // .catch(erro => {
            //     console.log("ERROU AQUI ó!!");
            //     console.log(erro)
            // })


        } else {
            console.log("chegou nessa parte: Usuario normal");
            api.post('/enderecos', dadosCadastro, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log("OEE resposta");

                    if (response.status === 201) {
                        var myBody = JSON.stringify({
                            cpf: dadosCadastro.novoCliente.cpf,
                            senha: dadosCadastro.novoUsuario.senha
                        });

                        setTimeout(() => {
                            console.log("Mandou o post login");
                            api.post('/login/cliente', myBody, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(response => {
                                    if (response.status === 200) {
                                        localStorage.setItem('usuario-login', response.data.token)
                                        navigate('/planoUsuario', { state: { cadastro: dadosCadastro } })
                                    }
                                })
                        }, 1000)
                    }

                })


            // .then(response => {
            //     if (response.status === 201) {
            //         var myBody = JSON.stringify({
            //             cpf: dadosCadastro.novoCliente.cpf,
            //             senha: dadosCadastro.novoUsuario.senha
            //         });

            //         api.post('/login/cliente', myBody, {
            //             headers: {
            //                 'Content-Type': 'application/json'
            //             }
            //         })
            //             .then(response => {
            //                 if (response.status === 200) {
            //                     localStorage.setItem('usuario-login', response.data.token)
            //                     navigate('/planoUsuario', { state: { cadastro: dadosCadastro } })
            //                 }
            //             })

            //     }
            // })
            // .catch(erro => {
            //     console.log("ERROU AQUI");
            //     console.log(erro)
            // })

        }

    }

    useEffect(() => {
        setDadosCadastro(location.state?.cadastro)
        console.log(location.state?.cadastro)
    }, [])

    return (
        <div className="c-corpo">
            <header className="c-header container">
                <img src={logo} alt="logo do euquerovantagens" />
            </header>

            <main className="c-main container">
                <section className="c-cadastro">
                    <h1 className="c-cadastro__titulo">Crie sua conta de {dadosCadastro?.novoCliente !== undefined ? 'cliente' : 'empresa'}</h1>

                    <div className="c-progress">
                        <div className="c-progress__bar--completed c-progress__bar"></div>
                        <div className="c-progress__bar--completed c-progress__bar"></div>
                        <div className="c-progress__bar"></div>
                    </div>

                    <h2 className="c-cadastro__subtitulo">Contato</h2>

                    <form className="c-inputs--lessmargin c-inputs--center" action="" onSubmit={CadastarContato}>

                        {/* <input required className="c-inputs__text c-inputs__text--shorter" type="email" placeholder="Email" value={email} onChange={(campo) => setEmail(campo.target.value)} />  */}
                        {/* <input style={dadosCadastro.novoUsuario?.idTipoUsuario === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7' ? { display: 'block' } : { display: 'none' }} className="c-inputs__text c-inputs__text--shorter" type="url" placeholder="Site (URL)" value={site} onChange={(campo) => setSite(campo.target.value)} />
                        <input style={dadosCadastro.novoUsuario?.idTipoUsuario === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7' ? { display: 'block' } : { display: 'none' }} className="c-inputs__text c-inputs__text--shorter" type="url" placeholder="E-commerce (URL)" value={eCommerce} onChange={(campo) => setECommerce(campo.target.value)} />
                        <input className="c-inputs__text c-inputs__text--shorter" type="text" placeholder="Facebook" value={facebook} onChange={(campo) => setFacebook(campo.target.value)} />
                        <input style={dadosCadastro.novoUsuario?.idTipoUsuario === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7' ? { display: 'block' } : { display: 'none' }} className="c-inputs__text c-inputs__text--shorter" type="url" placeholder="Youtube (URL)" value={youtube} onChange={(campo) => setYoutube(campo.target.value)} /> */}
                        {/* <div style={dadosCadastro.novoUsuario?.idTipoUsuario === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7' ? { flexDirection: 'row' } : { flexDirection: 'column' }} className="c-inputs--divided"/>
                        <input style={dadosCadastro.novoUsuario?.idTipoUsuario === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7' ? { width: '48%' } : { width: '100%' }} className="c-inputs__text c-inputs__text--shorter c-inputs__text--half" type="text" placeholder="Instagram" value={instagram} onChange={(campo) => setInstagram(campo.target.value)} />
                        <input style={dadosCadastro.novoUsuario?.idTipoUsuario === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7' ? { width: '48%' } : { width: '100%' }} className="c-inputs__text c-inputs__text--shorter c-inputs__text--half" type="text" placeholder="Telegram" value={telegram} onChange={(campo) => setTelegram(campo.target.value)} /> */}

                        {/* <input required className="c-inputs__text c-inputs__text--shorter" type="email" placeholder="Email" value={email} onChange={(campo) => setEmail(campo.target.value)} /> */}

                        {/* <label style={dadosCadastro.novoUsuario?.idTipoUsuario === '57fd82ca-1327-4dc7-b66a-f4aacd1fff66' || dadosCadastro.novoUsuario?.idTipoUsuario === 'b8d18b05-f89a-4503-9eee-8db9d4ecaca4' ? { display: 'none' } : { display: 'block' }} className="new_label">Site</label>
                        <input className="c-inputs__text" type="url" placeholder="Informe a URL do seu site" value={site} onChange={(campo) => setSite(campo.target.value)} />
                        <label style={dadosCadastro.novoUsuario?.idTipoUsuario === '57fd82ca-1327-4dc7-b66a-f4aacd1fff66' || dadosCadastro.novoUsuario?.idTipoUsuario === 'b8d18b05-f89a-4503-9eee-8db9d4ecaca4' ? { display: 'none' } : { display: 'block' }} className="new_label">E-Commerce</label>
                        <input className="c-inputs__text" type="url" placeholder="Insira seu E-Commerce" value={eCommerce} onChange={(campo) => setECommerce(campo.target.value)} /> */}

                        <label style={dadosCadastro?.novoCliente !== undefined ? { display: 'none' } : { display: 'block' }} className="new_label">Site</label>
                        <input style={dadosCadastro?.novoCliente !== undefined ? { display: 'none' } : { display: 'block' }} className="c-inputs__text" type="url" placeholder="Informe a URL do seu site" value={site} onChange={(campo) => setSite(campo.target.value)} />
                        <label style={dadosCadastro?.novoCliente !== undefined ? { display: 'none' } : { display: 'block' }} className="new_label">E-Commerce</label>
                        <input style={dadosCadastro?.novoCliente !== undefined ? { display: 'none' } : { display: 'block' }} className="c-inputs__text" type="url" placeholder="Insira seu E-Commerce" value={eCommerce} onChange={(campo) => setECommerce(campo.target.value)} />

                        <label className="new_label">Facebook</label>
                        <input className="c-inputs__text" type="text" placeholder="https://www.facebook.com/sua_pagina" value={facebook} onChange={(campo) => setFacebook(campo.target.value)} />
                        <label className="new_label">Youtube</label>
                        <input className="c-inputs__text" type="url" placeholder="Insira a URL do seu canal" value={youtube} onChange={(campo) => setYoutube(campo.target.value)} />
                        <label className="new_label">Instagram</label>

                        <input className="c-inputs__text" type="text" placeholder="https://www.instagram.com/seu_perfil/" value={instagram} onChange={(campo) => setInstagram(campo.target.value)} />
                        <label className="new_label">Telegram</label>

                        <input className="c-inputs__text" type="text" placeholder="@nomedeusuario" value={telegram} onChange={(campo) => setTelegram(campo.target.value)} />

                        <div style={dadosCadastro.novoUsuario?.idTipoUsuario === '57fd82ca-1327-4dc7-b66a-f4aacd1fff66' || dadosCadastro.novoUsuario?.idTipoUsuario === 'b8d18b05-f89a-4503-9eee-8db9d4ecaca4' ? { flexDirection: 'column' } : { flexDirection: 'row' }} className="c-inputs--divided">

                        </div>
                        {/* <label className="new_label">WhatsApp</label> */}
                        {/* <input className="c-inputs__text c-inputs__text--shorter c-inputs__text--half" type="text" placeholder="Whatsapp" value={whatsapp} onChange={(campo) => setWhatsapp(campo.target.value)} /> */}
                        {/* <InputMask mask="(99) 99999-9999" className="c-inputs__text" type="text" placeholder="Whatsapp" value={whatsapp} onChange={(campo) => setWhatsapp(campo.target.value)} /> */}
                        {/* <input className="c-inputs__text c-inputs__text--shorter c-inputs__text--half" type="text" placeholder="Telefone" value={telefone} onChange={(campo) => setTelefone(campo.target.value)} /> */}

                        <label className="new_label">Telefone (obrigatório) </label>

                        <InputMask
                            required mask="(99) 99999-9999"
                            className="c-inputs__text"
                            type="text"
                            placeholder="Telefone"
                            value={telefone} onChange={(campo) => setTelefone(campo.target.value)}
                        />




                        <div className="c-buttons">
                            <button className="c-buttons__button c-buttons__button--voltar" type="button" onClick={() => navigate(-1)}>retornar</button>
                            {
                                isLoading === false ? (
                                    <button className="c-buttons__button c-buttons__button--proximo" type="submit">proximo</button>
                                ) : (
                                    <button className="c-buttons__button c-buttons__button--proximo" disabled type="submit">carregando..</button>
                                )
                            }

                        </div>

                    </form>
                </section>

                <section className="c-imagem">
                    <img src={comemoracao} alt="pessoas felizes comemorando" />
                </section>

            </main>

            <footer className="c-footerC container">
                <p className="c-footer__texto">@2022 EuQueroVantagens</p>
                <a className="c-footer__texto" href="#">Politica de privacidade</a>
            </footer>
        </div>
    )
}