import { logDOM } from '@testing-library/react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/planoempresa.css'
import Frame from '../../assets/img/Frame.png'
import lock from '../../assets/img/Vector.png'
import master from '../../assets/img/mdi_diamond.png'
import check from '../../assets/img/check.png'
import uncheck from '../../assets/img/uncheck.png'
import checktwo from '../../assets/img/checkWhite.png'

export default function PlanoEmpresa(){
  //SELEÇÃO DE PLANO PARA EMPRESAS
  
    return (
        <div className="containerPlano" >
        
        <div className="row">
        
        <div className="sideContainer">
          <h2>Anuncie para seus <br/> clientes agora.</h2>
          <div className="imageContainer">
          <img src={Frame}/>
          </div>
        </div>

        <div className="informationContainer">

        <div className="choiceSection">
          <h1 id="h1Choice">Escolha o melhor plano <br/> para o seu negócio</h1>
        </div>

        <div className="choiceContainer">
          
        <div className="freeSection">
          
          <div className="free">
            <img src={lock} alt="" />
            <span>Free</span>
          </div>

          <div className='informationFree'>

          <div className='containerNon'>
          <h3>Gratuito</h3>
          <p>Aproveite todas as promoções e não <br/> gaste nada com isso.</p>
          </div>

        <div className='containerAcess'>

         <span id='spanAcess'>Você terá acesso à:</span>
          
          <div id='checkSpace'>
          <img src={check} alt="" />
          <p>Bônus formando seu próprio conjunto de afiliados, sendo aplicável até sua quinta geração.</p>
          </div>
          
          <div id='uncheckSpace'>
          <img src={uncheck} alt="" />
          <p>Publicar anúncios para dar maior visibilidade aos seus produtos e serviços</p>
          </div>
          </div>
        </div>
          <button>Escolher</button>
        </div>



        <div className="masterSection">

        <div className="master">
            <img src={master} alt="" />
            <span>Master</span>
        </div>

        <div className='informationFree'>
         <div className='containerNon'>
          <h3 className="whiteMaster">R$ 29,99 / mês</h3>
          <p className="whiteMaster">Inicie sua experiência com o melhor <br/> plano com o melhor jeito de impulsionar <br/> seus negócios</p>
          </div>

          <div className='containerAcess'>
          
          <span className="whiteMaster">Você terá acesso à:</span>
          
          <div id='checkSpace'>
          <img src={checktwo} alt="" />
          <p className="whiteMaster">Bônus formando seu próprio conjunto de afiliados, sendo aplicável até sua quinta geração.</p>
          </div>

          <div id='uncheckSpace'>
          <img src={checktwo} alt="" />
          <p className="whiteMaster">Publicar anúncios para dar maior visibilidade aos seus produtos e serviços</p>
          </div>
          </div>
        </div>
        
        <Link to="/PlanoMaster">
          <button>Escolher</button>
        </Link>
        </div>
        </div>

        </div>

        </div>
        </div>
    );
  }
  