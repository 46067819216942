import { logDOM } from '@testing-library/react'
import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../../assets/css/planomaster.css'
import master from '../../assets/img/mdi_diamond.png'
import logo from '../../assets/img/LogoCinza-Vetor.svg'
import voltar from '../../assets/img/voltar.png'
import { LinkTwoTone, PaddingSharp } from '@mui/icons-material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import InputMask from 'react-input-mask'
import api from '../../services/api'
import { parseJwt } from '../../services/auth'

export default function PlanoMaster() {
  const location = useLocation()
  const [dadosCadastro, setDadosCadastro] = useState({})
  const [numCartao, setNumCartao] = useState('')
  const [modoPagamento, setModoPagamento] = useState('Crédito')
  const [nomeCartao, setNomeCartao] = useState('')
  const [dataExpiracao, setDataExpiracao] = useState('')
  const [nomeCompleto, setNomeCompleto] = useState('')
  const [linkBoleto, setLinkBoleto] = useState('')
  const [cvv, setCvv] = useState('')
  const [valorMaster] = useState(59.99)
  const [erroMensagem, setErroMensagem] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  function PagamentoCredito(event) {
    event.preventDefault()

    setIsLoading(true);

    var formData = new FormData();
    formData.append("NomeCartao", nomeCartao);
    formData.append("NumCartao", numCartao.replace(/\s/g, ''));
    formData.append("DataExpiracao", dataExpiracao);
    formData.append("CVV", cvv);
    formData.append("Plano", 2);


    api.post("/Pagamentos/Credito/" + parseJwt().jti, formData, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.status === 200) {
        setErroMensagem("")
        navigate('/codigodna')
        setIsLoading(false);

      }
    })
      .catch(erro => {
        console.log(erro)
        console.log(erro.response.data.message)
        setIsLoading(false);
        let msgErro = erro.response.data.message.split(" ")
        if (msgErro[0] === '344') {
          setErroMensagem("O número do cartão informado é inválido. Verifique o número digitado e tente novamente.")
        } else if (msgErro[0] === '095') {
          setErroMensagem("O cartão informado ja está vencido.")
        }
      })

  }

  function PagamentoBoleto(event) {
    event.preventDefault()

    setIsLoading(true);

    api.post("/Pagamentos/Boleto/" + parseJwt().jti, 2, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      let responseBoleto = response.data.split(" ; ")
      setLinkBoleto(response.data)
      setIsLoading(false);
      if (response.status === 200) {
        navigate('/compraefetuada', { state: { pacoteEscolhido: 'master', linkBoleto: responseBoleto[0], linhaBoleto: responseBoleto[1], valorMaster: valorMaster } })
      }
    }).catch(erro => {
      setIsLoading(false);
      console.log(erro)
    }
    )

  }

  useEffect(() => {
    setDadosCadastro(location.state?.cadastro)
    setNomeCompleto(location.state?.nomeCompleto)
  }, [])

  return (
    //COMPRA DO PLANO MASTER
    // toast.error('Escolha alguma imagem principal')

    <container className='bodyBox'>
      <ToastContainer />
      <div className='containerBox'>
        {/* <div className='backPageBlock'>
          <img className="voltar" src={voltar} alt='' />
          <span>Voltar</span>
        </div> */}

        <section className='sectionBox'>
          <div className='sectionLogo'>
            <img src={logo} alt='Logo' />
            <div className='logoGroup'>
              <h1>Anuncie hoje mesmo!</h1>
              <p>
                Com o plano Master você pode publicar anúncios e fazer sua marca crescer ainda mais!{' '}
              </p>
            </div>
          </div>

          <div>
            <div className='sectionPlan'>

              {/* <Box sx={{ minWidth: 320 }} > */}
              <Box className='inputSectionPlan' >
                <FormControl fullWidth  >
                  <InputLabel id='demo-simple-select-label' >
                    Modo de pagamento
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={modoPagamento}
                    label ='Age'
                    onChange={event => setModoPagamento(event.target.value)}
                    >
                    {/* <MenuItem value={'Débito'}>Débito</MenuItem> */}
                    <MenuItem value={'Crédito'}>Crédito</MenuItem>
                    <MenuItem value={'Boleto'}>Boleto</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div>
              <form
                className='boxForm'
                onSubmit={event => {
                  if (modoPagamento === 'Crédito') {
                    PagamentoCredito(event)
                  } else if (modoPagamento === 'Boleto') {
                    PagamentoBoleto(event)
                  }
                }}
                autocomplete={'on'}
              >
                <span className="modal-content__text " style={{ color: 'red', fontSize: '15px' }}>{erroMensagem}</span>
                <div className='formInputs' style={modoPagamento === 'Crédito' ? { display: 'flex' } : { display: 'none' }}>
                  {/* <label for="frmCCNum">Número do cartão</label> */}
                  <InputMask
                    value={numCartao}
                    mask='9999 9999 9999 9999'
                    name='cardnumber'
                    id='frmCCnum'
                    inputmode='numeric'
                    autoCompleteType='cc-number'
                    placeholder='Número do cartão'
                    style={
                      modoPagamento === 'Boleto'
                        ? { display: 'none' }
                        : { display: 'block' }
                    }
                    onChange={cartao => setNumCartao(cartao.target.value)}
                  />

                  {/* <label type="text" name="name" id="frmNameA">Nome do cartão</label> */}
                  <input
                    autocomplete='name'
                    value={nomeCartao}
                    onChange={cartao => setNomeCartao(cartao.target.value)}
                    placeholder='Nome no cartão'
                    style={
                      modoPagamento === 'Boleto'
                        ? { display: 'none' }
                        : { display: 'block' }
                    }
                  />

                  <div className='formSecundar'>
                    {/* <label for="frmPhoneNumA">Número do cartão</label> */}
                    <InputMask
                      mask='999'
                      id='frmCCnum'
                      inputmode='numeric'
                      autoCompleteType='cc-number'
                      placeholder='CVV'
                      value={cvv}
                      onChange={cvv => setCvv(cvv.target.value)}
                      style={
                        modoPagamento === 'Boleto'
                          ? { display: 'none' }
                          : { display: 'block' }
                      }
                    />
                    {/* <label for="frmPhoneNumA">Número do cartão</label> */}

                    {/* <label for="frmPhoneNumA">Número do cartão</label> */}
                    <InputMask
                      value={dataExpiracao}
                      mask='99/9999'
                      placeholder='MM / YYYY'
                      onChange={cartao => setDataExpiracao(cartao.target.value)}
                      style={
                        modoPagamento === 'Boleto'
                          ? { display: 'none' }
                          : { display: 'block' }
                      }
                    />
                  </div>
                  <div
                    style={
                      modoPagamento === 'Boleto'
                        ? { display: 'flex' }
                        : { display: 'none' }
                    }
                    className='formSecundar'
                  >

                  </div>
                </div>

                <div className='formValue'>
                  
                  <div className='boxValue'>
                    <span>Total:</span>
                    <h2>R$ {valorMaster}</h2>
                  </div>
                </div>

                { isLoading === false ? <button id='formButton' type='submit'>Realizar pagamento</button> : <button disabled className='isLoadingButton' type='submit'>Carregando...</button>}

              </form>

            </div>
          </div>
        </section>
      </div>

    </container>
  )
}
