import CabecalhoDeslogado from "../../components/cabecalho/cabecalhoDeslogado"
import '../../assets/css/telasInfo.css'
import Rodape from "../../components/rodape/rodape"

export default function SobreNos() {
    return (
        <div>
            <CabecalhoDeslogado />
            <main>
                <div className="container textoComoUsar">
                    <div>
                        <h1 className="h1_telasInfo">Sobre Nós</h1>
                        <h2>Quem Somos</h2>
                        <ol className="listaComoUsar">
                        <p>A Evolution é uma empresa inovadodra no Marketing digital, idealizada pelo Dr. Eurípedes Jacob que trabalhou no Banco do Brasil por 14 anos, no SENAI,  formado e atuando deste 1.989 na odontologia e por sua Esposa Karla Poleski que trabalhou GruPlan Engenharia,  na área de divulgação da Life Cosméticos, formada em TSB pela ABO e em odontologia pela Unigranrio, atuando a 14 anos nesta área. </p>
                        <li>VISÃO - Ser a melhor empresa de Marketing digital, oferendo oportunidade  de economia e renda a todas as pessoas e as empresas de quaisquer portes, renda e divulgações dos seus produtos e serviços, promovendo  a oportunidade econômica na ampliação de seus negócios, onde o capital e o trabalho possam gerar sustentabilidade para essa geração e futuras.</li>
                        <li>MISSÃO - Gerar oportunidade profissional, crescimento pessoal e financeiro a todos. Inovar o Marketing digital, democratizando as relações financeiras, independente de nível social. Oferecer às pessoas uma oportunidade de mudarem de vida e terem bem estar com qualidade de vida assegurada para si e para os seus, através de novas fontes de economia e renda.</li>
                        <li>VALORES - Ética, justiça social, democratização das oportunidades, inovação, transparência e criatividade, são os valores da nossa empresa, que estão expressas em nossa forma de fazer o melhor para todos e não para poucos.</li>
                        </ol>
                    </div>
                       
                </div>
            </main>
            <Rodape />
        </div>
    )
}