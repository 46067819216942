// imports aqui
// import "https://code.iconify.design/2/2.1.2/iconify.min.js"
import { useEffect, useState } from "react"
import Header from "../../components/cabecalho/header"
import "../../assets/css/paginaempresa.css"

import iconCategoria from "../../assets/img/saudeicon.svg"
import fotoPerfil from "../../assets/img/unsplash_1AhGNGKuhR0.png"
import CabecalhoDeslogado from "../../components/cabecalho/cabecalhoDeslogado"
import Rodape from "../../components/rodape/rodape"
import api, { url } from "../../services/api"
import { idEmpresa, parseJwt } from "../../services/auth"
import { useParams, Link } from "react-router-dom"

export default function PaginaAnuncio() {

    let { idAnuncio } = useParams();

    const [anuncio, setAnuncio] = useState([])
    const [listaHorario, setListaHorario] = useState([])
    const [listaEndereco, setListaEndereco] = useState([])
    const [listaContato, setListaContato] = useState([])
    const [diasPromo, setDiasPromo] = useState([])

    function buscarAnuncio() {
        api.get(`/anuncios/` + idAnuncio)
            .then(async response => {
                if (response.status === 200) {
                    setAnuncio(response.data)
                    console.log(response.data)
                    console.log(response.data.nomeProduto)
                    console.log(anuncio)
                    console.log(anuncio)
                    setDiasPromo(response.data.idDiasPromoNavigation)
                    setListaEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation)
                    setListaContato(response.data.idUsuarioNavigation.idContatoNavigation)
                }
            }).catch(erro => {
                console.log(erro)
            })

    }


    function buscarHorario() {
        api.get('/horarios/').then((resposta) => {
            if (resposta.status === 200) {
                let dados = resposta.json()
                dados.then(data => setListaHorario(data))
            }
        }).catch(erro => console.log(erro))

    }

    useEffect(buscarAnuncio, [])
    useEffect(buscarHorario, [])

    return (
        <div>
            <Header />
            <main className="c-main__perfilEmpresa">
                <section className="c-section container">
                    <div className="c-section__tituloImg">
                        <img src={`${url}/${anuncio.imgPrincipal}`} alt="Imagem do Anúncio" className="section__img" />
                        <div className="c-section__titulos">
                            <h1 className="section__titulo">{anuncio.nomeProduto}</h1>
                            {/* <h2>{anuncio.idUsuarioNavigation.empresas.filter(empresa => empresa.idUsuario === anuncio.idUsuario).nomeFantasia}</h2> */}
                            <div className="titulo__classificacao">
                                <span className="iconify" data-icon="ant-design:star-filled" color="#FF7701"></span>
                                <span className="iconify" data-icon="ant-design:star-filled" color="#FF7701"></span>
                                <span className="iconify" data-icon="ant-design:star-filled" color="#FF7701"></span>
                                <span className="iconify" data-icon="ant-design:star-filled" color="#FF7701"></span>
                                <span className="iconify" data-icon="ant-design:star-filled" color="#FF7701"></span>
                                <span>5,0</span>
                            </div>

                            {/* <span className="c-titulos__horario">Clínica Fechada - Abre 08:00</span> */}
                        </div>
                    </div>

                    <div className="section__box">
                        <div className="section__divDesc">
                            <h3 className="section__subtitle">Funcionamento da promoção:</h3>
                            <table className="table__funcionamento">
                                <tbody>
                                    {
                                        diasPromo.segunda === true ?
                                            <tr>
                                                <td className="subtitle__semana">S</td>
                                                <td>Segunda-feira:</td>
                                                <td>{new Date(listaHorario.horaAbreSegunda).toString()}</td>
                                                <td>{listaHorario.horaFechaSegunda}</td>
                                            </tr> : ''
                                    }
                                    {
                                        diasPromo.terca === true ?
                                            <tr>
                                                <td className="subtitle__semana">T</td>
                                                <td>Terça-feira:</td>
                                                <td>{listaHorario.horaAbreTerca}</td>
                                                <td>{listaHorario.horaFechaTerca}</td>
                                            </tr>
                                            : ''
                                    }
                                    {
                                        diasPromo.quarta === true ?
                                            <tr>
                                                <td className="subtitle__semana">Q</td>
                                                <td>Quarta-Feira:</td>
                                                <td>{listaHorario.horaAbreQuarta}</td>
                                                <td>{listaHorario.horaFechaQuarta}</td>
                                            </tr> : ''
                                    } {
                                        diasPromo.quinta === true ?
                                            <tr>
                                                <td className="subtitle__semana">Q</td>
                                                <td>Quinta-Feira:</td>
                                                <td>{listaHorario.horaAbreQuinta}</td>
                                                <td>{listaHorario.horaFechaQuinta}</td>
                                            </tr> : ''
                                    }
                                    {
                                        diasPromo.sexta === true ?
                                            <tr>
                                                <td className="subtitle__semana">S</td>
                                                <td>Sexta-feira:</td>
                                                <td>{listaHorario.horaAbreSexta}</td>
                                                <td>{listaHorario.horaFechaSexta}</td>
                                            </tr> : ''
                                    }
                                    {
                                        diasPromo.sabado === true ?
                                            <tr>
                                                <td className="subtitle__semana">S</td>
                                                <td>Sábado:</td>
                                                <td>{listaHorario.horaAbreSabado}</td>
                                                <td>{listaHorario.horaFechaSabado}</td>
                                            </tr> : ''
                                    }
                                    {
                                        diasPromo.domingo === true ?
                                            <tr>
                                                <td className="subtitle__semana">D</td>
                                                <td>Domingo:</td>
                                                <td>{listaHorario.horaAbreDomingo}</td>
                                                <td>{listaHorario.horaFechaDomingo}</td>
                                            </tr> : ''
                                    }
                                    {
                                        diasPromo.feriados === true ?
                                            <tr>
                                                <td className="subtitle__semana">F</td>
                                                <td>Feriados:</td>
                                                <td>{listaHorario.horaAbreFeriado}</td>
                                                <td>{listaHorario.horaFechaFeriado}</td>
                                            </tr> : ''
                                    }
                                </tbody>
                            </table>

                            <div className="section__divSubtitle">
                                <h3 className="section__subtitle">Endereço:</h3>
                                <ul>
                                    {
                                        console.log(listaEndereco)
                                    }
                                    <li>{listaEndereco.endereco1}, {listaEndereco.numEndereco}</li>
                                    <li>{listaEndereco.bairro}</li>
                                    <li>{listaEndereco.cidade}, {listaEndereco.estado}</li>
                                    <li>{listaEndereco.complemento}</li>
                                    <li>CEP: {listaEndereco.cep}</li>
                                </ul>
                            </div>

                            <div className="section__divSubtitle">
                                <h3 className="section__subtitle">Contato:</h3>
                                <div className="section__contato">
                                        <ol>
                                            <li>Site: </li>
                                            <li>{listaContato.website}</li>
                                            <li>E-commerce: {listaContato.ecommerce}</li>
                                            <li>Telefone: {listaContato.telefone}</li>
                                            <li>Whatsapp: {listaContato.whatsapp}</li>
                                            <li>Instagram: {listaContato.instagram}</li>
                                            <li>Facebook: {listaContato.facebook}</li>
                                            <li>Youtube: {listaContato.youtube}</li>
                                        </ol>
                                    {/* <label className="contato__label">
                                        <a href={listaContato.website}><button><span className="iconify" data-icon="ant-design:global-outlined"></span></button></a>
                                        Site
                                    </label> */}
                                    {/* <label className="contato__label">
                                        <a href={listaContato.ecommerce}><button><span className="iconify" data-icon="fa:shopping-cart"></span></button></a>
                                        E-Commerce
                                    </label> */}
                                    {/* <label className="contato__label"> */}
                                    {/* <button style={{ cursor: 'pointer' }} value={listaContato.telefone} onClick={() => { navigator.clipboard.writeText(listaContato.telefone); alert('Texto copiado!') }}><span className="iconify" data-icon="ant-design:global-outlined"></span></button>     */}
                                    {/* Telefone */}
                                    {/* </label> */}
                                    {/* <label className="contato__label">
                                        <button><span className="iconify" data-icon="akar-icons:whatsapp-fill"></span></button>
                                        Whatsapp
                                    </label>
                                    <label className="contato__label">
                                        <a href={listaContato.website}><button><span className="iconify" data-icon="ant-design:global-outlined"></span></button></a>
                                        Instagram
                                    </label>
                                    <label className="contato__label">
                                        <a href={listaContato.facebook}></a><button><span className="iconify" data-icon="akar-icons:facebook-fill"></span></button>
                                        Facebook
                                    </label>
                                    <label className="contato__label">
                                        <a href={listaContato.youtube}><button><span className="iconify" data-icon="ant-design:global-outlined"></span></button></a>
                                        Youtube
                                    </label> */}
                                </div>
                            </div>
                        </div>

                        <div className="section__divDesc">
                            <div className="section__descricao">
                                <h3 className="section__subtitle">Descrição:</h3>
                                <p>{anuncio.descricao}
                                </p>
                            </div>

                            {/* <div className="section__divSubtitle">
                                <h3 className="section__subtitle">Categoria:</h3>
                                <label className="categoria__label">
                                    <img src={iconCategoria} alt="icone_categoria" className="icon__categoria" />
                                    Saúde
                                </label>
                            </div> */}

                            {/* <div className="section__divSubtitle">
                                <h3 className="section__subtitle">Outras informações:</h3>
                                <span>CNPJ: {anuncio.cnpjMeiCei}</span>
                            </div> */}
                        </div>
                    </div>

                    <div className="actions">
                        <button type="submit" className="section__btnReport"><span className="iconify"
                            data-icon="ic:sharp-report-gmailerrorred"></span>Informar ou reportar</button>
                        <button>Resgatar cupom</button>
                    </div>
                </section>
            </main>
            <Rodape />
        </div>
    )
}