import logoEQV from "../../assets/img/LogoEuQueroVantagens.png"
import baixarAppStore from "../../assets/img/baixarnaappstore.png"
import baixarGooglePlay from "../../assets/img/googlePlay.png"

import "../../assets/css/footer.css"

export default function Rodape(){
    return(
        <footer className="footer">
            <div className="c-footer container" style={{margin: '15px'}}>
                <ul>
                    {/* <li ><a href="#">Sobre nós</a></li>
                    <li><a href="#">Fale conosco</a></li>
                    <li><a href="#">A empresa</a></li>
                    <li><a href="#">Políticas de privacidade</a></li>
                    <li><a href="#">Como Usar</a></li> */}
                </ul>
                <div className="c-footer__logo">
                    <img src={logoEQV} alt="logo da empresa Eu Quero Vantagens"/>
                    <span>© Copyright - 2022 - EuQuero Vantagens - Todos os direitos reservados</span>
                </div>

                <div className="c-footer__baixarapp">
                    {/* <span>Baixe o app:</span>
                    <img src={baixarAppStore} alt="Botão para baixar o aplicativo na app store" />
                    <img src={baixarGooglePlay} alt="Botão para baixar o aplicativo na google play" /> */}
                </div>
            </div>

        </footer>
    )
}