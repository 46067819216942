// imports aqui
import { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import "../../assets/css/switch.css"
import "../../assets/css/checkbox.css"
import "../../assets/css/cadastro.css"

import logo from "../../assets/img/LogoCinza-Vetor.svg"
import comemoracao from "../../assets/img/undraw_happy_announcement_re_tsm0.svg"
import { Alert } from "bootstrap";
import { toast, ToastContainer } from 'react-toastify'
import { styled } from "@mui/styles";


export default function CadastroInicio() {

    //escolher cadastro comum ou empresa

    const navigate = useNavigate();

    const [opcaoTipo, setOpcaoTipo] = useState(false);

    const [checkServico, setCheckServico] = useState(false);
    const [checkPrivacidade, setCheckPrivacidade] = useState(false);
    const [checkNormas, setCheckNormas] = useState(false);
    const [check, setCheck] = useState(false);


    function verificarCheckbox() {
        if (checkServico && checkPrivacidade && checkNormas) {
            setCheck(true);
           
        } else {
            //console.log(check);
            setCheck(false);
        }
    }
    
    useEffect(() => {
        verificarCheckbox()
    },[checkServico, checkPrivacidade, checkNormas]);


    function mudarDescricao() {

        var option = document.getElementById("switchOpt");
        var text = document.getElementById("textOpt");

        if (option.checked == true) {
            text.textContent = "Com esta conta você consegue usufruir dos benefícios de promover sua empresa, seus anuncios, serviços e ganhar renda extra através do sistema de distribuiçao natural de afiliados (cadastro com CNPJ,MEI ou CEI)";
            setOpcaoTipo(true);
        } else {
            text.textContent = "Com esta conta você consegue usufruir de todos os benefícios, desde obter renda extra e descontos a anunciar serviços e anuncios(cadastro somente com CPF e opções variam de acordo com plano selecionado)"
            setOpcaoTipo(false);
        }
    }


    function redirecionarTipo() {
        if (opcaoTipo === true) {
            // navigate("/CadastroUsuario", { state: { tipo: opcaoTipo } });
            navigate("/CadastroEmpresa");
        } else {
            // navigate("/CadastroEmpresa", { state: { tipo: opcaoTipo } });
            navigate("/CadastroUsuario");
        }
    }

    return (
        <div className="c-corpo">
            <header className="c-header container">
                <img src={logo} alt="Logo do EuQueroVantagens" />
            </header>

            <main className="c-main container">
                <ToastContainer />

               {/* {
                    setTimeout(() => { toast.warning('Todos os termos devem ser aceitos para prosseguir!') }, 2000)
                }  */}

                <section className="c-cadastro">
                    <h1 className="c-cadastro__titulo">Cadastre-se</h1>

                    <div className="c-switch">
                        <p className="c-switch__label--complement">cliente</p>
                        <label className="switch">
                            <input id="switchOpt" type="checkbox" onChange={() => mudarDescricao()} />
                            <span className="slider round"></span>
                        </label>
                        <p className="c-switch__label--complement">empresa</p>
                    </div>
                    <span className="c-cadastro__descricao" id="textOpt">
                        Com esta conta você consegue usufruir de todos os benefícios, desde obter descontos a anunciar serviços e anuncios(cadastro somente com CPF)
                    </span>

                    <form className="c-inputs" onSubmit={() => redirecionarTipo()}>

                        <label htmlFor="servico" className="c-inputs__check" >
                            <input id="servico" required type="checkbox" onChange={() => setCheckServico(!checkServico)}  checked={checkServico} />
                            <span className="checkmark"></span>
                            Concordo com os <a title="termos de serviço" href="/">termos de serviço</a>
                        </label>


                        <label htmlFor="privacidade" className="c-inputs__check">
                            <input id="privacidade" required type="checkbox" onChange={() => setCheckPrivacidade(!checkPrivacidade)} checked={checkPrivacidade} />
                            <span className="checkmark"></span>
                            Concordo com a <a title="politica de privacidade" href="/">politica de privacidade</a>
                        </label>

                        <label htmlFor="normas" className="c-inputs__check">
                            <input id="normas" required type="checkbox" onChange={() => setCheckNormas(!checkNormas)} checked={checkNormas} />
                            <span className="checkmark"></span>
                            Concordo com o <a title="politica de privacidade" href="/">manual de normas</a>
                        </label>

                        <div className="c-login">
                            <p>Já tem uma conta?</p>
                            <a title="ir a tela principal para fazer login" href="/">Faça login</a>
                        </div>

                        <div className="c-buttons">
                            <button className="c-buttons__button c-buttons__button--voltar" type="button" onClick={() => navigate(-1)}>retornar</button>
                           
                            {
                                check ? <button className="c-buttons__button c-buttons__button--proximo" type="submit">começar</button>  
                                : <button className="c-buttons--disabled" type="submit" onClick={()=>  toast.warning('Todos os termos devem ser aceitos para prosseguir!')}>começar</button> 
                            }
                            {/* <button className="c-buttons__button c-buttons__button--proximo" type="submit">começar</button> */}
                        </div>

                    </form>

                </section>

                <section className="c-imagem">
                    <img src={comemoracao} alt="pessoas felizes comemorando" />
                </section>

            </main>

            <footer className="c-footerC container">
                <p className="c-footer__texto">@2022 EuQueroVantagens</p>
                <a title="politica de privacidade" className="c-footer__texto" href="#">Politica de privacidade</a>
            </footer>
        </div>
    )
}