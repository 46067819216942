// imports aqui
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import InputMask from 'react-input-mask'

import '../../../assets/css/cadastro.css'
import '../../../assets/css/checkbox.css'
import '../../../assets/css/switch.css'
import Lottie from 'react-lottie'
import animationData from '../../../assets/lotties/lf30_editor_mk8uvfc4.json'
import logo from '../../../assets/img/LogoCinza-Vetor.svg'
// import comemoracao from '../../../assets/img/undraw_happy_announcement_re_tsm0.svg'
import api from '../../../services/api'
import { parseJwt } from '../../../services/auth'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Modal, Button, Text } from '@nextui-org/react'
import { QrReader } from 'react-qr-reader'
import { PauseCircleOutline } from '@mui/icons-material'

export default function CodigoDna() {
  const [idPai, setIdPai] = useState('Sem resultado')
  const [resultado, setResultado] = useState(false)
  const [modalScan, setModalScan] = useState(false)

  const navigate = useNavigate();
  const [pacoteEscolhidoMaster, setPacoteEscolhido] = useState(false);
  
  const closeModalScan = () => {
    setModalScan(false)
    setIdPai('Sem resultado')
    setResultado(false)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin'
    }
  }

  function CadastrarDna(event) {
    event.preventDefault()


    let dnaUsuario = {
      idPai: idPai,
      idUsuario: parseJwt().jti
    }

    api.post('/dnausuario', dnaUsuario, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('usuario-login')
      }
    }).then(response => {
      if (response.status === 201) {
        setModalScan(false)
        setResultado(false)
        toast.success('Parabéns! Agora você tem um DNA!')

        setTimeout(function () {
          if (pacoteEscolhidoMaster) {
            navigate('/MeusAnuncios')
          } else{
            navigate('/')
          }
        }, 2300)
      } else if (response.status === 400) {
        setModalScan(false)
        setResultado(false)
        toast.error('Código inválido ou inexistente. Tente novamente!')
      }
    }).catch(teste => {
      console.log(teste)
      setModalScan(false)
      setResultado(false)
      toast.error('Código inválido ou inexistente. Tente novamente!')
      if (teste === 422) {
        console.log(teste.status)
        setModalScan(false)
        setResultado(false)
        toast.warning('O usuário atual já está em um DNA')
      } else if (teste === 400) {
        console.log(teste)
        setModalScan(false)
        setResultado(false)
        toast.error('Código inválido ou inexistente. Tente novamente!')
      }
    })
  }

  function verificarTipo() {

    let  idUsuario= parseJwt().jti;

      api.get('Usuarios/' + idUsuario)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data.master)

          if (response.data.master) {
            setPacoteEscolhido(true);
            console.log(pacoteEscolhidoMaster);
          } else{
            setPacoteEscolhido(false);
            console.log(pacoteEscolhidoMaster);
          }

          }
      })
      .catch(erro => {
        console.log(erro)
      })
      
  }


  useEffect( () => {
    console.log("oi")
    verificarTipo()
  }, []
  );


  return (
    <div className='c-corpo'>
      <header className='c-header container'>
        <img src={logo} alt='logo do euquerovantagens' />
        <ToastContainer />
      </header>

      <main className='c-main container containerDna'>
        <div className='boxDna'>
          <Button auto color='back' onClick={() => navigate(-1)} style={{ width: "100%", display: "flex", justifyContent: "flex-start", backgroundColor: "transparent", marginTop: "2%", color: "#808080" }}>
            <span
              className='iconify-inline'
              data-icon='akar-icons:chevron-left'
            />
            voltar
          </Button>
          <Lottie options={defaultOptions} height='60%' />
          <Modal
            closeButton
            blur
            aria-labelledby='modal-title'
            open={modalScan}
            onClose={() => closeModalScan()}
          >

            <Modal.Header>
              <Text id='modal-title' size={18}>
                <Text b size={18}>
                  Aponte sua câmera para o QR Code de convite e confirme para
                  prosseguir
                </Text>
                <Text>{idPai}</Text>
              </Text>
            </Modal.Header>
            <Modal.Body>
              <QrReader
                onResult={(result, error) => {
                  if (!!result) {
                    setResultado(true)
                    setIdPai(result?.text)
                    console.log('foi')
                    console.log(result?.text)
                  }

                  if (!!error) {
                    console.log(result)
                    console.log(error)
                  }
                }}
                style={{ width: '100%', height: '600px' }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button auto color='error' onClick={() => closeModalScan()}>
                Cancelar
              </Button>
              {resultado ? (
                <Button
                  auto
                  style={{ cursor: 'pointer' }}
                  onClick={x => CadastrarDna(x)}
                >
                  Concluído
                </Button>
              ) : (
                <Button auto style={{ cursor: 'no-drop' }}>
                  Concluído
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          <form
            onSubmit={x => CadastrarDna(x)}
            className='form-codigoDna'
            action=''
          >
            <label htmlFor=''>
              Insira um código de convite e entre em um DNA
            </label>
            <input
              type='text'
              onChange={x => setIdPai(x.target.value)}
              placeholder='Digite um código'
            />
            <button type='submit'>Validar</button>
            <a
              className='linkPularDna'
              onClick={() => setModalScan(true)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              ou escaneie o QrCode
            </a>
            {
              pacoteEscolhidoMaster ? 
              <Link className='linkPularDna' to={'/MeusAnuncios'}>
              Pular
            </Link>
              : 
              <Link className='linkPularDna' to={'/'}>
              Pular
            </Link>
            }
            
          </form>
        </div>
      </main>                

      <footer className='c-footerC container'>
        <p className='c-footer__texto'>@2022 EuQueroVantagens</p>
        <a className='c-footer__texto' href='#'>
          Politica de privacidade
        </a>
      </footer>
    </div>
  )
}
