import "../../assets/css/global.css"
import "../../assets/css/header.css"
import Logo from "../../assets/img/LogoEuQueroVantagens.png"
import { Link } from "react-router-dom"
import imgAnunciante from "../../assets/img/blank-profile-picture.svg"
import { useEffect, useState } from "react"
import { url } from "../../services/api"
import { parseJwt } from "../../services/auth"
import { useNavigate } from "react-router-dom"
import api from "../../services/api"

export default function CabecalhoLogado(usuario) {
    const [listaAnuncios, setListaAnuncios] = useState([])
    const [tituloAnuncio, setTituloAnuncio] = useState('')
    const [filteredResults, setFilteredResults] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [foto, setFoto] = useState('');
    const [nome, setNome] = useState('');
    const [nomeComercial, setNomeComercial] = useState('');
    const [empresa, setEmpresa] = useState(false);
    const navigate = useNavigate();


    //Função para buscar anúncios
    function listarAnuncios() {
        api.get('/anuncios').then(response => {
            if (response.status === 200) {
                setListaAnuncios(response.data)
                //console.log(response.data)
            }
        }).catch(erro => {
            console.log(erro)
        })
    }

    // useEffect(listarAnuncios, [])

    // Função da barra de pesquisa
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = listaAnuncios.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(listaAnuncios)
        }
    }

    function atualizarDadosUsuario(usuario) {
        console.log(empresa);

        var user = parseJwt(localStorage.getItem('usuario-login')).jti
        // var myURL = "http://localhost:5000/api/Usuarios/" + user;
        var myURL = "https://195.35.42.38:5001/api/Usuarios/" + user;

        var myBody;

        fetch(myURL, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: myBody
        })
            .then((resposta) => {
                // console.log("chegou antes do if");
                if (resposta.status === 200) {
                    //console.log(resposta.json());
                    //console.log(usuario.tipo);
                    // console.log("chegou dps");

                    //console.log(usuario.tipo)
                    // if (usuario.tipo !== "Empresa") {
                    //     setEmpresa(false);
                    // }

                    return resposta.json()
                        .then((data) => {
                            console.log(url + data.caminhoImagem);
                            console.log(data)
                            // console.log(data);
                            setFoto(url + data.caminhoImagem)
                            setNomeComercial(data.nomeComercial)
                            setNome(data.nome)

                            if (data.master) {
                                setEmpresa(true);
                            }

                            console.log(empresa);

                        })
                        .catch((error) => console.error(error))
                }
            })
    }

    function MostrarMenu() {
        var menu = document.getElementById("menu")

        if (window.innerWidth <= 910) {
            if (menu.style.display === "flex") {
                console.log("aí")
                menu.style.display = "none"

            } else {
                menu.style.display = "flex"
            }
        }
    }

    useEffect(() => {
        console.log(empresa);
        atualizarDadosUsuario();
        listarAnuncios();
        function handleResize() {
            var menu = document.getElementById("menu")
            menu.removeAttribute("style")
            console.log("mudando")
        }
        window.addEventListener('resize', handleResize)
    }, [])

    return (
        <div>
            <header>
                <div className="navegacaoPrincipal">
                    <div id="menu" className="navegacaoPrincipal__menu">
                        <div className="container">
                            <div className="navegacaoPrincipal__barraPesquisa">
                                <input
                                    type="search"
                                    className="navegacaoPrincipal__inputPesquisa"
                                    onChange={(e) => searchItems(e.target.value)}
                                    placeholder="Encontre a promoção certa para você"
                                />
                                <button className="navegacaoPrincipal__btnPesquisa">
                                    <span className="iconify-inline" data-icon="fa-solid:search"></span>
                                </button>

                                {
                                    searchInput.length > 0 ?
                                        filteredResults.map((anuncio) => {
                                            return (
                                                <section key={anuncio.idAnuncio}>
                                                    <p>{anuncio.nomeProduto}</p>
                                                </section>
                                            )
                                        }) :
                                        ''
                                }
                            </div>
                            {
                                empresa ?
                                    <button onClick={() => navigate("/meusAnuncios")} style={{ cursor: 'pointer', backgroundColor: 'transparent' }} className="navegacaoPrincipal__links">
                                        <span className="iconify-inline" data-icon="material-symbols:sell-sharp"></span>
                                        <p>meus anúncios</p>
                                    </button>
                                    :
                                    <button onClick={() => navigate("/meusCupons")} style={{ cursor: 'pointer', backgroundColor: 'transparent' }} className="navegacaoPrincipal__links">
                                        <span className="iconify-inline" data-icon="whh:coupon"></span>
                                        <p>meus cupons</p>
                                    </button>
                            }
                            <button onClick={() => navigate("/dna")} style={{ cursor: 'pointer', backgroundColor: 'transparent' }} className="navegacaoPrincipal__links navegacaoPrincipal__links--width">
                            <span className="iconify-inline" data-icon="la:dna"></span>
                            <p >dna</p>
                        </button>
                        </div>
                    </div>
                    <div className="container">
                        <button onClick={() => MostrarMenu()} id="btnMenu" className="navegacaoPrincipal__btnMenu">
                            <span className="iconify-inline" data-icon="ant-design:menu-outlined"></span>
                        </button>
                        <Link to='/'><img src={Logo} alt="Logo do EuQueroVantagens" /></Link>
                        <div className="navegacaoPrincipal__barraPesquisa">

                            <input
                                type="search"
                                className="navegacaoPrincipal__inputPesquisa"
                                onChange={(e) => searchItems(e.target.value)}
                                placeholder="Encontre a promoção certa para você"
                            />
                            <button className="navegacaoPrincipal__btnPesquisa">
                                <span className="iconify-inline" data-icon="fa-solid:search"></span>
                            </button>

                            <section className='navegacaoPrincipal__listaPesquisa'>
                                {
                                    searchInput.length > 0 ?
                                        filteredResults.map((anuncio) => {
                                            return (
                                                <section key={anuncio.idAnuncio} className='navegacaoPrincipal__resultadoPesquisa'>
                                                    <button onClick={() => navigate('/TodosAnuncios', {
                                                        state: { paramKey: anuncio.nomeProduto }
                                                    })}><p className="">{anuncio.nomeProduto}</p> </button>
                                                </section>
                                            )
                                        }) :
                                        ''
                                }
                            </section>
                        </div>
                        {
                            empresa ?
                                <button onClick={() => navigate("/meusAnuncios")} style={{ cursor: 'pointer', backgroundColor: 'transparent' }} className="navegacaoPrincipal__links">
                                    <span className="iconify-inline" data-icon="material-symbols:sell-sharp"></span>
                                    <p>meus anúncios</p>
                                </button>
                                :
                                <button onClick={() => navigate("/meusCupons")} style={{ cursor: 'pointer', backgroundColor: 'transparent' }} className="navegacaoPrincipal__links">
                                    <span className="iconify-inline" data-icon="whh:coupon"></span>
                                    <p>meus cupons</p>
                                </button>
                        }
                        <button onClick={() => navigate("/dna")} style={{ cursor: 'pointer', backgroundColor: 'transparent' }} className="navegacaoPrincipal__links navegacaoPrincipal__links--width">
                            <span className="iconify-inline" data-icon="la:dna"></span>
                            <p >dna</p>
                            {/* <button style={{ cursor: 'pointer', backgroundColor: 'transparent' }} onClick={() => navigate("/dna")}>dna</button> */}
                        </button>
                        <Link to='/edicaoPerfil'>
                            <div className="navegacaoPrincipal__perfil">
                                <img src={foto} alt="" />
                                {/* {
                                    foto === "" ? (
                                        <img src={imgAnunciante} alt="foto padrão do usuario" />
                                    ) : (
                                        <img src={foto} alt="foto do usuario" />
                                    )
                                } */}

                                <div>
                                    {/* so vai aparecer o primeiro nome */}
                                    {/* <p>{usuario.name.split(" ")[0] }</p>  */}
                                    <p>{nome}</p>
                                    <p>{nomeComercial}</p>
                                    <p>{usuario.tipo}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="navegacaoSecundaria">
                    <nav className="container">
                        <a href="#" className="navegacaoSecundaria__links">regulamento</a>
                        <Link to={"/SobreNos"} className="navegacaoSecundaria__links">sobre nós</Link>
                        <a href="#" className="navegacaoSecundaria__links">fale conosco</a>
                        <a href="/ComoUsar" className="navegacaoSecundaria__links">como usar</a>
                        <a href="#" className="navegacaoSecundaria__links">política de privacidade</a>
                        <a href="/#secaoPlanos" className="navegacaoSecundaria__links navegacaoSecundaria__links--weight">descubra nossos planos
                            de vantagens
                            {/* <span className="iconify-inline" data-icon="akar-icons:circle-chevron-right-fill"></span> */}
                        </a>
                    </nav>
                </div>
            </header>
        </div>
    )
}