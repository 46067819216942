import { logDOM } from '@testing-library/react'
import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import '../../../assets/css/planoUsuario.css'
import lock from '../../../assets/img/Vector.png'
import master from '../../../assets/img/DiamonOrange.png'
import crown from '../../../assets/img/CrownOrange.png'
import check from '../../../assets/img/check.png'
import uncheck from '../../../assets/img/uncheck.png'
import api from '../../../services/api'

export default function PlanoUsuario() {
  const navigate = useNavigate()
  const location = useLocation()
  const [dadosCadastro, setDadosCadastro] = useState({})

  function DefineTipoUsuario(id) {

    var tipoUsuario = id;
    dadosCadastro.novoUsuario['idTipoUsuario'] = id

    if (id === '57fd82ca-1327-4dc7-b66a-f4aacd1fff66') {
      dadosCadastro.novoUsuario['vip'] = true
      navigate('/planoVip', { state: { cadastro: tipoUsuario } })
    }
    else if (id === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7') {
      dadosCadastro.novoUsuario['master'] = true
      navigate('/planoMaster', { state: { cadastro: tipoUsuario } })
    }
    else if (id === "b8d18b05-f89a-4503-9eee-8db9d4ecaca4") {
      navigate('/codigoDna')
    }
  }

  useEffect(() => {
    setDadosCadastro(location.state?.cadastro)
  }, [])

  return (
    //SELEÇÃO DE PLANO PARA USUÁRIOS COMUNS

    <section className='SectionPlan'>
      <div className='divPlan'>
        <h1>Melhore a sua experiência</h1>
        <p>
          Adquira uma das nossas assinaturas e turbine suas promoções,
          aqui você paga para gastar menos!{' '}
        </p>
      </div>

      <container className='sectionCards'>
        <div className='freeCard'>
          <div className='freeLock'>
            <img src={lock} alt='' />
            <span>Free</span>
          </div>

          <div className='informationFreeU'>
            <div className='containerNone'>
              <h3>Gratuito</h3>
              <p>
                Aproveite todas as promoções e não <br /> gaste nada com isso.
              </p>
            </div>

            <hr className='hrId' />

            <div className='ucontainerAcess'>
              <span id='uspanAcess'>Você terá acesso à:</span>
              <div id='ucheckSpace'>
                <img src={check} alt='' />
                <p>
                  Bônus formando seu próprio conjunto de afiliados, sendo
                  aplicável até sua quinta geração.
                </p>
              </div>

              <div id='uuncheckSpace'>
                <img src={uncheck} alt='' />
                <p>
                  Descontos totais em todos os anúncios na lista de
                  classificados.
                </p>
              </div>
            </div>
          </div>
          {/* Free */}
          <button
            onClick={() =>
              DefineTipoUsuario('b8d18b05-f89a-4503-9eee-8db9d4ecaca4')
            }
          >
            Escolher
          </button>
        </div>

        <div className='freeCard'>
          <div className='VipLock'>
            <img src={crown} alt='' />
            <span>VIP</span>
          </div>

          <div className='informationFreeU'>
            <div className='containerNone'>
              <div className='divNone'>
                <h3>R$ 29,99</h3>
                <span>/mês</span>
              </div>
              <p>
                Aproveite todas as promoções com o <br /> dobro de desconto.
              </p>
            </div>

            <hr className='hrId' />

            <div className='ucontainerAcess'>
              <span id='uspanAcess'>Você terá acesso à:</span>
              <div id='ucheckSpace'>
                <img src={check} alt='' />
                <p>
                  Bônus formando seu próprio conjunto de afiliados, sendo
                  aplicável até sua quinta geração.
                </p>
              </div>

              <div id='ucheckSpace'>
                <img src={check} alt='' />
                <p>
                  Descontos totais em todos os anúncios na lista de
                  classificados.
                </p>
              </div>
            </div>
          </div>
          {/* VIP */}
          <button
            onClick={() =>
              DefineTipoUsuario('57fd82ca-1327-4dc7-b66a-f4aacd1fff66')
            }
          >
            Escolher
          </button>
        </div>

        <div className='freeCard'>
          <div className='freeLock'>
            <img id='masterimg' src={master} alt='' />
            <span>MASTER</span>
          </div>

          <div className='informationFreeU'>
            <div className='containerNone'>
              <div className='divNone'>
                <h3>R$ 29,99</h3>
                <span>/mês</span>
              </div>
              <p>
                Inicie sua experiência com o melhor jeito de
                impulsionar seus negócios.
              </p>
            </div>

            <hr className='hrId' />

            <div className='ucontainerAcess'>
              <span id='uspanAcess'>Você terá acesso à:</span>
              <div id='ucheckSpace'>
                <img src={check} alt='' />
                <p>
                  Bônus formando seu próprio conjunto de afiliados, sendo
                  aplicável até sua quinta geração.
                </p>
              </div>

              <div id='ucheckSpace'>
                <img src={check} alt='' />
                <p>
                  Publicar anúncios para dar maior visibilidade aos seus
                  produtos e serviços
                </p>
              </div>
            </div>
          </div>
          {/* Master */}
          <button
            onClick={() =>
              DefineTipoUsuario('d0593a14-a9be-42b3-aaf8-3223a137dbe7')
            }
          >
            Escolher
          </button>
        </div>
      </container>
    </section>
  )
}