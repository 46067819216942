import React from 'react';
import { useState, useEffect } from 'react'
import Header from "../../components/cabecalho/header"

import "../../assets/css/verTodos.css"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import api, { url } from "../../services/api"
import { parseJwt } from "../../services/auth"
// import {Handler} from "../../services/handler.js"

import { Modal, Text } from "@nextui-org/react";
import { QRCodeSVG } from 'qrcode.react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Search } from '@mui/icons-material';

export default function VerTodosAnuncios({ paramKey }) {
    const [anunciosBeleza, setAnunciosBeleza] = useState([]);
    const [anunciosCultura, setAnunciosCultura] = useState([]);
    const [anunciosEletro, setAnunciosEletro] = useState([]);
    const [anunciosEsportes, setAnunciosEsportes] = useState([]);
    const [anunciosComida, setAnunciosComida] = useState([]);
    const [anunciosMercado, setAnunciosMercado] = useState([]);
    const [anunciosSaude, setAnunciosSaude] = useState([]);
    const [anunciosTurismo, setAnunciosTurismo] = useState([]);
    const [anunciosServicos, setAnunciosServicos] = useState([]);
    const [anunciosVeiculo, setAnunciosVeiculo] = useState([]);
    const [empresaLogada, setEmpresaLogada] = useState(true)
    const [listaAnuncios, setListaAnuncios] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [cupom, setCupom] = useState('')
    const [pesquisa, setPesquisa] = useState("");
    const [filteredResults, setFilteredResults] = useState([])
    const [searchInput, setSearchInput] = useState('');

    // let {tituloAnuncio} = useParams();
    const location = useLocation()

    const [visible, setVisible] = useState(false);
    const handler = () => setVisible(true);
    const closeHandler = () => {
        setVisible(false);
        console.log("closed");
    };
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    var myHeader = { 'Content-Type': 'application/json' }

    function buscarTodosAnuncios() {
        api.get('/anuncios/', {
            headers: myHeader
        }).then((resposta) => {
            if (resposta.status === 200) {
                console.log(resposta.data);
                setListaAnuncios(resposta.data)
            }
        }).catch((erro) => console.log(erro));
    }

    function buscarAnuncio() {
        api.get('/categoriaanuncio').then((resposta) => {
            if (resposta.status === 200) {
                console.log(resposta)

                let beleza = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Beleza'
                })

                let eletronicos = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Eletrônicos'
                })

                let cultura = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Cultura'
                })

                let esportes = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Esportes'
                })
                let comida = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Comida'
                })
                let mercados = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Mercado'
                })
                let saude = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Saude'
                })
                let turismo = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Turismo'
                })
                let servicos = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Serviços'
                })
                let veiculos = resposta.data.filter((anuncio) => {
                    return anuncio.categorium.tituloCategoria === 'Veículos'
                })

                setAnunciosBeleza(beleza)
                setAnunciosEletro(eletronicos)
                setAnunciosCultura(cultura)
                setAnunciosEsportes(esportes)
                setAnunciosComida(comida)
                setAnunciosMercado(mercados)
                setAnunciosSaude(saude)
                setAnunciosTurismo(turismo)
                setAnunciosServicos(servicos)
                setAnunciosVeiculo(veiculos)
            }
        })
            .catch((erro) => console.log(erro))
    }

    // Função da barra de pesquisa
    const searchItems = (paramKey) => {
        console.log(listaAnuncios)
        console.log(paramKey)
        setSearchInput(paramKey)
        if (searchInput !== '') {
            const filteredData = listaAnuncios.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            console.log(filteredData)
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(listaAnuncios)
        }
    }

    function buscarCategorias() {
        api.get('/categorias').then((resposta) => {
            if (resposta.status === 200) {
                setCategorias(resposta.data)
            }
        })

    }

    function Handler(idAnuncio, nomeProduto, nomeComercial) {
        setVisible(true)

        var cliente = parseJwt(localStorage.getItem('usuario-login')).jti

        api.get('/clientes/buscar/' + cliente).then(response => {
            if (response.status === 200) {
                //console.log(response.data.idCliente)
                setEmpresaLogada(false)

                const novoCupom = {
                    "idAnuncio": idAnuncio,
                    "idCliente": response.data.idCliente,
                    "dataResgate": (new Date()).toJSON(),
                    "codigo": "123",
                    "situacao": "1"
                }

                api.post('/cupons', novoCupom).then(response => {
                    if (response.status === 201) {
                        console.log('foi aqui moço')
                        console.log(response.data.idCupom)
                        setCupom(response.data.idCupom)
                    }
                })

            }
        }).catch(erro => {
            console.log(erro)
        })

        api.get('/empresas/buscar/' + cliente).then(response => {
            if (response.status === 200) {
                //setEmpresaLogada(true);
                var input = document.getElementById("resgatar");
                input.classList.add('c-input--invalid');
                toast.error("Você está cadastrado(a) como uma empresa, portanto não pode resgatar cupons  :(");
                //alert(`Você está cadastrado(a) como uma empresa, portanto não pode resgatar cupons :(`);

            }
        }).catch(erro => {
            console.log(erro)
        })

    }

    useEffect(buscarAnuncio, [])
    useEffect(buscarCategorias, [])
    useEffect(buscarTodosAnuncios, [])
    useEffect(() => searchItems, [])
    // useEffect(() => {
    //     searchItems(location.state?.paramKey)
    // }, [location])
    // useEffect(() => {
    //     setPesquisa(location.state?.paramKey)
    // }, [])

    return (
        <>
            <Header {...paramKey}></Header>
            <div className="verTodos__btnVoltar">
                <Link to='/'>
                    <span className="iconify-inline" data-icon="akar-icons:chevron-left"></span>
                    <span>Voltar</span>
                </Link>
            </div>
            <section className='verTodos__conteudo '>
                <ToastContainer />

                {
                    searchInput.length > 0 ?
                        filteredResults.map((anuncio) => {
                            return (
                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                    <div className="secaoAnuncios__anuncio" >
                                        <div className="secaoAnuncios__titulo">
                                            {
                                                empresaLogada ? <p></p> :
                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                        <Modal.Header
                                                            className="headerQrcode"
                                                        >
                                                            <Text className="nomeProdutoQrcode">
                                                                {anuncio.nomeProduto}
                                                            </Text>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                        </Modal.Body>
                                                    </Modal>

                                            }
                                            <img src={`${url}/${anuncio.imgPrincipal}`} alt="Photo by David B Townsend on Unsplash" />
                                            <div>
                                                <p>{anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                <p>{anuncio.nomeProduto}</p>
                                            </div>
                                        </div>
                                        <div className="secaoAnuncios__diasPromocao">
                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                            <div>
                                                <div className={anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                <div className={anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                <div className={anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                <div className={anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                <div className={anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                <div className={anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                <div className={anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                <div className={anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                            </div>
                                        </div>
                                        <div className="secaoAnuncios__valor">
                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                            <div className="secaoAnuncios__descontos">
                                                <div className="secaoAnuncios__tag">
                                                    <p>{`R$ ${anuncio.valor}`}</p>
                                                    <div>visitante</div>
                                                </div>
                                                <div className="separator"></div>
                                                <div className="secaoAnuncios__tag">
                                                    <p>{`R$ ${(anuncio.valor - (anuncio.valor * anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                    <div>vip 20%</div>
                                                </div>
                                                <div className="separator"></div>
                                                <div className="secaoAnuncios__tag">
                                                    <p>{`R$ ${(anuncio.valor - (anuncio.valor * (anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                    <div>free 10%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <button id="resgatar" onClick={() => Handler(
                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.idUsuarioNavigation.nomeComercial)}
                                            className="secaoAnuncios__btnResgatar">
                                            resgatar
                                        </button>
                                    </div>
                                </Link>
                            )
                        })

                        :


                        <div className='verTodos__secoes container'>
                            <section>
                                <h1 className='verTodos__titulo'>Beleza</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosBeleza.map((anuncio) => {
                                            return (
                                                <Link className='secaoAnuncios__link' to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio">
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Cultura</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosCultura.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Eletrônicos</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosEletro.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Esportes</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosEsportes.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>

                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Comida</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosComida.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Mercados</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosMercado.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Saúde</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosSaude.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Turismo</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosTurismo.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Serviços</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosServicos.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                            <section>
                                <h1 className='verTodos__titulo'>Veículos</h1>
                                <div className='secaoAnuncios__container'>
                                    {
                                        anunciosVeiculo.map((anuncio) => {
                                            return (
                                                <Link to={"/PaginaAnuncio/" + anuncio.idAnuncio} key={anuncio.idAnuncio}>
                                                    <div className="secaoAnuncios__anuncio" >
                                                        <div className="secaoAnuncios__titulo">
                                                            {
                                                                empresaLogada ? <p></p> :
                                                                    <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                                                                        <Modal.Header
                                                                            className="headerQrcode"
                                                                        >
                                                                            <Text className="nomeProdutoQrcode">
                                                                                {anuncio.nomeProduto}
                                                                            </Text>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <QRCodeSVG className="qr-code" value={cupom} />
                                                                        </Modal.Body>
                                                                    </Modal>

                                                            }
                                                            <img src={`${url}/${anuncio.anuncio.imgPrincipal}`} al alt="Photo by David B Townsend on Unsplash" />
                                                            <div>
                                                                <p>{anuncio.anuncio.idUsuarioNavigation.nomeComercial}</p>
                                                                <p>{anuncio.anuncio.nomeProduto}</p>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__diasPromocao">
                                                            <p className="secaoAnuncios__txtCampo">Dias da promoção:</p>
                                                            <div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.segunda === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.terca === true ? "diasPromo" : "diasSemPromo"}>t</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quarta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.quinta === true ? "diasPromo" : "diasSemPromo"}>q</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sexta === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.sabado === true ? "diasPromo" : "diasSemPromo"}>s</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.domingo === true ? "diasPromo" : "diasSemPromo"}>d</div>
                                                                <div className={anuncio.anuncio.idDiasPromoNavigation.feriados === true ? "diasPromo" : "diasSemPromo"}>f</div>
                                                            </div>
                                                        </div>
                                                        <div className="secaoAnuncios__valor">
                                                            <p className="secaoAnuncios__txtCampo">Valor:</p>
                                                            <div className="secaoAnuncios__descontos">
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${anuncio.anuncio.valor}`}</p>
                                                                    <div>visitante</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * anuncio.anuncio.descontoTotal)).toFixed(2)}`}</p>
                                                                    <div>vip 20%</div>
                                                                </div>
                                                                <div className="separator"></div>
                                                                <div className="secaoAnuncios__tag">
                                                                    <p>{`R$ ${(anuncio.anuncio.valor - (anuncio.anuncio.valor * (anuncio.anuncio.descontoTotal / 2))).toFixed(2)}`}</p>
                                                                    <div>free 10%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="resgatar" onClick={() => Handler(
                                                            anuncio.idAnuncio, anuncio.nomeProduto, anuncio.anuncio.idUsuarioNavigation.nomeComercial)}
                                                            className="secaoAnuncios__btnResgatar">
                                                            resgatar
                                                        </button>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                        </div>
                }
            </section>
        </>
    )
}