//import React, { useEffect } from "react";
import React from "react";
//import { parseJwt } from "../../services/auth.js";
import Header from "../../components/cabecalho/cabecalhoLogado.jsx";
import Footer from "../../components/rodape/rodape.jsx"
import ImgVip from "../../assets/img/Frame.png"
//import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import '../../assets/css/listaCupons.css';
import '../../assets/css/atividade.css';

//import api, { url } from "../../services/api.js";
//import { Navigate } from "react-router-dom";

moment.locale('pt-br')


export default function Atividades() {
    //const [descontoAtual, setDescontoAtual] = useState(0)
    //const [descontoVip, setrDescontoVip] = useState(0)
    //const [descontoMaster, setDescontMaster] = useState(0)

    const navigate = useNavigate();


    function ListarDescontos() {
        // api.get("/Cupons/Meus/" + parseJwt().jti)
        //     .then(response => {
        //         if (response.status === 200) {
        //             console.log(response.data)
        //             setListaResgates(response.data.filter(cupom => cupom.situacao === "1"))
        //             setListaValidados(response.data.filter(cupom => cupom.situacao === "2"))
        //             setListaExpirados(response.data.filter(cupom => cupom.situacao === "3"))
        //         }
        //     }).catch(error => {
        //         console.log(error)
        //     })
    }


    //useEffect(ListarDescontos, [])

    return (
        <div>
            <Header />
            <main>
                <section className="banner-vip">
                    <div className="container-banner-vip">
                        <div className="txtBanner">
                            <h1>Veja o quanto você já economizou com seus cupons!</h1>
                            <button style={{cursor:"pointer"}} onClick={()=> navigate("/meuscupons")}>Ver meus cupons</button>
                        </div>
                        <img src={ImgVip} alt="" />
                    </div>
                </section>
                <section className="listaCupons container">
                    <h2> Minhas atividades</h2>
                    <div className="box-atividade">
                        <p className="text-min">Até agora você já economizou:</p>
                        <p className="text-max">R$ 56,38</p>
                    </div>
                    <div className="box-atividade">
                        <p className="text-min">Com o plano<p className="word">  VIP  </p>  você economizaria:</p>
                        <p className="text-med">R$ 97,45</p>
                    </div>
                    <div className="box-atividade">
                        <p className="text-min">E ainda melhor, sendo <p className="word"> MASTER </p> você teria economizado:</p>
                        <p className="text-med">R$ 142,20</p>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

