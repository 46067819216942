import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import { List, ListItem } from 'material-ui/List';
import RaisedButton from 'material-ui/RaisedButton';
import api from '../../../services/api';

export class FormPersonalDetails extends Component {

  // Um protótipo de fazer uma tela de confirmação antes de cadastrar
  // provavelmente ainda n está funcionando

  continue = e => {
    e.preventDefault();
    // process form //

    function cadastrarEmpresa(item) {
      item.preventDefault();
      this.setState({ isLoading: true });

      let empresa = {
        CEP: this.props.CEP,
        endereco: this.props.endereco,
        complemento: this.props.complemento,
        numero: this.props.numero,
        bairro: this.props.bairro,
        cidade: this.props.cidade,
        UF: this.props.UF,
        site: this.props.site,
        ecommerce: this.props.ecommerce,
        facebook: this.props.facebook,
        youtube: this.props.youtube,
        instagram: this.props.instagram,
        whatsapp: this.props.whatsapp,
        telegram: this.props.telegram,
        telefone: this.props.telefone,
        celular: this.props.celular,
        email: this.props.email,
        razaosocial: this.props.razaosocial,
        nomefantasia: this.props.nomefantasia,
        cnpj: this.props.cnpj,
        senha: this.props.senha,
      };

      api.post('/empresas', JSON.stringify(empresa), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.status === 201) {
          console.log('Evento Cadastrado eba :D');
          this.setState({ isLoading: false });
        }
      }).catch((error) => {
        console.log("deu ruim")
        console.log(error);
        this.setState({ isLoading: false });
      });

      // fetch('http://191.101.234.36:5000/api/Empresas', empresa, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: empresa
      // }).then((r) => {
      //   if (r.status === 201) {
      //     console.log('Evento Cadastrado eba :D');
      //     this.setState({ isLoading: false });
      //   }
      // }).catch((error) => {
      //   console.log("deu ruim")
      //   console.log(error);
      //   this.setState({ isLoading: false });
      // });
    };
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();

  }

  render() {
    const { values: { CEP, endereco, complemento, numero, bairro, cidade, UF, site, ecommerce, facebook, youtube, instagram, whatsapp, telegram, telefone, celular, email, razaosocial, nomefantasia, cnpj, senha } } = this.props;
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <AppBar title="Confirm User Data" />

          <List>
            <ListItem
              primaryText="CEP"
              secondaryText={CEP}
            />
            <ListItem
              primaryText="endereco"
              secondaryText={endereco}
            />
            <ListItem
              primaryText="complemento"
              secondaryText={complemento}
            />
            <ListItem
              primaryText=" numero"
              secondaryText={numero}
            />
            <ListItem
              primaryText=" bairro"
              secondaryText={bairro}
            />

            <ListItem
              primaryText="cidade"
              secondaryText={cidade}
            />

            <ListItem
              primaryText="UF"
              secondaryText={UF}
            />

            <ListItem
              primaryText="site"
              secondaryText={site}
            />

            <ListItem
              primaryText="ecommerce"
              secondaryText={ecommerce}
            />

            <ListItem
              primaryText="facebook"
              secondaryText={facebook}
            />

            <ListItem
              primaryText="youtube"
              secondaryText={youtube}
            />

            <ListItem
              primaryText="instagram"
              secondaryText={instagram}
            />

            <ListItem
              primaryText="whatsapp"
              secondaryText={whatsapp}
            />

            <ListItem
              primaryText="telegram"
              secondaryText={telegram}
            />
            <ListItem
              primaryText="telefone"
              secondaryText={telefone}
            />
            <ListItem
              primaryText="celular"
              secondaryText={celular}
            />
            <ListItem
              primaryText="razaosocial"
              secondaryText={razaosocial}
            />
            <ListItem
              primaryText="nomefantasia"
              secondaryText={nomefantasia}
            />
            <ListItem
              primaryText="cnpj"
              secondaryText={cnpj}
            />
          </List>
          <br />
          <RaisedButton
            label="Confirm and Continue"
            primary={true}
            style={styles.button}
            onClick={this.continue}
          />

          <RaisedButton
            label="Back"
            primary={false}
            style={styles.button}
            onClick={this.back}
          />
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}

const styles = {
  button: {
    margin: 15
  }
}

export default FormPersonalDetails