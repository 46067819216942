import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { parseJwt } from "../../services/auth.js";
import Header from "../../components/cabecalho/cabecalhoLogado.jsx";
import Footer from "../../components/rodape/rodape.jsx"
import ImgVip from "../../assets/img/img-vip.svg"
import ImgCupom from "../../assets/img/img-cupom.png"
import { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Modal, Text } from "@nextui-org/react";
import { QRCodeSVG } from 'qrcode.react';
import '../../assets/css/listaCupons.css';

import api, { url } from "../../services/api.js";

moment.locale('pt-br')

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
    root: {
        '& .css-19kzrtu': {
            padding: "20px 0 0 0"
        }
    }
});

export default function ListaCupons() {
    const [listaResgates, setListaResgates] = useState([])
    const [listaValidados, setListaValidados] = useState([])
    const [listaExpirados, setListaExpirados] = useState([])
    const [nomeProduto, setNomeProduto] = useState('')
    const [codigo, setCodigo] = useState('')
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const handler = () => setVisible(true);
    const closeHandler = () => {
        setVisible(false);
        console.log("closed");
    };
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    function ListarCupons() {
        api.get("/Cupons/Meus/" + parseJwt().jti)
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                    setListaResgates(response.data.filter(cupom => cupom.situacao === "1"))
                    setListaValidados(response.data.filter(cupom => cupom.situacao === "2"))
                    setListaExpirados(response.data.filter(cupom => cupom.situacao === "3"))
                }
            }).catch(error => {
                console.log(error)
            })
    }

    function Handler(codigoCupom, produto) {
        setNomeProduto(produto)
        setCodigo(codigoCupom)
        setVisible(true)
        console.log(produto)
    }

    useEffect(ListarCupons, [])

    return (
        <div>
            <Header />
            <main>
                <Modal className="modalQrcode" noPadding open={visible} onClose={closeHandler}>
                    <Modal.Header
                        className="headerQrcode"
                    >
                        <Text className="nomeProdutoQrcode">
                            {nomeProduto}
                        </Text>
                    </Modal.Header>
                    <Modal.Body>
                        <QRCodeSVG className="qr-code" value={codigo} />
                        {/* <Image
                            showSkeleton
                            src="https://images.unsplash.com/photo-1459411552884-841db9b3cc2a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                            width={400}
                            height={490}
                        /> */}
                    </Modal.Body>
                </Modal>
                <section className="banner-vip">
                    <div className="container-banner-vip">
                        <div className="txtBanner">
                            <h1>Se torne VIP e obtenha descontos completos em todos os anúncios</h1>
                            <button>tornar-se vip</button>
                        </div>
                        <img src={ImgVip} alt="" />
                    </div>
                </section>
                <section className="listaCupons container">
                    <h2>Meus cupons</h2>
                    <Box sx={{ width: '100%', padding: '0px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs variant="scrollable" textColor="#F57200" TabIndicatorProps={{ style: { background: '#F57200' } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Resgatados" {...a11yProps(0)} />
                                <Tab label="Validados" {...a11yProps(1)} />
                                <Tab label="Vencidos" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel className={classes.root} value={value} index={0}>
                            <div className="cupons-conteudo">
                                {
                                    listaResgates.map((cupom) => {
                                        return (
                                            <article className="cupom">
                                                <div>
                                                    <img src={`${url}/${cupom.idAnuncioNavigation.imgPrincipal}`} alt="" />
                                                    <p>{cupom.idAnuncioNavigation.nomeFantasia}</p>
                                                </div>
                                                <h3>{cupom.idAnuncioNavigation.nomeProduto}</h3>
                                                <div>
                                                    <p>código</p>
                                                    <p>{cupom.codigo}</p>
                                                </div>
                                                <div>
                                                    <p>até {moment(cupom.idAnuncioNavigation.dataFim).format('DD/MM')}</p>
                                                    <button onClick={() => Handler(cupom.codigo, cupom.idAnuncioNavigation.nomeProduto)}>QR Code</button>
                                                </div>
                                            </article>
                                        )
                                    })
                                }

                            </div>
                        </TabPanel>
                        <TabPanel className={classes.root} value={value} index={1}>
                            <div className="cupons-conteudo">
                                {
                                    listaValidados.map((cupom) => {
                                        return (
                                            <article className="cupom">
                                                <div>
                                                    <img src={`${url}/${cupom.idAnuncioNavigation.imgPrincipal}`} alt="" />
                                                    <p>{cupom.idAnuncioNavigation.nomeFantasia}</p>
                                                </div>
                                                <h3>{cupom.idAnuncioNavigation.nomeProduto}</h3>
                                                <div>
                                                    <p>código</p>
                                                    <p>{cupom.codigo}</p>
                                                </div>
                                                <div>
                                                    <p>até {moment(cupom.idAnuncioNavigation.dataFim).format('DD/MM')}</p>
                                                </div>
                                            </article>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel className={classes.root} value={value} index={2}>
                            <div className="cupons-conteudo" >
                                {
                                    listaExpirados.map((cupom) => {
                                        return (
                                            <article className="cupom">
                                                <div>
                                                    <img src={`${url}/${cupom.idAnuncioNavigation.imgPrincipal}`} alt="" />
                                                    <p>{cupom.idAnuncioNavigation.nomeFantasia}</p>
                                                </div>
                                                <h3>{cupom.idAnuncioNavigation.nomeProduto}</h3>
                                                <div>
                                                    <p>código</p>
                                                    <p>{cupom.codigo}</p>
                                                </div>
                                                <div>
                                                    <p>até {moment(cupom.idAnuncioNavigation.dataFim).format('DD/MM')}</p>
                                                </div>
                                            </article>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                    </Box>
                    <div style={{ marginTop: "3%", cursor: "pointer" }}>
                        <span style={{ color: "#542494", fontWeight: "500", textDecorationLine: "underline" }} onClick={() => navigate("/atividades")}>Ver minhas atividades</span>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

