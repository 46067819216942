import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import InputMask from 'react-input-mask';
import moment from "moment";
import 'moment/locale/pt-br';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import "../../assets/css/edicaoperfil.css"

// import "https://code.iconify.design/2/2.1.2/iconify.min.js"

import "../../assets/css/edicaoperfil.css"

import Rodape from "../../components/rodape/rodape";
import Header from "../../components/cabecalho/header";

import { parseJwt } from "../../services/auth";
import api, { url } from "../../services/api";

import planoMaster from "../../assets/img/PlanoMaster.png"
import PlanoVip from "../../assets/img/PlanoVip.png"
import planoFree from "../../assets/img/PlanoFree.png"

export default function EdicaoPerfil() {
    const [idEmpresa, setIdEmpresa] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [idContato, setIdContato] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeComercial, setnomeComercial] = useState('');
    const [cpf, setCpf] = useState('');
    const [cnpjMeiCei, setCnpjMeiCei] = useState('');

    const [email, setEmail] = useState('');
    const [antigaSenha, setAntigaSenha] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [cep, setCep] = useState('');
    const [idEndereco, setIdEndereco] = useState(0);
    const [endereco1, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [numEndereco, setNumEndereco] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [estado, setEstado] = useState('');

    const [telefone, setTelefone] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youtube, setYoutube] = useState('');
    const [dataAdesao, setDataAdesao] = useState('');

    const [fotoPerfil, setFotoPerfil] = useState('');
    const [empresa, setEmpresa] = useState(false);
    const [free, setFree] = useState(true);

    const [idAlterado, setIdAlterado] = useState(0);
    const [senhaAlterada, setSenhaAlterada] = useState(false);
    const [senhasConfirmam, setSenhasConfirmam] = useState(false);

    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");


    const idUsuarioLogado = parseJwt(localStorage.getItem('usuario-login')).jti

    const navigate = useNavigate();

    function BotaoMenu() {
        var menu = document.getElementById("links");
        if (window.innerWidth <= 768) {
            if (menu.style.display === "none") {
                menu.style.display = "flex";
                document.documentElement.style.overflow = "hidden";
            } else {
                menu.style.display = "none";
                document.documentElement.style.overflow = 'auto';
            }
        }
    }

    function abrirModal() {
        var modal = document.getElementById("myModal");

        modal.style.display = "block";

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        }
    }

    function atualizarDadosUsuario() {
        console.log(empresa);

        var user = parseJwt(localStorage.getItem('usuario-login')).jti
        // var myURL = "http://localhost:5000/api/Usuarios/" + user;
        var myURL = "https://195.35.42.38:5001/api/Usuarios/" + user;

        var myBody;

        fetch(myURL, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: myBody
        })
            .then((resposta) => {
                if (resposta.status === 200) {

                    //console.log(resposta.json())
                    //if (usuario.tipo !== "Empresa") {
                    //    setEmpresa(false);
                    //}

                    return resposta.json()
                        .then((data) => {
                            //console.log(data.master);
                            setEmpresa(data.master);
                            //console.log(empresa);
                        })
                        .catch((error) => console.error(error))
                       
                    }
                })
                console.log(empresa); 
    }


    function buscarUsuario() {

        atualizarDadosUsuario();

        //não está trazendo role
        var usuario = parseJwt().jti

        console.log(empresa);
        //if (parseJwt().role === 'd0593a14-a9be-42b3-aaf8-3223a137dbe7'.toLocaleLowerCase()) {
        if (empresa) {
            api.get('empresas/buscar/' + usuario).then(response => {
                if (response.status === 200) {
                    //console.log(response.data)
                    //setEmpresa(true)
                    setFree(false)
                    setIdEmpresa(response.data.idEmpresa)
                    setRazaoSocial(response.data.razaoSocial)
                    setnomeComercial(response.data.idUsuarioNavigation.nomeComercial)
                    setEmail(response.data.idUsuarioNavigation.email)
                    setIdContato(response.data.idUsuarioNavigation.idContatoNavigation.idContato)
                    setTelefone(response.data.idUsuarioNavigation.idContatoNavigation.telefone)
                    setIdEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation.idEndereco)
                    setCep(response.data.idUsuarioNavigation.idEnderecoNavigation.cep)
                    setEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation.endereco1)
                    setComplemento(response.data.idUsuarioNavigation.idEnderecoNavigation.complemento)
                    setNumEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation.numEndereco)
                    setCidade(response.data.idUsuarioNavigation.idEnderecoNavigation.cidade)
                    setBairro(response.data.idUsuarioNavigation.idEnderecoNavigation.bairro)
                    setEstado(response.data.idUsuarioNavigation.idEnderecoNavigation.estado)
                    setLat(response.data.idUsuarioNavigation.idEnderecoNavigation.latitude)
                    setLng(response.data.idUsuarioNavigation.idEnderecoNavigation.longitude)
                    setCnpjMeiCei(response.data.cnpjMeiCei)
                    setWebsite(response.data.idUsuarioNavigation.idContatoNavigation.website)
                    setFacebook(response.data.idUsuarioNavigation.idContatoNavigation.facebook)
                    setInstagram(response.data.idUsuarioNavigation.idContatoNavigation.instagram)
                    setYoutube(response.data.idUsuarioNavigation.idContatoNavigation.youtube)
                    setDataAdesao(response.data.idUsuarioNavigation.dataAdesao)
                    setFotoPerfil(url + response.data.idUsuarioNavigation.caminhoImagem)
                }
            }).catch(erro => {
                console.log(erro)
            })

        } else {
            api.get('clientes/buscar/' + usuario).then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                    setDataAdesao(response.data.idUsuarioNavigation.dataAdesao)
                    //setEmpresa(false)
                    setFree(true)
                    setIdCliente(response.data.idCliente)
                    setnomeComercial(response.data.idUsuarioNavigation.nomeComercial)
                    setEmail(response.data.idUsuarioNavigation.email)
                    setTelefone(response.data.idUsuarioNavigation.idContatoNavigation.telefone)
                    setCep(response.data.idUsuarioNavigation.idEnderecoNavigation.cep)
                    setIdEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation.idEndereco)
                    setEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation.endereco1)
                    setComplemento(response.data.idUsuarioNavigation.idEnderecoNavigation.complemento)
                    setLat(response.data.idUsuarioNavigation.idEnderecoNavigation.latitude)
                    setLng(response.data.idUsuarioNavigation.idEnderecoNavigation.longitude)
                    setNumEndereco(response.data.idUsuarioNavigation.idEnderecoNavigation.numEndereco)
                    setCidade(response.data.idUsuarioNavigation.idEnderecoNavigation.cidade)
                    setBairro(response.data.idUsuarioNavigation.idEnderecoNavigation.bairro)
                    setEstado(response.data.idUsuarioNavigation.idEnderecoNavigation.estado)
                    setCpf(response.data.cpf)
                    setIdContato(response.data.idUsuarioNavigation.idContatoNavigation.idContato)
                    setWebsite(response.data.idUsuarioNavigation.idContatoNavigation.website)
                    setFacebook(response.data.idUsuarioNavigation.idContatoNavigation.facebook)
                    setInstagram(response.data.idUsuarioNavigation.idContatoNavigation.instagram)
                    setYoutube(response.data.idUsuarioNavigation.idContatoNavigation.youtube)
                    setFotoPerfil(url + response.data.idUsuarioNavigation.caminhoImagem)
                }
            }).catch(erro => {
                console.log(erro)
            })
        }
    }

    function atualizarPerfil(e) {
        e.preventDefault()
        var formData = new FormData();

        const target = document.getElementById('arquivo')

        if (target.files[0] !== undefined) {
            const file = target.files[0]
            console.log(file)
            console.log(fotoPerfil)

            formData.append('imagemPerfil', file, file.name);
        }

        console.log(lat)

        formData.append("novoContato.idContato", idContato);
        formData.append("novoContato.facebook", facebook);
        formData.append("novoContato.telefone", telefone);
        formData.append("novoContato.ecommerce", website);
        formData.append("novoContato.website", website);
        formData.append("novoContato.whatsapp", telefone);
        formData.append("novoContato.telegram", telefone);
        formData.append("novoContato.youtube", youtube);
        formData.append("novoContato.instagram", instagram);
        formData.append("novoEndereco.IdEndereco", idEndereco);
        formData.append("novoEndereco.Cep", cep);
        formData.append("novoEndereco.Endereco1", endereco1);
        formData.append("novoEndereco.NumEndereco", numEndereco);
        formData.append("novoEndereco.Complemento", complemento);
        formData.append("novoEndereco.Cidade", cidade);
        formData.append("novoEndereco.Bairro", bairro);
        formData.append("novoEndereco.Estado", estado);
        formData.append("novoEndereco.Latitude", lat);
        formData.append("novoEndereco.Longitude", lng);
        formData.append('idTipoUsuario', parseInt(parseJwt().role));
        formData.append('novoUsuario.email', email);

        formData.append('novoUsuario.nomeComercial', nomeComercial);
        formData.append('novoUsuario.saldo', 12);

        if (empresa) {
            formData.append('novaEmpresa.IdEmpresa', idEmpresa)
            formData.append('novaEmpresa.IdUsuario', parseJwt().jti);
            formData.append('novaEmpresa.cnpjmeicei', cnpjMeiCei);
            formData.append('novaEmpresa.razaoSocial', razaoSocial);
        } else {
            formData.append('novoCliente.IdCliente', idCliente)
            formData.append('novoCliente.Cpf', cpf)
            formData.append('novoCliente.nome', nomeComercial)
        }

        // formData.append('novoUsuario.token', "");

        api.put('/Usuarios/' + parseJwt().jti, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 204) {
                console.log(response)
                setIdAlterado(0)
                toast.success("Dados alterados com sucesso!");

            }
        }).then(() => buscarUsuario())
            .catch(erro => {
                toast.error("ERRO! Tente novamente mais tarde...");
                console.log(erro)
            })
        // }

    }

    function compararSenha() {
        var feedback = document.getElementById("msgFeedback")
        if (novaSenha === confirmaSenha) {
            feedback.innerHTML = '';
            setSenhasConfirmam(true);
            return true
        } else {
            feedback.innerHTML = 'As senhas não conferem!';
            setSenhasConfirmam(false);
            return false
        }
    }

    function alterarSenha(e) {
        var feedback = document.getElementById("feedbackOldPsw")
        e.preventDefault()
        var formData = new FormData();

        formData.append('idUsuario', parseJwt().jti);
        formData.append('novaSenha', novaSenha);
        formData.append('antigaSenha', antigaSenha);

        console.log(novaSenha)

        if (senhasConfirmam === true) {

            api.patch("Usuarios?novaSenha=" + novaSenha + "&antigaSenha=" + antigaSenha, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            ).then(response => {
                console.log(response)
                if (response.status === 200) {
                    toast.success("Senha alterada com sucesso")
                }
            }
            ).catch(function (erro) {
                if (erro.response.status === 404) {
                    feedback.innerHTML = 'Senha incorreta! Verifique novamente.';
                }
            })
        }

    }

    function limpa_formulário_cep() {
        //Limpa valores do formulário de cep.
        setEndereco("");
        setBairro("");
        setCidade("");
        setEstado("");
    }

    function preencherPosition() {
        // var myAPIKey = "vWyk3dDn92M41JJTqI4kjfHuhvuw1XtnUVSgST65aJc";

        var splited = endereco1.split(" ");

        var endFormat = "";

        for (let index = 0; index < splited.length; index++) {
            endFormat = endFormat + "+" + splited[index];
        }

        var formated = numEndereco + endFormat + "%2C+Brazil";

        var myUrl = "https://geocode.search.hereapi.com/v1/geocode?q= " + formated + "&apiKey=vWyk3dDn92M41JJTqI4kjfHuhvuw1XtnUVSgST65aJc";

        fetch(myUrl)
            .then((resposta) => resposta.json())
            .then((data) => {
                var position = data.items[0].position;
                setLat(position.lat);
                setLng(position.lng);
            });
    }

    function preencherCallback(conteudo) {
        if (conteudo != null) {
            setEndereco(conteudo.logradouro);
            setBairro(conteudo.bairro);
            setCidade(conteudo.localidade);
            setEstado(conteudo.uf);

            preencherPosition();

        }
        else {
            limpa_formulário_cep();
            alert("CEP não encontrado.");
        }
    }

    function pesquisacep(valor) {
        var cep = valor.replace(/\D/g, '');

        if (cep !== "") {

            var validacep = /^[0-9]{8}$/;

            if (validacep.test(cep)) {

                var myURL = 'https://viacep.com.br/ws/' + cep + '/json';

                fetch(myURL)
                    .then(respota => respota.json())
                    .then(data => preencherCallback(data));

            }
            else {
                limpa_formulário_cep();
                alert("Formato de CEP inválido.");
            }
        }
        else {

            limpa_formulário_cep();
        }
    };


    function logout() {
        try {
            localStorage.removeItem("usuario-login");
            navigate('/')
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        atualizarDadosUsuario() 
    }, [buscarUsuario () ])
    useEffect(BotaoMenu, [])
    useEffect(() => {
        compararSenha()
    }, [confirmaSenha])

    return (
        <div>
            <Header />
            <main className="mainEdicaoPerfil c-main__edicaoPerfil">
                <section className="c-box container">
                    <nav className="c-box__menu" id="links">
                        <a href="#sua_conta" onClick={() => BotaoMenu()}><span className="iconify icon__edicaoPerfil" data-icon="mdi:account-edit"></span>sua conta</a>
                        <div className="c-box__line"></div>
                        <a href="#meus_cupons" onClick={() => navigate('/atividades')}><span className="iconify  icon__edicaoPerfil" data-icon="ic:outline-confirmation-number" ></span>minhas
                            atividades</a>
                        <div className="c-box__line" ></div>
                        <a href="#links_externos" onClick={() => BotaoMenu()}><span className="iconify  icon__edicaoPerfil" data-icon="mdi:open-in-new"></span>links externos</a>
                        <div className="c-box__line"></div>
                        <a href="#plano" onClick={() => BotaoMenu()}><span className="iconify  icon__edicaoPerfil" data-icon="mdi:card-account-details-outline"></span>plano</a>
                        <div className="c-box__line"></div>
                        <a href="#ajuda" onClick={() => BotaoMenu()}><span className="iconify  icon__edicaoPerfil" data-icon="bx:bx-help-circle"></span>ajuda</a>
                        <div className="c-box__line"></div>
                        <a href="#sair" onClick={() => logout()}><span className="iconify  icon__edicaoPerfil" data-icon="bx:bx-exit"></span>sair</a>
                        <div className="c-box__line"></div>
                        <a href="http://191.101.234.36/EdicaoPerfil" onClick={() => BotaoMenu()}><span className="iconify  icon__edicaoPerfil" data-icon="ic:outline-privacy-tip"></span>políticas de privacidade</a>
                        <div className="c-box__line"></div>
                    </nav>
                    <section className="c-box__conteudo">
                        <a className="c-menu__btn" onClick={() => BotaoMenu()}>
                            <span className="iconify  icon__edicaoPerfil" data-icon="charm:menu-hamburger"></span>
                        </a>
                        <h1 id="sua_conta">Sua Conta</h1>
                        <div className="c-box__qrcode">
                            <QRCodeSVG className="qr-code" value={idUsuarioLogado} />
                            <span style={{ fontWeight: '800' }}>Código: </span>
                            <button id="passar_mouse" style={{ cursor: 'pointer', fontSize: '10px' }} onClick={() => { navigator.clipboard.writeText(idUsuarioLogado); alert('Texto copiado!') }}>{idUsuarioLogado} <div id="mostrar" style={{ color: 'red' }}>Clique para copiar </div></button>
                        </div>
                        <ToastContainer />

                        <div id="myModal" className="modal">
                            <div className="modal-content__senha">
                                <div className="modal__container">
                                    <form onSubmit={(e) => alterarSenha(e)}>
                                        <h3>Alterar senha</h3>
                                        <label className="c-box__input">
                                            Senha antiga:
                                            <input
                                                id='antigaSenha'
                                                type='password'
                                                required
                                                value={antigaSenha}
                                                onChange={(campo) => {
                                                    setAntigaSenha(campo.target.value)
                                                }}
                                            />

                                            <div id="feedback"><p id="feedbackOldPsw"></p></div>

                                        </label>
                                        <label className="c-box__input">
                                            Nova senha:
                                            <input
                                                id="novaSenha"
                                                type='password'
                                                required
                                                value={novaSenha}
                                                onChange={(campo) => {
                                                    setNovaSenha(campo.target.value)
                                                }}
                                            />

                                        </label>
                                        <label className="c-box__input">
                                            Confirme a nova senha:
                                            <input
                                                type='password'
                                                required
                                                value={confirmaSenha}
                                                onChange={(campo) => {
                                                    setSenhaAlterada(true)
                                                    setConfirmaSenha(campo.target.value)
                                                }}
                                            />

                                            <div id="feedback"><p id="msgFeedback"></p></div>

                                        </label>

                                        {
                                            senhasConfirmam === true ?
                                                <div>
                                                    <button id='btnEditSenha' className="c-box__btnEdit" type="submit">Salvar alterações</button>
                                                    <button type='reset' className="c-box__btnEdit--Cancel" onChange={() => { setSenhaAlterada(false) }} onClick={() => { document.location.reload() }} >Cancelar</button>
                                                </div>
                                                : ''
                                        }
                                    </form>

                                </div>
                            </div>
                        </div>

                        <form className='box__editPerfil' onSubmit={(e) => atualizarPerfil(e)}>
                            <div className="c-box__imagemqrcode">
                                <label className="c-box__imagemqrcode">
                                    <input type='file' id='arquivo' className='imgUpload' onChange={(e) => atualizarPerfil(e)} />
                                    <img src={fotoPerfil} alt="Sua foto de perfil" className="c-box__imgperfil" />
                                </label>
                            </div>

                            {
                                idAlterado === 0 ? '' :
                                    <div>
                                        <button id='btnEdit' className="c-box__btnEdit" type='submit' >Salvar alterações</button>
                                        <button className="c-box__btnEdit--Cancel" onClick={() => { setIdAlterado(0); document.location.reload() }}>Cancelar</button>
                                    </div>
                            }

                            {
                                empresa ?
                                    <section className="c-box__edicao">
                                        <label className="c-box__input">
                                            razão social:
                                            <input
                                                type='text'
                                                value={razaoSocial}
                                                id='razSocial'
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setRazaoSocial(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            nome fantasia:
                                            <input
                                                type='text'
                                                value={nomeComercial}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setnomeComercial(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            email:
                                            <input
                                                type='email'
                                                value={email}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setEmail(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label htmlFor='inputOPTCNPJ' className="c-box__input">
                                            CNPJ/MEI/CEI
                                            {
                                                <InputMask
                                                    mask="99.999.999/9999-99"
                                                    required
                                                    id="inputOPTCNPJ"
                                                    type="text"
                                                    placeholder="CNPJ"
                                                    title="CNPJ"
                                                    value={cnpjMeiCei}
                                                    onChange={(campo) => {
                                                        setIdAlterado(1)
                                                        setCnpjMeiCei(campo.target.value)
                                                    }} />

                                            }
                                        </label>

                                        <label className="c-box__input">
                                            telefone:
                                            <InputMask
                                                mask="9999-9999"
                                                required
                                                id="inputTel"
                                                type="text"
                                                placeholder={telefone !== null ? "Telefone" : "Adicionar telefone"}
                                                title="Telefone"
                                                value={telefone}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setTelefone(campo.target.value)
                                                }} />

                                        </label>

                                        <button onClick={() => {
                                            abrirModal()
                                            setSenhasConfirmam(false)
                                        }} className="c-buttons__senha" type="button">Alterar senha</button>

                                        <h2>Endereço</h2>

                                        <label className="c-box__input">
                                            CEP:
                                            <InputMask
                                                mask="99999-999"
                                                type='text'
                                                value={cep}
                                                onBlur={(campo) => pesquisacep(campo.target.value)}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setCep(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            endereço:
                                            <input
                                                type='text'
                                                disabled
                                                value={endereco1}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setEndereco(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            cidade:
                                            <input
                                                type='text'
                                                disabled
                                                value={cidade}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setCidade(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            bairro:
                                            <input
                                                type='text'
                                                disabled
                                                value={bairro}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setBairro(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            estado:
                                            <input
                                                type='text'
                                                disabled
                                                value={estado}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setEstado(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            complemento:
                                            <input
                                                type='text'
                                                value={complemento}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setComplemento(campo.target.value)
                                                }}
                                            />
                                        </label>

                                        <label className="c-box__input">
                                            número:
                                            <input
                                                type='number'
                                                value={numEndereco}
                                                onChange={(campo) => {
                                                    setIdAlterado(1)
                                                    setNumEndereco(campo.target.value)
                                                }}
                                            />
                                        </label>


                                    </section>
                                    :

                                    <section className="c-box__edicao">

                                        <label className="new_label">Nome:</label>
                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={nomeComercial}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setnomeComercial(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">E-mail:</label>


                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='email'
                                            value={email}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setEmail(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Telefone:</label>

                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='tel'
                                            value={telefone}
                                            placeholder={"Telefone"}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setTelefone(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">CEP:</label>

                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={cep}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setCep(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Endereço:</label>

                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={endereco1}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setEndereco(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Complemento:</label>

                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={complemento}
                                            placeholder="Complemento"
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setComplemento(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Número:</label>

                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='number'
                                            value={numEndereco}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setNumEndereco(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Cidade:</label>

                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={cidade}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setCidade(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Bairro:</label>


                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={bairro}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setBairro(campo.target.value)
                                            }}
                                        />

                                        <label className="new_label">Estado:</label>


                                        <input
                                            className="c-inputs__text_edicaoPerfil"
                                            type='text'
                                            value={estado}
                                            onChange={(campo) => {
                                                setIdAlterado(1)
                                                setEstado(campo.target.value)
                                            }}
                                        />

                                        <label className="c-box__input">
                                            CPF
                                            {
                                                <InputMask
                                                    className="c-inputs__text_edicaoPerfil"
                                                    disabled
                                                    mask="999.999.999-99"
                                                    required
                                                    id="inputOPTCPF"
                                                    type="text"
                                                    placeholder="CPF"
                                                    title="CPF"
                                                    value={cpf}
                                                    onChange={(campo) => {
                                                        setIdAlterado(1)
                                                        setCpf(campo.target.value)
                                                    }} />
                                            }
                                        </label>

                                        <button onClick={() => abrirModal()} className="c-buttons__senha" type="button">Alterar senha</button>

                                    </section>
                            }

                            <section>
                                <h2 id="links_externos">Links externos</h2>


                                <label className="c-box__input">
                                    Site:
                                    <input
                                        type='url'
                                        placeholder="https://www.exemplo.com"
                                        value={website}
                                        onChange={(campo) => {
                                            setIdAlterado(1)
                                            setWebsite(campo.target.value)
                                        }}
                                    />
                                </label>
                                <label className="c-box__input">
                                    Facebook:
                                    <input
                                        type='text'
                                        value={facebook}
                                        placeholder="https://www.facebook.com/sua_pagina"
                                        onChange={(campo) => {
                                            setIdAlterado(1)
                                            setFacebook(campo.target.value)
                                        }}
                                    />
                                </label>
                                <label className="c-box__input">
                                    Instagram:
                                    <input
                                        type='text'
                                        value={instagram}
                                        placeholder="https://www.instagram.com/seu_perfil/"
                                        onChange={(campo) => {
                                            setIdAlterado(1)
                                            setInstagram(campo.target.value)
                                        }}
                                    />
                                </label>
                                <label className="c-box__input">
                                    Youtube:
                                    <input
                                        type='url'
                                        value={youtube}
                                        placeholder="https://www.youtube.com/seu_canal"
                                        onChange={(campo) => {
                                            setIdAlterado(1)
                                            setYoutube(campo.target.value)
                                        }}
                                    />
                                </label>
                            </section>
                        </form>
                        {
                            free ?
                                <div>
                                    <h2 id="plano">Plano</h2>
                                    <div className="c-box__plano">
                                        <img src={planoFree} alt="Card com as descrições do seu plano" className="c-box__card" />
                                        <button onClick={() => {
                                            navigate("/planoUsuario")
                                        }} className="c-buttons__senha-plano" type="button">Melhore seu plano!</button>
                                        <span>Usuário desde:  {moment(dataAdesao).format('LL')}</span>
                                    </div>
                                </div>
                                :
                                <div>
                                    <h2 id="plano">Plano</h2>
                                    <div className="c-box__plano">
                                        {
                                            empresa ?
                                                <img src={planoMaster} alt="Card com as descrições do seu plano" className="c-box__card" />
                                                : <img src={PlanoVip} alt="Card com as descrições do seu plano" className="c-box__card" />
                                        }
                                        {
                                            empresa ?
                                                <span></span>
                                                : <button onClick={() => {
                                                    navigate("/planoUsuario")
                                                }} className="c-buttons__senha-plano" type="button">Melhore seu plano!</button>
                                        }
                                        <span>Usuário desde:  {moment(dataAdesao).format('LL')}</span>
                                    </div>
                                </div>
                        }
                    </section>
                </section>
            </main>

            <Rodape />

        </div>
    )
}